
@import "../utilities/variables";

// line.scss
.line {
    display: block;
    //height: .2rem;
    height: 2px;
    margin: 8px 0;
    background-color: $color-border;
}
