.lottery-hero {
    overflow: hidden;
    background-image: url('/wp-content/themes/harnasxosp/src/images/hero-bg-lottery.jpg');
    background-position: center bottom;
    background-size: cover;

    .wrapper {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        max-width: 430px;
        margin: 30px auto 0;


        >.hero-image {
            max-width: 100%;
            display: flex;

            img {
                max-width: 100%;
                margin-bottom: -53px;
            }

        }

        >.hero-slogan {
            max-width: 520px;

            img {
                max-width: 100%;
            }

            >.slogan-image {}

            >.txt {
                font-size: 20px;
                line-height: 1.2;
                color: $blue-6;
                font-family: $font-primary;
                font-weight: $font-weight-bold;
                margin-top: 10px;
                margin-bottom: 10px;
                letter-spacing: -0.5px;
            }

            .rules{
                font-size: 20px;
                font-weight: 700;
                color: $blue-6;
                background-color: $white;
                padding: 2px 11px;
                margin-bottom: 50px;
                display: inline-block;
                border-radius: 4px;
                transition: all 200ms linear;
                white-space: nowrap;

                &:hover{
                    background-color:  $blue-6;
                    color: $white;
                }
            }

        }
    }

    .hero-contact {
        color: $white;
        text-align: center;
        line-height: 1.1;
        height: 52px;
        padding: 10px 0px;
        font-size: 12px;
        font-weight: 600;
        text-shadow: 1px 1px 1px #101728;

        a{
            color: $white;
            white-space: nowrap;
            &:hover{
                text-decoration: underline;         
            }
        }
    }

    @media screen and (min-width: 460px) {
        .hero-contact {
            font-size: 16px;
        }
    }


    @media screen and (min-width: 768px) {
        .wrapper {
            flex-direction: row;
            align-items: end;
            max-width: unset;
            gap: 50px;

            >.hero-image {
                flex: 1 1 50%;
                max-width: 50%;

                >.img {
                    margin-top: -20px;
                    margin-left: -93px;
                    margin-right: -100px;
                    margin-bottom: -35px;
                    flex-grow: 1;
                    width: 100%;
                    max-width: unset;
                }
            }

            >.hero-slogan {
                flex: 1 1 50%;
                margin-left: 100px;

                >.slogan-image {}

                >.txt {
                    font-size: 30px;
                }

            }
        }

        .hero-contact {
            white-space: nowrap;
            font-size: 15px;
            height: 34px;
        }
    }

    @media screen and (min-width: 900px) {
        .wrapper{
            >.hero-image {
                >.img{
                    margin-bottom: -65px;
                }
            }
            >.hero-slogan{
                .rules{
                    font-size: 24px;
                }
            }
        }
        .hero-contact {
            font-size: 18px;
            padding: 10px 0px 20px;
            height: 64px;
        }
    }

    @media screen and (min-width: 1200px) {
        .hero-contact {
            font-size: 24px;
        }
    }

    @media screen and (min-width: 1300px) {
        .wrapper{
            >.hero-image{
                .img{
                    margin-top: -90px;
                    margin-left: -153px;
                    margin-right: -120px;
                }
            }
            >.hero-slogan{
                .rules{
                    margin-top: 10px;
                    margin-left: 95px;
                    padding: 4px 8px;
                    line-height: 1;
                }
            }
        }
    }

    @media screen and (min-width: 1520px) {
        .wrapper{
            >.hero-image{
                .img{
                    margin-top: -280px;
                    margin-left: -3px;
                    margin-right: -87px;
                }
            }
        }
    }
}


