
// movies.scss

@import "../utilities/variables";

.movies {

    &-content {

    }

    &-container {

        display: flex;
        padding: 5rem 10rem 10rem 10rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }

        @media screen and (max-width: 767px) {
            padding: 5rem 15px 10rem 15px;
        }
    }

    &-info {
        display: flex;
        width: 66.6666667%;
        align-items: center;

        // Media Queries
        @media screen and (max-width: 991px) {
            width: 100%;
            margin: 0 0 50px 0;
        }

        .movies-cover {
            // Media Queries
            @media screen and (max-width: 575px) {
                display: none;
            }
        }
    }

    &-cover {
        width: 13.5rem;
        height: 20rem;

        img {
            height: 100%;
        }
    }

    &-copy {
        max-width: 65%;
        padding: 0 0 0 5.5rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            max-width: 100%;
            padding: 0 5rem 0 5rem;
        }

        @media screen and (max-width: 991px) {
            padding: 0 0 0 5rem;
        }

        @media screen and (max-width: 575px) {
            padding: 0;
        }
    }

    &-title {

        margin: 0 0 1.5rem 0;
        color: $movie-color-title;
        font-size: 3rem;
        line-height: 1;
    }

    &-description {
        color: $movie-color-description;
        font-size: 2rem;
        line-height: 1.5;
    }

    &-slider {
        position: relative;
        width: 33.3333333%;

        // Media Queries
        @media screen and (max-width: 991px) {
            width: 100%;
        }

        .movies-cover {
            overflow: hidden;
            margin: 0 auto;
            cursor: pointer;

            img {
                opacity: .8;
            }

            &:hover {

                img {
                    opacity: 1;
                }
            }
        }

        &::before,
        &::after {

            position: absolute;
            z-index: -1;

            display: block;
            width: 1px;
            background-color: $movie-color-border;
            content: "";
        }

        &::before {
            display: none;

            // height: 100%;
            // left: 0;

            // Media Queries

            /*
            @media screen and (max-width: 991px) {
                display: none;
            }
            */
        }

        &::after {
            top: 50%;
            left: 50%;
            height: 4rem;
            margin: -2rem 0 0 -.05rem;

            // Media Queries
            @media screen and (max-width: 1199px) {
                display: none;
            }

            @media screen and (max-width: 991px) {
                display: block;
            }

            @media screen and (max-width: 575px) {
                display: none;
            }
        }
    }

    &-slider-btn {

        position: absolute;
        top: 50%;

        display: block;
        width: 2.5rem;
        height: 4rem;
        padding: 0;
        // reset
        border: 0;
        margin-top: -2rem;
        background: 0;
        background-repeat: no-repeat;

        background-size: contain;

        color: transparent;
        cursor: pointer;

        opacity: .4;

        &:hover {
            opacity: 1;
        }
    }

    &-slider-btn-next {
        right: 0;
        background-image: url("/wp-content/themes/harnasxosp/src/images/btn-next.svg");
    }

    &-slider-btn-prev {
        left: 0;
        background-image: url("/wp-content/themes/harnasxosp/src/images/btn-prev.svg");

        // display: none;

        // Media Queries

        /*
        @media screen and (max-width: 991px) {
            display: block;
        }
        */
    }
}
