/*
 * Typography
 */

@import "../utilities/variables";

html {
    font-size: 10px;

    // Media Queries
    @media screen and (max-width: 1680px) {
        font-size: 9px;
    }

    @media screen and (max-width: 1366px) {
        font-size: 8px;
    }
}

body {
    color: $color-text;
    font-family: $font-primary;
    font-size: 1.8rem;
    font-weight: $font-weight-regular;
    line-height: 1.66666667;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: 1;
    text-transform: uppercase;
}

a,
.link {
    color: $color-text;
    text-decoration: none;
}
