
@import "../utilities/variables";

.navbar {

    position: relative;
    z-index: 10;
    height: 12rem;
    background-color: $navbar-bg;

    &-container {

        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }
}
