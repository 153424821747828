/*
 * Fonts
 */

@import "../utilities/variables";
@import "../utilities/mixins";

// Source Sans Pro

// @include font-face("Source Sans Pro", $font-directory + "sourcesanspro-regular-webfont", $font-weight-regular, normal);
// @include font-face("Source Sans Pro", $font-directory + "sourcesanspro-italic-webfont", $font-weight-regular, italic);
// @include font-face("Source Sans Pro", $font-directory + "sourcesanspro-semibold-webfont", $font-weight-semibold, normal);
// @include font-face("Source Sans Pro", $font-directory + "sourcesanspro-bold-webfont", $font-weight-bold, normal);
// @include font-face("Source Sans Pro", $font-directory + "sourcesanspro-bolditalic-webfont", $font-weight-bold, italic);

// Artegra Slab
@include font-face("Artegra Slab", $font-directory + "artegra-slab-extrabold-webfont", $font-weight-extrabold, normal);
