// container.scss
.container {
    max-width: 1920px;
    padding: 0 10rem;
    margin: 0 auto;

    // Media Queries
    @media screen and (max-width: 767px) {
        padding: 0 15px;
    }
}
