// hamburger.scss

@import "../utilities/variables";

.hamburger {

    position: relative;
    z-index: 999;

    display: none;
    padding: 15px;

    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    transition: opacity .15s linear, filter .15s linear;

    // Media Queries
    @media screen and (max-width: 991px) {
        display: block;
    }

    &-box {

        position: relative;
        display: block;
        width: 45px;
        height: 25px;
    }

    &-inner {

        &,
        &::before,
        &::after {
            position: absolute;
            width: 45px;
            height: 4px;
            border-radius: 2px;
            background-color: $hamburger-bg;

            transition: transform .15s ease;
        }

        top: 50%;
        display: block;
        margin-top: -1px;

        transition: 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before,
        &::after {
            display: block;
            content: "";
        }

        &::before {
            top: -10px;
            transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in, width .35s ease;
        }

        &::after {
            bottom: -10px;
            transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width .35s ease;
        }
    }

    &.is-active {

        &:hover {
            transition: .5s all;
        }

        .hamburger-inner {
            transform: rotate(225deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before,
            &::after {
                width: 100%;
            }

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
