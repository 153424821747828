// _navigations.scss

@import "../utilities/variables";

.navigation {

    // Media Queries
    @media screen and (max-width: 991px) {

        position: fixed;

        z-index: 998;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background-color: $navigation-bg;
        opacity: 0;

        transition: opacity .25s ease, visibility .25s ease;
        visibility: hidden;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

    &-list {
        display: flex;

        // Media Queries
        @media screen and (max-width: 991px) {
            width: 100%;
            height: 100%;

            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &-item {

        &:not(:last-of-type) {
            margin: 0 4rem 0 0;

            // Media Queries
            @media screen and (max-width: 1366px) {
                margin: 0 4rem 0 0;
            }

            @media screen and (max-width: 991px) {
                margin: 0 0 4rem 0;
            }
        }
    }

    &-link {
        color: $navigation-link;
        font-size: 2rem;
        font-weight: $font-weight-bold;

        &.is-active {
            font-weight: $font-weight-extrabold;
        }

        @media screen and (min-width: 991px) and (max-width:1100px) {
            font-size:1.8rem;
        }

    }
}
