
// qa.scss

@import "../utilities/variables";

.qa {
    position: relative;

    overflow: hidden;
    padding: 16.5rem 0 15rem 0;
    background-color: $qa-color-bg;

    &::before,
    &::after {
        position: absolute;
        left: 0;
        width: 100%;
        content: "";
    }

    &::before {
        top: 0;
        height: 100rem;
        background: url(/wp-content/themes/harnasxosp/src/images/qa-bg-2.jpg) top center / cover no-repeat;

        // Media Queries
        @media screen and (max-width: 991px) {
            height: 50rem;
        }
    }

    &::after {
        top: 90rem;
        height: 10rem;
        background: linear-gradient(0deg, $qa-color-bg, transparent);

        // Media Queries
        @media screen and (max-width: 991px) {
            top: 40rem;
        }
    }

    &-container {
        position: relative;
        z-index: 5;
    }

    &-title {
        margin: 0 0 2rem 0;
        color: $qa-color-title;
        font-size: 10rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            font-size: 8rem;
        }
    }

    &-subtitle {
        margin: 0 0 10rem 0;
        color: $qa-color-subtitle;
        font-size: 3rem;
    }

    &-panel {
        border-radius: 1rem;
        background-color: $qa-color-panel;
    }

    &-item {
        padding: 2rem 6rem;
        cursor: pointer;

        // Media Queries
        @media screen and (max-width: 767px) {
            padding: 2rem 3rem;
        }

        &:first-of-type {
            padding-top: 4rem;
        }

        &:last-of-type {
            padding-bottom: 4rem;

            &.is-active {
                padding-bottom: 2rem;
            }
        }

        &:hover,
        &.is-active {
            background-color: $qa-color-item-active;

            .qa-question {
                font-weight: $font-weight-bold;
            }
        }

        &.is-active {

            .qa-question {
                color: $qa-color-question-active;

                &::before {
                    transform: rotate(90deg);
                }
            }

            .qa-body {
                height: auto;
                padding: 4rem 8rem 2rem 8rem;
                visibility: visible;

                // Media Queries
                @media screen and (max-width: 991px) {
                    padding: 4rem 3rem 2rem 3rem;
                }
            }

            .qa-answer {
                opacity: 1;
                transition: opacity .35s .15s linear;
            }
        }
    }

    &-body {
        height: 0;
        visibility: hidden;
    }

    &-text {
        color: $qa-color-text;
        font-size: 2.6rem;
    }

    .qa-item {
        .qa-text {
            a {
                color:$blue;
            }
        }
    }

    &-question {
        display: flex;
        align-items: flex-start;
        line-height: 1;

        &::before {
            display: block;
            width: 1rem;
            height: 2rem;
            flex-shrink: 0;
            margin: 0 2rem 0 0;
            background: url(/wp-content/themes/harnasxosp/src/images/qa-arrow.svg) center center / contain no-repeat;
            content: "";
            transition: transform .25s ease;
        }
    }

    &-answer {
        font-style: italic;
        line-height: 1.2;

        opacity: 0;

        &:not(:last-of-type) {
            margin: 0 0 2rem 0;
        }
    }

    &-link {
        color: $qa-color-link;

        &:hover {
            text-decoration: underline;
        }
    }

    &-important {
        display: block;
        font-weight: $font-weight-bold;
    }
}
