
// btn.scss

@import "../utilities/variables";

@mixin button-reset {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

.btn {
    @include button-reset;
    display: inline-block;
    padding: 2.5rem 5rem;
    border-radius: 4rem;
    background-color: $btn-color-bg;
    color: $btn-color-text !important;
    font-size: 3rem;
    font-style: italic;
    font-weight: $font-weight-bold;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    &.squared{
        border-radius: 3.3rem;
    }
    &.squared08{
        border-radius: .8rem;
    }

    &.nouppercase{
        text-transform: none;
    }

    &.normal{
        font-style: normal;
    }

    &.huge{
        padding: 3.2rem 4rem;
        font-size: 4.7rem;
        min-width: 50.2rem;
        letter-spacing: -.2rem;
    }

    &.white-hollow{
        background-color: transparent;
        border: solid .4rem #fff;

        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    &.blue{
        background-color: rgb(0, 45, 102);

        &:hover {
            background-color: rgba(0, 45, 102, 0.9);
        }
    }

    &:hover {
        background-color: $btn-color-bg-hover;
    }

    // Media Queries
    @media screen and (max-width: 767px) {
        font-size: 2rem;
        border: solid 1px #fff;

        &.huge{
            padding: 1.8rem 2rem;
            font-size: 2.8rem;
            min-width: auto;
        }
        &.squared{
            border-radius: 2rem;
        }

        &.white-hollow{
            border: solid 2px #fff;
        }
    }
}


