// promo.scss

@import "../utilities/variables";

.promo {
    position: relative;

    @media screen and (max-width: 1199px) {
        margin: 0;
    }

    &-hero {
        position: relative;
        // height: 81.4rem;
        background: url(/wp-content/themes/harnasxosp/img/hero-bg.jpg) bottom center / cover no-repeat;

        @media screen and (max-width: 1680px) {
            //   height: 150rem;
        }

        @media screen and (max-width: 1366px) {
            // height: 140rem;
        }

        @media screen and (max-width: 1200px) {
            // height: 125rem;
        }

        >.container {
            height: 100%;
        }

        >.slogan {
            position: absolute;
            top: 9.3rem;
            width: 100%;

            >.slogan-image {
                width: 100%;

                &.-mob {
                    display: none;
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0%;
            width: 100%;
            height: 100%;
            background-image: url(/wp-content/themes/harnasxosp/img/end-promotion/hero-harnas.png);
            background-size: cover;
            background-repeat: no-repeat;
            max-width: 65%;

            // @media screen and (max-width: 990px) {
            //     display: block;
            //     top:auto;
            //     bottom: 0;
            //     height: 55%;
            //     background-repeat: no-repeat;
            //     background-position: center bottom !important;
            // }        
        }



        @media screen and (max-width: 990px) {
            // height: calc(550px + 55vw);
            height: calc(460px + 60vw);
            background-position: center;

            >.slogan {
                >.slogan-image {
                    &.-mob {
                        display: block;
                        z-index: 3;
                        position: relative;
                        max-width: 100%;
                        width: 100%;
                    }

                    &.-des {
                        display: none;
                    }
                }
            }

            &::after {
                background-image: url(/wp-content/themes/harnasxosp/img/end-promotion/hero-harnas-mob.png);
                right: 0;
                left: unset;
                width: 100%;
                max-width: 100%;
                background-position: center;
            }


        }
    }

    &-hero-container {
        position: relative;
        z-index: 5;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding-top: 24rem;

        @media screen and (max-width: 1680px) {
            padding-top: 8rem;
        }

        @media screen and (max-width: 990px) {
            justify-content: flex-start;
            padding-top: 0;
            padding-bottom: 0;
        }
    }


    &-hero-copy {
        padding: 0 0 25rem 12rem;
        width: 55%;

        @media screen and (max-width: 1680px) {
            padding: 0 0 5rem 0;
        }

        @media screen and (max-width: 990px) {
            padding: 0 0 calc(15vw - 80px) 0;
            width: 100%;
            text-align: center;
            padding-bottom: 55rem;
        }
    }

    // &-hero-title,
    // &-hero-subtitle,
    // &-hero-link {
    //     color: $promo-hero-text-color;
    // }

    &-hero-title {
        margin: 0 0 .5rem 0;
        font-size: calc(2vw + 2.6rem);
        color: #002d66;
        font-style: normal;
        letter-spacing: .05rem;
        text-align: center;
        white-space: nowrap;

        @media screen and (max-width: 1680px) {
            //max-width: 65rem;
            //font-size: 6rem;
        }

        @media screen and (max-width: 1200px) {
            //max-width: 55rem;
            //margin: 0 0 2rem .6rem;
            //font-size: 5rem;
        }

        @media screen and (max-width: 990px) {
            white-space: normal;
            font-size: 40px;

        }

        @media screen and (max-width: 512px) {
            font-size: 24px;
        }



    }

    &-hero-subtitle {
        margin: 0 0 2rem 0;
        font-size: calc(2vw + 2rem);
        padding: 1rem .5rem 0.8rem;
        color: #fff;
        text-align: center;
        background-color: #e11a2b;
        font-style: normal;
        white-space: nowrap;

        @media screen and (max-width: 990px) {
            line-height: 1;
            //font-size: 15px;
            white-space: normal;
            display: inline-block;
            font-size: 40px;
        }

        @media screen and (max-width: 512px) {
            font-size: 24px;
        }
    }

    &-hero-link {
        font-size: 2.2rem;
        text-decoration: underline;

        @media screen and (max-width: 990px) {
            font-size: 12px;
            white-space: wrap;
        }
    }

    &-hero-thx {
        text-align: center;
        color: #002d66;
        font-size: 3.7rem;
        line-height: 1.2em;

        @media screen and (max-width: 990px) {
            font-size: 20px;
        }

        @media screen and (max-width: 512px) {
            font-size: 14px;
        }

    }

    &-end {
        position: relative;
        min-height: 89.8rem;
        padding: 6.3rem 0 11.9rem 0;
        background: url(/wp-content/themes/harnasxosp/src/images/promo-end-bg.png) top center / cover no-repeat;
    }

    &-end-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 990px) {
            flex-direction: column;
        }

        >div {
            padding: 0 3.5rem;
            flex: 0 0 50%;
            width: 50%;

            @media screen and (max-width: 990px) {
                flex: 0 0 100%;
                width: 100%;
            }

        }
    }

    &-end-image {
        @media screen and (max-width: 990px) {
            margin-bottom: 7rem;
        }
    }

    &-end-image-img {
        max-width: 100%;
        height: auto;
        display: block;
    }

    &-end-header {
        font-size: 6.4rem;
        text-align: center;
        color: #fff;
        margin-bottom: 1rem;
        line-height: 1.3em;
        margin-bottom: 2rem;

        @media screen and (max-width: 1680px) {
            font-size: 40px;
        }

        @media screen and (max-width: 480px) {
            font-size: 24px;
        }
    }

    &-end-text {
        font-size: 2.8rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        line-height: 1.3em;

        @media screen and (max-width: 480px) {
            font-size: 14px;
        }

        &::after {
            content: url(/wp-content/themes/harnasxosp/src/images/piktogram.png);
            display: block;
            margin-top: 2rem;
        }
    }

    &-note {
        padding: 2rem 0;
        background-color: $promo-note-bg-color;
        //position: relative;
    }

    &-note-text {
        color: $promo-note-text-color;
        font-size: 2.5rem;
        font-style: italic;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        //margin-left: 27.5rem;

        @media screen and (max-width: 575px) {
            font-size: 2rem;
            //margin-left: 15rem;
        }

        @media screen and (max-width: 360px) {
            //margin-left: 12rem;
        }

        @media screen and (max-width: 320px) {
            font-size: 1.8rem;
            //margin-left: 10rem;
        }
    }

    &-note-cap {
        position: absolute;
        z-index: 5;
        top: -2rem;
        left: calc(50% - 21rem);
        width: 15rem;
        height: 15rem;

        @media screen and (max-width: 575px) {
            left: calc(50% - 23rem);
            width: 14rem;
            height: 14rem;
        }
    }

    &-visual {
        position: relative;
        height: 124.6rem;
        background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-bg.jpg) top center / cover no-repeat;
        background-position: 31.5% 0%;

        // Media Queries
        @media screen and (max-width: 1680px) {
            height: 120rem;
        }

        @media screen and (max-width: 990px) {
            background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-bg-mob.jpg) top center / cover no-repeat;
            background-position: 50% 0%;
            background-size: calc(50vw + 550px) auto;
            height: auto;
            padding-bottom: 8rem;
        }


        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-people.png) calc(30% - 23rem) bottom no-repeat;
            background-size: 100rem;
            content: "";

            // Media Queries
            @media screen and (max-width: 1366px) {}

            @media screen and (max-width: 990px) {
                display: none;
            }
        }
    }

    &-visual-copy {
        display: flex;
        justify-content: flex-end;
        padding: 15rem 12rem 30rem 0;

        @media screen and (max-width: 1680px) {
            padding: 10rem 0 25rem 0;
        }

        @media screen and (max-width: 990px) {
            padding: 8rem 0rem 8rem 0;
        }
    }

    &-visual-text {
        //max-width: 68rem;
        max-width: 40%;
        color: #060812;
        font-size: 2.2rem;
        line-height: 1.25;

        &::after {

            display: block;
            width: 2rem;
            height: 2rem;
            margin: 1.5rem 0 0 0;
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-1.svg");

            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
        }

        @media screen and (max-width: 990px) {
            max-width: 100%;
        }
    }

    &-visual-video {

        position: relative;
        z-index: 5;
        max-width: 100rem;
        margin: 0 0 0 auto;

        // Media Queries
        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    &-visual-caption {
        max-width: 60rem;
        margin: 0 auto;
    }

    &-visual-title {
        position: relative;
        text-align: center;

        &::before {

            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin: 0 1rem 0 0;
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");

            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";

            vertical-align: top;
        }
    }

    &-visual-list {
        text-align: center;
    }

    &-visual-list-item {

        &::before {
            display: inline-block;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            margin: 0 1rem 0 0;
            background-color: $promo-visual-bullet-color;
            content: "";

            vertical-align: middle;
        }
    }

    &-visual-list-text {
        color: $promo-visual-text-color;
        font-size: 2rem;
        line-height: 1;
    }

    &-video-caption {
        max-width: 70rem;
        padding: 6rem 6rem 0 3.5rem;
        margin-left: auto;

        // Media Queries
        @media screen and (max-width: 767px) {
            max-width: 100%;
            padding: 6rem 3.5rem 0 3.5rem;
            margin: 0;
        }
    }

    &-headline-copy {

        position: absolute;
        top: 66rem;
        left: 10rem;
        max-width: 48rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            top: 45rem;
            left: 15px;
            max-width: 35rem;
        }
    }

    &-headline-title {
        font-size: 7rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            font-size: 4.8rem;
        }
    }

    &-headline-title-row {
        display: block;
    }

    &-headline-subtitle {
        font-size: 4rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            font-size: 2.8rem;
        }
    }

    &-headline-title,
    &-headline-subtitle {
        margin: 0 0 2.5rem 0;
    }

    &-headline-title,
    &-headline-subtitle,
    &-headline-note {
        color: $promo-headline-text-color;
        line-height: 1;
        text-shadow: 0 0 7.5rem $black;
    }

    &-headline-note {
        font-size: 2.5rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }
    }

    &-title {
        margin: 0 0 1.5rem 0;
        font-size: 3rem;
        line-height: 1;

        // position: relative;

        /*
        &:before {
            content: '';

            display: block;
            width: 2rem;
            height: 2rem;

            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;

            position: absolute;
            left: -3.5rem;
            top: .2rem;
        }
        */
    }

    &-text {
        font-size: 2rem;
        line-height: 1.5;
    }

    // promo visual 1

    &-visual-1 {
        background: url("/wp-content/themes/harnasxosp/src/images/promo-visual-1.jpg") top center / 192rem no-repeat;

        // Media Queries
        @media screen and (min-width: 1921px) {
            background-size: cover;
        }

        @media screen and (max-width: 767px) {
            background-size: 750px;
        }
    }

    &-visual-1-content-top {

        &::after {

            display: block;
            width: 100%;
            height: 6rem;

            margin-top: -6rem;
            background-color: $promo-video-top-mask;
            content: "";
        }

        &-container {
            position: relative;
            padding: 100rem 10rem 0 10rem;

            // Media Queries
            @media screen and (min-width: 1921px) {
                padding: 110rem 10rem 0 10rem;
            }

            @media screen and (max-width: 767px) {
                padding: 70rem 15px 0 15px;
            }
        }
    }

    &-visual-1-content-bottom {
        background-color: $white;

        &-container {
            padding: 0 10rem 10rem 10rem;

            // Media Queries
            @media screen and (max-width: 767px) {
                padding: 0 15px 6rem 15px;
            }
        }
    }

    &-visual-1-title {

        &::before {
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-1.svg");
        }
    }

    // promo visual 2

    &-visual-2 {
        background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-2.jpg");
        background-position: 50% 0;
        background-size: cover;

        // Media Queries
        @media screen and (max-width: 767px) {
            background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-2-sm.jpg");
        }
    }

    &-visual-2-content-top {

        &::after {

            display: block;
            width: 100%;
            height: 6rem;

            margin-top: -6rem;
            background-color: $promo-video-bottom-mask;
            content: "";
        }

        &-container {
            padding: 15rem 10rem 0 10rem;

            // Media Queries
            @media screen and (max-width: 767px) {
                padding: 45rem 15px 0 15px;
            }
        }

    }

    &-visual-2-content-bottom {
        background-color: $promo-video-bottom-mask;

        &-container {
            padding: 0 10rem 10rem 10rem;

            // Media Queries
            @media screen and (max-width: 767px) {
                padding: 0 15px 6rem 15px;
            }
        }
    }

    &-visual-2-col {
        max-width: 95rem;
        margin-left: auto;

        // Media Queries
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    &-visual-2-title {
        color: $promo-visual-2-title;

        &::before {
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");
        }
    }

    &-visual-2-text {
        color: $promo-visual-2-text;
    }

    // promo visual 3

    &-visual-3-content-top {
        background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-3.jpg");
        background-position: right top;
        background-size: cover;

        // Media Queries
        @media screen and (max-width: 767px) {
            background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-3-sm.jpg");

            &::after {
                display: block;
                width: 100%;
                height: 6rem;
                margin-top: -6rem;
                background-color: $promo-visual-3-content-bottom;
                content: "";
            }
        }

        &-container {
            padding: 15rem 10rem 10rem 10rem;

            // Media Queries
            @media screen and (max-width: 767px) {
                padding: 45rem 15px 0 15px;
            }
        }

    }

    &-visual-3-content-bottom {
        background-color: $promo-visual-3-content-bottom;

        &-container {
            padding: 0 10rem 10rem 10rem;

            // Media Queries
            @media screen and (max-width: 767px) {
                padding: 0 15px 6rem 15px;
            }
        }
    }

    &-visual-3-col {
        max-width: 95rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    &-visual-3-title {
        color: $promo-visual-3-title;

        &::before {
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-1.svg");
        }
    }

    &-visual-3-text {
        color: $promo-visual-3-text;
    }

    &-visual-3-video-caption {
        margin: 0;
    }

    // promo rules
    &-rules-container {
        position: relative;
        padding: 10rem 10rem 10rem 10rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            padding: 6rem 15px;
        }
    }

    &-rules-copy {
        max-width: 86rem;
        margin: 0 0 10rem 3.5rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            max-width: 100%;
            margin: 0 0 6rem 3.5rem;
        }
    }

    &-rules-title {
        margin: 0 0 10rem 0;
        color: $promo-rules-title-color;
        font-size: 4.8rem;
        text-align: center;

        /*
        &:before {
            background-image: url('/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-1.svg');
        }
        */
    }

    &-rules-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 0 -1.5rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            flex-direction: column;
            align-items: center;
            justify-content: unset;
        }
    }

    &-rules-list-item {
        max-width: calc(100% / 3);
        padding: 0 1.5rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            max-width: 100%;

            &:not(:last-of-type) {
                margin: 0 0 4rem 0;
            }
        }
    }

    &-rules-list-item-1 {

        .promo-rules-list-text {
            max-width: 30rem;
        }
    }

    &-rules-list-item-3 {

        .promo-rules-list-text {
            max-width: 40rem;
        }
    }

    &-rules-list-content {
        position: relative;
    }

    &-rules-list-copy {

        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
    }

    &-rules-list-title {
        color: $promo-rules-list-title;
        font-size: 4.8rem;
        line-height: 1;
    }

    &-rules-list-text {
        margin: 1rem auto 0 auto;
        font-size: 2.4rem;
        line-height: 1.25;

        span {
            font-weight: $font-weight-semibold;
        }
    }

    &-rules-list-title,
    &-rules-list-text {
        text-align: center;
    }

    &-rules-list-img {
        display: block;
        max-width: 100%;
        padding: 14rem 0 0 0;
        margin: 0 auto;
    }

    &-rules-more-info {

        position: absolute;
        z-index: 2;
        top: 10rem;
        right: 10rem;
        width: 30rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            position: static;
            top: auto;
            right: auto;

            margin: 0 0 4.5rem 0;
        }
    }

    &-rules-btn {

        position: relative;

        display: inline-block;
        padding: 0;
        border: 0;
        background: 0;
        color: $promo-rules-btn-color;
        cursor: pointer;
        font-size: 3rem;

        font-style: italic;
        font-weight: 700;
        line-height: 1;
        outline: 0;
        text-transform: uppercase;

        &::after {
            position: absolute;
            top: .2rem;
            right: -3.5rem;
            display: block;
            width: 2rem;
            height: 2rem;

            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-3.svg");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: "";

            transform: rotateX(180deg);
        }

        &.is-active {

            &::after {
                transform: rotateX(0);
            }
        }
    }

    &-rules-links {
        margin: 6rem 0 0 0;
        text-align: center;
    }

    &-rules-link {
        //color: $promo-rules-link-color;

        display: inline-block;
        margin: 10px 15px;
        font-size: 2.2rem;
        font-style: normal;
        line-height: 1;

        &::after {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin: 0 0 -.3rem 1rem;
            background: url("/wp-content/themes/harnasxosp/src/images/rules-link-arrow.svg") 50% 50% / contain no-repeat;
            content: "";
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    // promo map

    &-map {
        //height: 136.6rem;
        // background: url(/wp-content/themes/harnasxosp/src/images/promo-map-bg.png) top center no-repeat;
        background-color: white;
        overflow: hidden;
        padding-top: 100px;

        iframe {
            display: block;
            width: 100%;
            overflow: hidden !important;
            // height: 690px;
            height: 574px;

            // @media screen and (max-width: 769px) {
            //     height: 728px;
            // }

            @media screen and (max-width: 780px) {
                height: 635px;
            }

        }
    }

    &-map-container {
        // padding-top: 10rem;
        // padding-bottom: 5rem;
    }

    &-map-title {
        // max-width: 55rem;
        margin: 0 auto 5rem auto;
        color: #113456;
        font-size: 4.8rem;
        text-align: center;
        font-style: normal;
        font-weight: 700;



        @media screen and (max-width: 600px) {
            font-size: 18px;
        }
    }

    &-map-img {
        display: block;
        width: 100%;
        height: auto;
    }

    // promo overlay

    &-overlay {
        max-width: 1920px;
        height: 60vh;
        margin: 0 auto;
        //background-image: url('/wp-content/themes/harnasxosp/src/images/promo-overlay.jpg');
        //background-size: cover;
        //background-position: 50% 100%;
        //background-repeat: no-repeat;
        //background-attachment: fixed;

        // Media Queries
        @media screen and (max-width: 575px) {
            //background-attachment: unset;
            height: 300px;
        }
    }

    // promo old rules

    &-old-rules {
        padding: 6.5rem 0;

        @media screen and (max-width: 991px) {
            padding: 5rem 0 8.5rem 0;
        }
    }

    &-old-rules-outer {
        max-width: 100.5rem;
        padding: 1rem 2rem;
        margin: 5rem auto 0 auto;
        background-color: $promo-old-rules-bg-color;
    }

    &-old-rules-inner {
        padding: 2rem;
        border: 2px solid $promo-old-rules-color;
    }

    &-old-rules-text {
        color: $promo-old-rules-color;
        font-size: 3rem;
        font-weight: $font-weight-semibold;
        letter-spacing: -0.02em;
        line-height: 1;
        text-align: center;
    }

    &-old-rules-link {
        color: $promo-old-rules-color;
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }


    .hero-text {
        position: relative;
        z-index: 2;
        color: #002d66;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 45rem;
        padding-bottom: 15rem;

        >.inner {
            flex: 0 0 47%;
            text-align: center;

            >.header {
                font-size: 5rem;
                font-weight: 900;
                font-style: normal;
                margin-bottom: 2rem;
            }

            >.caption {
                font-size: 3.6rem;
                margin-top: 1rem;
                line-height: 1.3;
                margin-bottom: 3rem;
            }

            >.mail {
                color: #002d66;
                line-height: 1.3;
                font-weight: 900;
                margin-top: 8rem;
                margin-bottom: 2rem;
                font-size: 1.8rem;

                a {
                    color: #002d66;
                }
            }

            >.rules {
                font-size: 2.4rem;
                text-decoration: underline;
                color: #002d66;
                text-decoration: none;
                background-color: transparent;
                border: solid #002d66 2px;
                padding: 0px 13px;
                margin: 11px auto 5px 0;
                display: inline-block;
                width: fit-content;
                transition: all 0.3s linear;

                &:hover {
                    text-decoration: none;
                    color: #1a5198;
                    border-color: #1a5198;
                    background-color: #f9fbff;
                }
            }
        }



        @media screen and (max-width: 992px) {
            display: flex;
            height: 100%;
            padding-bottom: 7rem;
            padding-top: 0;

            >.inner {
                flex: 0 0 100%;
                align-self: flex-end;

                >.header {
                    font-size: 28px;
                    color: #fff;
                }

                >.caption {
                    font-size: 20px;
                }

                >.mail {
                    margin-top: 20px;
                    font-size: 11px;
                }

                >.rules {
                    font-size: 15px;
                    text-decoration: none;
                    border: solid #fff 2px;
                    color: #fff;
                    padding: 0px 13px;
                    margin: 11px auto 5px;
                    display: block;
                    width: fit-content;
                }
            }
        }
    }

    &-taste {
        background: url(/wp-content/themes/harnasxosp/img/end-promotion/taste-bg.jpg) bottom center / cover no-repeat;
        padding: 80px 0;

        &-wrapper {
            display: flex;
            flex-direction: row-reverse;
        }

        .promo-text {
            flex: 0 0 50%;
            align-self: center;
        }

        .promo-title {
            font-size: calc(8px + 3vw);
            text-align: center;
            font-style: normal;
            color: #fff;
            font-weight: 700;
        }

        .txt {
            color: #fff;
            text-align: center;
            font-size: 28px;
            font-weight: 700;
            line-height: 1.2;
        }

        .taste-img {
            max-width: 743px;
            margin: 30px auto 0;
            display: block;
            width: 80%;
        }

        @media screen and (max-width: 1480px) {
            .taste-img {
                max-width: 620px;
            }
        }

        @media screen and (max-width: 1200px) {
            .taste-img {
                max-width: 440px;
            }
        }

        @media screen and (max-width: 990px) {
            background: url(/wp-content/themes/harnasxosp/img/end-promotion/taste-bg-mob.jpg) bottom center / cover no-repeat;
            padding: 35px 0 80px;

            &-wrapper {
                display: flex;
                flex-direction: column;
            }

            .promo-title {
                font-size: 36px;
            }

            .txt {
                font-size: 16px;
            }

            .taste-img {
                max-width: 320px;
                width: 100%;
            }
        }

        @media screen and (max-width: 600px) {}
    }

    &-soon {
        background: url(/wp-content/themes/harnasxosp/img/end-promotion/soon-bg.jpg) 56% 50% / cover no-repeat;
        padding: 180px 0;
        min-height: 895px;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-end;

        &-text {
            color: #fff;
            // font-size: calc(8px + 3vw);
            
            text-align: center;


        }

        &-title {
            font-style: normal;
            line-height: 1.3em;
            font-size: 36px;
        }

        @media screen and (min-width: 990px) {
            &-title {
                font-size: 48px;
            }

            .d-none-desktop {
                display: none;
            }
        }

        @media screen and (max-width: 989px) {
            .d-none-mobile {
                display: none;
            }
        }

        @media screen and (min-width: 1366px) {
            &-title {
                font-size: 57px;
            }
        }

        @media screen and (min-width: 1520px) {
            &-title {
                font-size: 64px;
            }
        }

    }

}


.contest-end-visual {
    position: relative;

    >.copy {
        position: absolute;
        color: $white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
        height: 100%;

        >div {
            height: 100%;
        }

        .teaser {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
            padding: 40px 0;

            .header {
                font-size: 6.4rem;
                font-style: normal;
                font-weight: 700;
            }

            .caption {
                font-size: 2.8rem;
                font-weight: 700;
                max-width: 1100px;
                margin: 20px auto 0;
                line-height: 1.2;
            }

            .final-video-btn {
                background-color: transparent;
                border: none;
                -webkit-font-smoothing: inherit;
                -moz-osx-font-smoothing: inherit;
                -webkit-appearance: none;
                color: $white;
                cursor: pointer;
                display: flex;
                margin: 2rem auto 0;
                flex-direction: column;
                align-items: center;
                max-width: 28rem;

                >.play {
                    max-width: 20rem;
                }

                >span {
                    text-transform: uppercase;
                    font-size: 3.8rem;
                    font-weight: 700;
                    margin-top: 4rem;
                }
            }
        }
    }

    @media screen and (max-width: 782px) {
        >.copy {
            width: 76%;
            // max-width: 180px;
        }
    }

    @media screen and (max-width: 460px) {
        >.copy {
            width: 76%;
            max-width: 180px;

            .teaser {
                .header {
                    font-size: 24px;
                }

                .caption {
                    font-size: 14px;
                }

                .final-video-btn {
                    >.play {
                        max-width: 93px;
                    }

                    >span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

/* Section 2 */

.promo-rules.-lottery {
    background-color: #002d66;

    .container {
        max-width:1444px;
    }

    .promo-rules-list {
        margin:0 -1.5rem 8rem;
    }

    .promo-rules-title {
        color: #fff;
        font-family: $font-primary;
        font-style: normal;
        font-size: 6.4rem;
        @media screen and (max-width: 1199px) {
            font-size: 4.8rem;
        }
        
        
    }

    .promo-rules-list-item {
        position: relative;
        flex:0 0 33.3333333333%;
        @media screen and (max-width: 1199px) {
            flex:0 0 100%;
            width: 100%;
        }
        &:not(:last-of-type) { 
            @media screen and (max-width: 1199px) {
                margin: 0 0 10rem 0;
            }    
        }    
        &:before {
            position: absolute;
            bottom:-6rem;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            
        }
    }

    @for $i from 1 through 3 {
        .promo-rules-list-item-#{$i}:before {
          content: url(/wp-content/themes/harnasxosp/img/icon-number-#{$i}.png);
        }
    }

    
    .promo-rules-list-content {
        background-color: #fff;
        border-radius:10px;
        padding-top:2rem;
        padding-bottom:10rem;
        height: 100%;
    }


    .promo-rules-list-img {
        padding: 0 0 8rem 0;
    }
    .promo-rules-list-copy {
        position: relative;
        top:auto;
        left:auto;
        transform: translateX(0);
        &:before {
            content:'';
            width: 70%;
            height:3px;
            position: absolute;
            top:-3rem;
            left: 15%;
            background-color:#002d66;
        }
    }

    .promo-rules-list-title {
        
    }

    .promo-rules-list-text {
        font-size: 3.2rem;
        font-weight: 700;
        color: #002d66;
        max-width: 37rem;
        strong {
            font-size: 3.2rem;
            font-weight: 900;
            font-style: normal;
            text-transform: uppercase;
            color:#d81732;
        }
    }

    .promo-rules-list-info {
        font-size: 2.4rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
    }

    
}

/* Section 4 */

.promo-soon.-lottery {
    background-image:url(/wp-content/themes/harnasxosp/img/promo-soon-lottery.jpg);
    justify-content: flex-end;
    padding:80px 0;

    .promo-soon-title {
        font-size: 3.5rem;
        text-shadow: 1px 1px 2px rgba(0, 15, 35, 0.455);
    }

    @media screen and (min-width: 768px) {
        .promo-soon-title {
            font-size: 4.8rem;
        }
    }
}

.harnas {
    overflow: hidden !important;
}