// logo.scss
.logo {
    display: block;
    width: 19rem;
    height: 7.3rem;
    //background-image: url("..//wp-content/themes/harnasxosp/src/images/logo.svg");
    background-image: url("/wp-content/themes/harnasxosp/src/images/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
    font-size: 0;
}
