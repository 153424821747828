.our--beers {
    overflow: hidden;
}

.content--volts{
    position: relative;
    padding: 2px 10px 12px 10px;
    border: 3px #D2A463 solid;
    border-left-color: transparent;
    border-right-color: transparent;
    min-width: 17.6rem;
    >.amount{
        font-size: 3.4rem;
        font-weight: 700;
        line-height: 1.2;
        text-align: center;

        span{
            font-weight: 500;
            font-size: 1.2rem;
        }
    }

    >.desc{
        font-size: 1.6rem;
        position: absolute;
        text-align: center;
        font-weight: 700;
        color: #002D66;
        background: linear-gradient(to right, #E7C37B -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #F9E6C6 8.53%, #C99447 45.63%, #ECC67E 80.56%, #B37226 115.48%, #C99447 159.13%);
        // background: linear-gradient(to right, #87e0fd 0%,#05abe0 100%);
        line-height: 1;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, calc(50% + 1px));
        min-width: 8.2rem;
        padding: .3rem .6rem;
    }
}

.beer--item {
    color: #fff;
    background-size: cover;
    position: relative;
    

    &:nth-child(2n-1)>.bg--mob {
        // background-position: 70% 0%;
    }
    &:nth-child(2n)>.bg--mob {
        // background-position: 30% 0%;
    }
    &:nth-child(3n) {
        .content--volts{
            border-top-color: #002D66;
            border-bottom-color: #002D66;

            >.desc{
                background: linear-gradient(to right, #002F6A 0%, #005ACC 33%, #002F6A 92%);
                color: #D2A463;
            }
        }

        .inner>.details>.ingredients table tr td{
            border-color: #002D66;
        }
    }


    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        >.details {
            order: 2;
            padding: 5% 20px;

            h1,h2,h3,h4,h5,h6{
                font-style: normal;
                font-size: 3.6rem;
                font-weight: 900;
                margin-bottom: 5rem;
            }
            em{
                font-style: italic;
            }

            >.description{
                p{
                    font-size: 2rem;
                    font-weight: 900;
                    margin-bottom: 2.3rem;
                }
                button{
                    @include button-reset;
                    font-size: 2rem;
                    font-weight: 700;
                    background-color: #D2A463;
                    padding: .3rem 1.4rem .2rem 1.4rem;
                    border-radius: .4rem;
                    text-decoration: underline;

                    &:hover{
                        text-decoration: none;
                    }
                }
            }

            >.content{
                margin: 2.3rem 0 2.6rem;
                display: flex;
                column-gap: 4.8rem;
                row-gap: 2rem;
                flex-wrap: wrap;
            }

            >.ingredients{
                margin-top: 5rem;
                //white-space: pre-wrap;

                h1,h2,h3,h4,h5,h6{
                    font-size: 2.2rem;
                    margin-bottom: 2rem;
                    margin-top: 5rem;
                }
                p{
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-bottom: 1.5rem;
                }

                table{
                    font-size: 13px;
                    text-align: center;
                    line-height: 1.1;
                    tr{
                        td{
                            vertical-align: middle;
                            border: 2px solid #D2A463;
                            padding: 8px 6px;
                        }
                    }

                    @media screen and (max-width: 599px) {
                        height: auto !important;
                        tbody{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            tr{
                                height: auto !important;
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;
                                align-items: flex-start;
                                margin-right: -2px;
                                flex: 1 1 50%;
                                td{
                                    width: 100% !important;
                                    height: 50px !important;
                                    margin-top: -2px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 10px 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        >.packshot {
            order: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 550px;
            margin-top: -60px;
            margin-bottom: -70px;

            >.image {
                max-width: 100%;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        

        .inner {
            display: flex;
            flex-direction: row;
            align-items: center;

            >.details {
                flex: 1 0 50%;
            }

            >.packshot {
                flex: 1 1 50%;
                >.image{
                    margin-left: -7%;
                    margin-right: auto;
                }
            }
        }

        &:nth-child(2n-1) {
            .inner {
                >.details {
                    order: 1;
                }

                >.packshot {
                    order: 2;
                    >.image{
                        margin-right: -7%;
                        margin-left: auto;
                    }
                }
            }
        }
    }
}


.our--beers--popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 45, 102, .67);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity .1s linear;

    >.packshoot{
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
    }

    &.-visible{
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
    }
}


.bg--desk{
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-position: center;

    @media screen and (min-width: 1024px) {
        display: block;
    }
}

.bg--mob{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    @media screen and (min-width: 1024px) {
        display: none;
    }
}
