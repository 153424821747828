.how-to{
    background-color: #002d66;
    padding: 8rem 0 4rem;
    .header{
        font-size: 6.4rem;             
        font-style: normal;
        font-weight: 700;
        color: white;
        text-align: center;
    }
    
    &-steps{
        display: flex;
        gap: 74px;
        flex-wrap: wrap;
        margin-top: 30px;
        margin-bottom: 50px;
        gap: 4.3%;
    }

    &-step{
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        aspect-ratio: 0.88;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1 20%;
        width: 20%;
        padding: 21% 25px 30px 25px;

        &.-one{
            background-image: url(/wp-content/themes/harnasxosp/img/how-to-step1.png);
        }
        &.-two{
            background-image: url(/wp-content/themes/harnasxosp/img/how-to-step2.png);
        }
        &.-three{
            background-image: url(/wp-content/themes/harnasxosp/img/how-to-step3.png);
        }

        >.big{
            text-transform: uppercase;
            font-size: 50px;
            color: #b70916;
            font-weight: 900;
            line-height: 1;

            >span{
                display: inline-block;                
                background-color: #b70916;
                color: white;
                border-radius: 100px;
                width: 62px;
                height: 62px;
                padding: 3px 13px;
                margin-right: 20px;
            }
        }

        >.small{
            text-align: center;
            // font-size: 28px;
            font-size: calc(-5px + 1.6vw);
            color: #002d66;
            font-weight: 700;
            line-height: 1.3;
            margin-top: 10px;
            padding: 0px 45px;
            white-space: nowrap;

        }
    }

    .disclaimer{
        color: white;
        text-align: center;
        line-height: 1.2;
        font-size: 2.4rem;
        font-weight: 600;
    }

    @media screen and (max-width: 1680px) {
        &-step{
            >.big{
                font-size: 36px;
                >span{
                    width: 50px;
                    height: 50px;
                    padding: 5px 13px;
                    margin-right: 9px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        &-step{
            padding: 20% 5px 0px 5px;
            >.big{
                font-size: 27px;
                >span{
                    width: 40px;
                    height: 40px;
                    padding: 4px 11px;
                    margin-right: 11px;
                }
            }
            >.small{
                font-size: calc(-2px + 1.6vw);
                margin-top: 2px;
            }
        }
    }

    @media screen and (max-width: 990px) {
        .header{
            font-size: 28px;             
        }

        &-steps{
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;
        }

        &-step{
            flex: 1 1 100%;
            width: 100%;
            padding: 68% 25px 10px 25px;

            >.small{
                font-size: 15px;
            }
        }

        .disclaimer{
            font-size: 12px;
        }
    }

    @media screen and (max-width: 460px) {
        &-step{
            >.small{
                font-size: calc(10px + 1vw);
            }
        }
    }
}