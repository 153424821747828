@import "../utilities/variables";

.products {
    position: relative;

    &::after {

        position: absolute;

        z-index: -1;
        top: 0;

        display: block;
        width: 100%;
        height: 60rem;

        background-image: url("/wp-content/themes/harnasxosp/src/images/products-bg.jpg");
        background-position: 50% 50%;
        background-size: cover;
        content: "";

        // Media Queries
        @media screen and (max-width: 767px) {
            height: 45rem;
        }
    }

    &-content {
        display: flex;
        flex-wrap: wrap;

        // Media Queries

        /*
        @media screen and (max-width: 1365px) {
            flex-direction: column;
        }
        */
    }

    &-col {
        //width: 54rem;

        width: calc(100% / 3);

        &--images {
            position: relative;
        }

        // Media Queries
        @media screen and (max-width: 1199px) {

            &:nth-of-type(1) {

                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                order: 1;

                .products-inner-top {
                    padding: 0;
                }
            }

            &:nth-of-type(2) {
                order: 2;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
                width: 50%;
                height: 60rem;
                padding: 4rem 0;
            }

            &:nth-of-type(3) {
                width: 100%;
                order: 3;
            }
        }

        @media screen and (max-width: 767px) {

            &:nth-of-type(1) {
                height: auto;
                order: 2;
                padding: 0;

                .products-inner-top {
                    padding: 4rem 0 0 0;
                }
            }

            &:nth-of-type(2) {

                height: 45rem;
                order: 1;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
                width: 100%;
            }
        }
    }

    &-inner {

        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-inner-top {
        height: 60rem;
        //padding: 8rem 0 4rem 0;


        padding: 0 0 4rem 0;

        // Media Queries
        @media screen and (max-width: 1199px) {
            height: auto;
            padding: 4rem 0 0 0;
        }
    }

    &-inner-bottom {
        min-height: 30.5rem;
        padding: 4rem 0 0 0;

        // Media Queries
        @media screen and (max-width: 1199px) {
            min-height: auto;
        }
    }

    &-name {

        color: $products-name;
        font-size: 8.5rem;
        // line-height: 70px
        line-height: 0.7777778;

        // Media Queries
        @media screen and (max-width: 991px) {
            font-size: 6rem;
        }
    }

    &-logo {
        display: block;
        width: 14.9rem;
        height: 14.9rem;

        margin: 0 0 4rem 0;
    }

    &-title {

        margin: 0 0 1.5rem 0;
        color: $products-title;
        font-size: 3rem;
        line-height: 1;
    }

    &-text {
        margin: 0 0 3.5rem 0;
    }

    &-details-list {
        display: flex;

        // Media Queries
        @media screen and (max-width: 575px) {
        }
    }

    &-details-item {

        &:not(:last-of-type) {
            margin: 0 9.5rem 0 0;
        }

        // Media Queries
        @media screen and (max-width: 575px) {

            &:not(:last-of-type) {
                margin: 0 4rem 0 0;
            }
        }
    }

    &-detail {
        color: $products-detail;
        font-size: 2.4rem;
        line-height: 2.5;

        span {
            display: block;

            font-size: 8rem;
            font-weight: $font-weight-semibold;
            line-height: 0.75;
        }
    }

    &-images {

        position: absolute;
        display: flex;
        width: 100%;
        //padding: 0 4rem;

        //height: 100%;
        //display: flex;
        //align-items: flex-end;
        //justify-content: center;

        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 6rem 6rem 0 6rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            position: static;
            padding: 0 4rem;
        }

        @media screen and (max-width: 767px) {
            padding: 0;
        }

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &-text-bottom {
        //font-size: 2.4rem;
        font-size: 2rem;
        line-height: 1.25;

        .accent {
            font-weight: $font-weight-semibold;
        }

        .comment {
            display: block;
            font-size: 1.8rem;
            line-height: 1.66666667;
        }
    }

    &-ingredients {
        margin: 0 0 4.5rem 0;
    }

    &-description {
        margin: 10rem 0;
        color: $products-description;
        font-size: 2.4rem;
        font-style: italic;
        line-height: 1.25;
        text-align: center;

        // Media Queries
        @media screen and (max-width: 575px) {
            margin: 5rem 0 10rem 0;
        }
    }

    // slider

    &-slider {
        display: flex;
        align-items: center;
    }

    &-slider-images {

        display: flex;
        width: 14rem;
        height: 25rem;
        align-items: center;

        img {
            display: block;
            //max-width: 14rem;
            //max-height: 25rem;

            max-width: 100%;
            max-height: 100%;
        }
    }

    &-slider-container {
        width: 40rem;
    }

    &-slider-copy {
        padding: 0 0 2rem 6.5rem;
        //border-bottom: .2rem solid $color-border;
        border-bottom: 2px solid $color-border;
    }

    &-slider-caption {
        color: $slider-caption;
        font-size: 1.8rem;
        line-height: calc(40 / 18);
    }

    &-slider-name {
        color: $slider-product-name;
        font-size: 3rem;
        font-style: italic;
        font-weight: $font-weight-bold;
        line-height: calc(40 / 30);
    }

    &-slider-caption,
    &-slider-name {
        display: block;
        text-transform: uppercase;
    }

    &-slider-navigation {
        display: flex;
        justify-content: flex-end;
    }

    &-slider-btn {

        position: relative;

        display: block;
        width: 10rem;
        height: 8rem;
        padding: 0;
        border: 0;
        background: 0;
        color: transparent;
        cursor: pointer;

        &::after {

            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;

            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: "";

            opacity: .4;
            transition: opacity .25s ease;
        }

        &:hover {

            &::after {
                opacity: 1;
            }
        }
    }

    &-slider-btn-next {
        border-left: 1px solid $color-border;

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/btn-next.svg");
        }
    }

    &-slider-btn-prev {
        border-right: 1px solid $color-border;

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/btn-prev.svg");
        }
    }
}
