
// video-container.scss
.video-container {
    position: relative;
    overflow: hidden;
    //padding-top: 30px;
    padding-bottom: 56.25%;
    height: 0;
     /* 16:9 */

    iframe,
    object,
    embed,
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.-end{        
        padding-bottom: 46.85%;        
        background-image: url("/wp-content/themes/harnasxosp/src/images/video-bg.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        pointer-events: all;
        &.-stopped{
            padding-bottom: 46.85%;   
            iframe,
            object,
            embed,
            video {
                opacity: 0;
                pointer-events: none;
            }
        }

        // @media screen and (max-width: 1024px) {
        //     &.-stopped{
        //         padding-bottom: 120%;
        //     }
            
        // }

        @media screen and (max-width: 992px) {
            &.-stopped{
                padding-bottom: 59%;
            }
        }

        @media screen and (max-width: 782px) {
            &.-stopped{
                padding-bottom: 136.85%;
            }
        }

        @media screen and (max-width: 460px) {            
            background-position: 57% 50%;
            &.-stopped{
                padding-bottom: 113%;
            }
        }
        @media screen and (max-width: 350px) {            
            &.-stopped{
                padding-bottom: 233.2%;
            }
        }
    }
}
