html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
 * Fonts
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
@font-face {
  font-family: "Artegra Slab";
  font-style: normal;
  font-weight: 800;
  src: url("/wp-content/themes/harnasxosp/src/fontsartegra-slab-extrabold-webfont.woff") format("woff"), url("/wp-content/themes/harnasxosp/src/fontsartegra-slab-extrabold-webfont.woff2") format("woff2");
}
/*
 * Typography
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
html {
  font-size: 10px;
}
@media screen and (max-width: 1680px) {
  html {
    font-size: 9px;
  }
}
@media screen and (max-width: 1366px) {
  html {
    font-size: 8px;
  }
}

body {
  color: #464646;
  font-family: "Source Sans 3", arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.66666667;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

a,
.link {
  color: #464646;
  text-decoration: none;
}

/*
 * General
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  overflow-x: hidden;
}

body {
  background-color: #fff;
  image-rendering: -webkit-optimize-contrast;
}

.text-center {
  text-align: center;
}

/*
 * Global classes
 */
.is-visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  white-space: nowrap;
}

.is-hidden {
  display: none;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal .modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.modal .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}
.modal .close:hover {
  color: #000;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.btn {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  padding: 2.5rem 5rem;
  border-radius: 4rem;
  background-color: #c40f28;
  color: #fff !important;
  font-size: 3rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
.btn.squared {
  border-radius: 3.3rem;
}
.btn.squared08 {
  border-radius: 0.8rem;
}
.btn.nouppercase {
  text-transform: none;
}
.btn.normal {
  font-style: normal;
}
.btn.huge {
  padding: 3.2rem 4rem;
  font-size: 4.7rem;
  min-width: 50.2rem;
  letter-spacing: -0.2rem;
}
.btn.white-hollow {
  background-color: transparent;
  border: solid 0.4rem #fff;
}
.btn.white-hollow:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.btn.blue {
  background-color: rgb(0, 45, 102);
}
.btn.blue:hover {
  background-color: rgba(0, 45, 102, 0.9);
}
.btn:hover {
  background-color: #dc1229;
}
@media screen and (max-width: 767px) {
  .btn {
    font-size: 2rem;
    border: solid 1px #fff;
  }
  .btn.huge {
    padding: 1.8rem 2rem;
    font-size: 2.8rem;
    min-width: auto;
  }
  .btn.squared {
    border-radius: 2rem;
  }
  .btn.white-hollow {
    border: solid 2px #fff;
  }
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.hamburger {
  position: relative;
  z-index: 999;
  display: none;
  padding: 15px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  transition: opacity 0.15s linear, filter 0.15s linear;
}
@media screen and (max-width: 991px) {
  .hamburger {
    display: block;
  }
}
.hamburger-box {
  position: relative;
  display: block;
  width: 45px;
  height: 25px;
}
.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -1px;
  transition: 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  position: absolute;
  width: 45px;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
  transition: transform 0.15s ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  display: block;
  content: "";
}
.hamburger-inner::before {
  top: -10px;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in, width 0.35s ease;
}
.hamburger-inner::after {
  bottom: -10px;
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.35s ease;
}
.hamburger.is-active:hover {
  transition: 0.5s all;
}
.hamburger.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  width: 100%;
}
.hamburger.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.line {
  display: block;
  height: 2px;
  margin: 8px 0;
  background-color: #ccc;
}

.logo {
  display: block;
  width: 19rem;
  height: 7.3rem;
  background-image: url("/wp-content/themes/harnasxosp/src/images/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  font-size: 0;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.contact {
  padding: 10rem 0;
  background: linear-gradient(90deg, #061c3d 0%, #0a2c50 50%);
}
.contact-item:not(:last-of-type) {
  margin: 0 0 2.5rem 0;
}
.contact-text, .contact-link {
  color: #fff;
}
.contact-text {
  font-size: 2.6rem;
  line-height: 1.15;
  text-align: center;
}
.contact-text--important {
  font-weight: 700;
}
.contact-row {
  display: block;
}

.container {
  max-width: 1920px;
  padding: 0 10rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.darkmode,
.darkmode .navbar,
.darkmode .navigation,
.darkmode .footer {
  background-color: #111;
}
.darkmode a {
  color: #595959;
  transition: color 0.25s ease;
}
.darkmode a:hover {
  color: #fff;
}
.darkmode .hamburger-inner,
.darkmode .hamburger-inner::before,
.darkmode .hamburger-inner::after {
  background-color: #595959;
}
.darkmode .hamburger:hover .hamburger-inner,
.darkmode .hamburger:hover .hamburger-inner::before,
.darkmode .hamburger:hover .hamburger-inner::after {
  background-color: #fff;
}
.darkmode .navigation-link.is-active {
  color: #fff;
}
.darkmode .footer {
  border-top: 1px solid #595959;
}
.darkmode .footer-navigation-item:not(:last-of-type)::after {
  background-color: #595959;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.footer {
  background-color: #c40f28;
}
@media screen and (max-width: 991px) {
  .footer {
    height: auto;
  }
}
.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10rem;
  padding-bottom: 4rem;
}
@media screen and (max-width: 991px) {
  .footer-container {
    flex-direction: column;
    padding: 8rem 15px 5rem 15px;
  }
}
.footer-content {
  position: relative;
  z-index: 5;
  margin: -7rem 0 0 0;
}
@media screen and (max-width: 991px) {
  .footer-content {
    margin: -3.5rem 0 0 0;
  }
}
.footer-links {
  margin: 4rem 0 0 0;
}
@media screen and (max-width: 991px) {
  .footer-links {
    margin: 4rem 0 4rem 0;
  }
}
.footer-navigation:not(:last-of-type) {
  margin: 0 0 1rem 0;
}
.footer-navigation--large .footer-navigation-link {
  font-size: 2.5rem;
}
.footer-navigation--large .footer-navigation-item:not(:last-of-type) {
  margin: 0 4rem 0 0;
}
@media screen and (max-width: 991px) {
  .footer-navigation--large .footer-navigation-item:not(:last-of-type) {
    margin: 0;
  }
}
.footer-navigation--large .footer-navigation-item:not(:last-of-type)::after {
  right: -2.25rem;
}
.footer-navigation-list {
  display: flex;
}
@media screen and (max-width: 991px) {
  .footer-navigation-list {
    flex-direction: column;
  }
}
.footer-navigation-item:not(:last-of-type) {
  position: relative;
  margin: 0 2rem 0 0;
}
@media screen and (max-width: 991px) {
  .footer-navigation-item:not(:last-of-type) {
    margin: 0;
  }
}
.footer-navigation-item:not(:last-of-type)::after {
  position: absolute;
  top: 50%;
  right: -1.25rem;
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: -0.25rem 0 0 0;
  background-color: #fff;
  content: "";
}
@media screen and (max-width: 991px) {
  .footer-navigation-item:not(:last-of-type)::after {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .footer-navigation-item {
    text-align: center;
  }
}
.footer-navigation-link {
  color: #fff;
}
.footer-caution {
  width: 35rem;
}
@media screen and (max-width: 575px) {
  .footer-caution {
    width: 30rem;
  }
}
@media screen and (max-width: 991px) {
  .footer .logo {
    margin: 0 auto;
  }
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.navbar {
  position: relative;
  z-index: 10;
  height: 12rem;
  background-color: #fafafa;
}
.navbar-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
@media screen and (max-width: 991px) {
  .navigation {
    position: fixed;
    z-index: 998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.25s ease, visibility 0.25s ease;
    visibility: hidden;
  }
}
.navigation.is-visible {
  opacity: 1;
  visibility: visible;
}
.navigation-list {
  display: flex;
}
@media screen and (max-width: 991px) {
  .navigation-list {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.navigation-item:not(:last-of-type) {
  margin: 0 4rem 0 0;
}
@media screen and (max-width: 1366px) {
  .navigation-item:not(:last-of-type) {
    margin: 0 4rem 0 0;
  }
}
@media screen and (max-width: 991px) {
  .navigation-item:not(:last-of-type) {
    margin: 0 0 4rem 0;
  }
}
.navigation-link {
  color: #113456;
  font-size: 2rem;
  font-weight: 700;
}
.navigation-link.is-active {
  font-weight: 800;
}
@media screen and (min-width: 991px) and (max-width: 1100px) {
  .navigation-link {
    font-size: 1.8rem;
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
  /* 16:9 */
}
.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-container.-end {
  padding-bottom: 46.85%;
  background-image: url("/wp-content/themes/harnasxosp/src/images/video-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: all;
}
.video-container.-end.-stopped {
  padding-bottom: 46.85%;
}
.video-container.-end.-stopped iframe,
.video-container.-end.-stopped object,
.video-container.-end.-stopped embed,
.video-container.-end.-stopped video {
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 992px) {
  .video-container.-end.-stopped {
    padding-bottom: 59%;
  }
}
@media screen and (max-width: 782px) {
  .video-container.-end.-stopped {
    padding-bottom: 136.85%;
  }
}
@media screen and (max-width: 460px) {
  .video-container.-end {
    background-position: 57% 50%;
  }
  .video-container.-end.-stopped {
    padding-bottom: 113%;
  }
}
@media screen and (max-width: 350px) {
  .video-container.-end.-stopped {
    padding-bottom: 233.2%;
  }
}

.wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.gate {
  padding: 40rem 0 0 0;
  background: url(/wp-content/themes/harnasxosp/src/images/gate-visual.jpg) top center/800px no-repeat #fff;
}
@media screen and (min-width: 768px) {
  .gate {
    padding: 60rem 0 0 0;
    background-size: 1200px;
  }
}
@media screen and (min-width: 992px) {
  .gate {
    background-size: cover;
  }
}
@media screen and (min-width: 1024px) and (min-height: 680px) {
  .gate {
    height: 100vh;
    padding: 0;
  }
}
.gate-visual {
  height: 60rem;
  background-image: url("/wp-content/themes/harnasxosp/src/images/gate-visual.jpg");
  background-position: 50% 0;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .gate-visual {
    height: 30rem;
  }
}
@media screen and (max-width: 360px) {
  .gate-visual {
    height: 25rem;
  }
}
.gate-content {
  background-color: #fff;
}
@media screen and (min-width: 1024px) and (min-height: 680px) {
  .gate-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.gate-container {
  position: relative;
  padding: 12rem 10rem;
}
@media screen and (max-width: 767px) {
  .gate-container {
    padding: 8rem 15px 4rem 15px;
  }
}
.gate-row {
  display: flex;
  justify-content: space-between;
  margin: 0 -2rem;
}
@media screen and (max-width: 991px) {
  .gate-row {
    flex-direction: column;
    margin: 0;
  }
}
.gate-col {
  max-width: 50%;
  padding: 0 2rem;
}
@media screen and (max-width: 991px) {
  .gate-col {
    max-width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
  .gate-col-1 {
    margin: 0 0 3rem 0;
  }
}
.gate-question, .gate-answer {
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
.gate-question {
  margin: 0 0 4rem 0;
  font-size: 3rem;
}
@media screen and (max-width: 320px) {
  .gate-question {
    font-size: 2.4rem;
  }
}
.gate-answer {
  display: block;
  padding: 0 1rem 0 0;
  border: 0;
  background: 0;
  color: #ab7e38;
  cursor: pointer;
  font-size: 9rem;
  outline: 0;
}
@media screen and (max-width: 320px) {
  .gate-answer {
    font-size: 7rem;
  }
}
.gate-answer:not(:last-of-type) {
  position: relative;
  margin: 0 4rem 0 0;
}
.gate-answer:not(:last-of-type)::after {
  position: absolute;
  top: 50%;
  right: -2.2rem;
  display: block;
  width: 0.2rem;
  height: 7rem;
  margin: -3.5rem 0 0 0;
  background-color: #ab7e38;
  content: "";
  transform: rotate(12deg);
}
@media screen and (max-width: 320px) {
  .gate-answer:not(:last-of-type)::after {
    right: -2rem;
    height: 5rem;
    margin: -2.5rem 0 0 0;
  }
}
.gate-answer-container {
  display: flex;
  margin: 0 0 0 -1rem;
}
.gate-title {
  margin: 0 0 3rem 0;
  font-size: 2.4rem;
  line-height: 1.66667;
}
@media screen and (max-width: 320px) {
  .gate-title {
    font-size: 2rem;
  }
}
.gate-description {
  padding: 0 0 0 4rem;
  border-left: 2px solid #ab7e38;
  font-size: 1.8rem;
  line-height: 1.66667;
}
.gate .logo {
  position: absolute;
  top: -5.5rem;
  left: 10rem;
  width: 31.5rem;
  height: 12.2rem;
}
@media screen and (max-width: 767px) {
  .gate .logo {
    top: -3.65rem;
    left: 15px;
    width: 19rem;
    height: 7.3rem;
  }
}

body {
  background-color: #fff;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.energetyk {
  padding-top: 85px;
  padding-bottom: 200px;
  background-color: #00a0e4;
}
.energetyk .container {
  position: relative;
}
.energetyk__hero-content {
  position: relative;
  z-index: 9;
  min-height: 570px;
  background: url(/wp-content/themes/harnasxosp/src/images/energetyk_hasla.png) 0 0/contain no-repeat;
}
.energetyk__hero-content * {
  color: transparent;
}
@media all and (max-width: 1280px) {
  .energetyk__hero-content {
    min-height: 370px;
  }
}
@media all and (max-width: 800px) {
  .energetyk__hero-content {
    background-position: center;
  }
}
.energetyk__packshot {
  position: absolute;
  top: 0;
  right: 12%;
}
@media all and (max-width: 800px) {
  .energetyk__packshot {
    position: relative;
    right: initial;
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: 80px;
  }
}
.energetyk__packshot .positioning {
  position: relative;
}
@media all and (max-width: 800px) {
  .energetyk__packshot .positioning {
    display: flex;
    justify-content: center;
  }
}
.energetyk__packshot img.main {
  position: relative;
  z-index: 9;
  height: 640px;
}
@media all and (max-width: 1280px) {
  .energetyk__packshot img.main {
    height: 440px;
  }
}
@media all and (max-width: 425px) {
  .energetyk__packshot img.main {
    height: 320px;
  }
}
.energetyk__packshot .highlight {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  height: 1000px;
  transform: translate(-50%, -50%);
}
.energetyk__packshot .large-lighting-gold {
  position: absolute;
  z-index: 2;
  top: 60%;
  left: 50%;
  height: 1200px;
  animation: blinker-stable 5s linear infinite;
  animation-delay: 1s;
  transform: translate(-50%, -50%);
}
@media all and (max-width: 1250px) {
  .energetyk__packshot .large-lighting-gold {
    height: 100vw;
  }
}
@media all and (max-width: 800px) {
  .energetyk__packshot .large-lighting-gold {
    height: 100vw;
  }
}
.energetyk__packshot .large-lighting {
  position: absolute;
  z-index: 1;
  top: 85%;
  left: -70%;
  width: 110vw;
  animation: blinker 5s linear infinite;
  animation-delay: 2s;
  transform: translate(-50%, -50%);
}
@media all and (max-width: 1480px) {
  .energetyk__packshot .large-lighting {
    left: -44%;
  }
}
@media all and (max-width: 1250px) {
  .energetyk__packshot .large-lighting {
    top: 65%;
    left: -24%;
  }
}
@media all and (max-width: 800px) {
  .energetyk__packshot .large-lighting {
    left: 0;
    width: 260vw;
  }
}
.energetyk__packshot .small-lighting {
  position: absolute;
  z-index: 1;
  top: 85%;
  left: -70%;
  width: 80vw;
  animation: blinker 5s linear infinite;
  animation-delay: 2s;
  transform: translate(-50%, -50%);
}
.energetyk__bottom {
  position: relative;
  z-index: 2;
  width: 100vw;
  background-color: #00a0e4;
}
.energetyk__bottom::after {
  position: absolute;
  top: -300px;
  left: 0;
  display: block;
  width: 100vw;
  height: calc(100% + 300px);
  background: url(/wp-content/themes/harnasxosp/src/images/energetyk_tlo.png) 0 0/cover no-repeat;
  content: "";
}
@media all and (max-width: 990px) {
  .energetyk__bottom::after {
    top: -450px;
    height: calc(100% + 450px);
  }
}
@media all and (max-width: 630px) {
  .energetyk__bottom::after {
    top: -50vw;
    height: calc(100% + 260vw);
    background: url(/wp-content/themes/harnasxosp/src/images/energetyk_tlo_mob.png) 0 0/cover no-repeat;
    background-position: top right;
  }
}
.energetyk__bottom {
  padding-bottom: 100px;
}
.energetyk__bottom .container {
  position: relative;
  z-index: 12;
  display: grid;
  align-items: center;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}
@media all and (max-width: 990px) {
  .energetyk__bottom .container {
    grid-template-columns: 1fr;
  }
}
.energetyk__bottom-content {
  position: relative;
  z-index: 12;
  padding-right: 40%;
}
@media all and (max-width: 990px) {
  .energetyk__bottom-content {
    order: -1;
  }
}
@media all and (max-width: 1280px) {
  .energetyk__bottom-content {
    padding-right: 0;
  }
}
.energetyk__bottom-content h2 {
  min-height: 120px;
  margin-bottom: 70px;
  background: url(/wp-content/themes/harnasxosp/src/images/POCZUJ_ENERGIE_HARNASIA.png) 0 0/contain no-repeat;
  color: transparent;
}
.energetyk__bottom-content p {
  color: white;
  font-size: 22px;
  line-height: 1.5;
}
.energetyk__bottom-content ul {
  margin-top: 60px;
}
.energetyk__bottom-content ul li {
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 20px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
}
.energetyk__bottom-content ul li span {
  display: inline;
  background-color: #db021d;
  box-decoration-break: clone;
  box-shadow: 1em 0 0 #db021d, -1em 0 0 #db021d;
}
.energetyk__bottom-content ul li:nth-child(even) span {
  display: inline;
  background-color: #00a2e5;
  box-decoration-break: clone;
  box-shadow: 1em 0 0 #00a2e5, -1em 0 0 #00a2e5;
}
.energetyk__bottom-packshot {
  position: relative;
  z-index: 6;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
}
@media all and (max-width: 500px) {
  .energetyk__bottom-packshot {
    gap: 60px;
    grid-template-columns: 1fr;
  }
}
.energetyk__bottom-packshot img {
  height: 330px;
  margin: 0 auto;
}
.energetyk__bottom-packshot .button-like {
  display: inline-block;
  width: max-content;
  height: max-content;
  justify-content: center;
  padding: 20px 14px;
  border-radius: 39px;
  margin: 0 auto;
  margin-top: 80px;
  background-color: #e9cc8b;
  color: #002d66;
  font-size: 26px;
  font-weight: 700;
  grid-column: 1/span 2;
  line-height: 1.2;
}
@media all and (max-width: 500px) {
  .energetyk__bottom-packshot .button-like {
    max-width: 90vw;
    margin-top: 0;
    grid-column: 1;
    text-align: center;
    font-size: 24px;
  }
}

@keyframes blinker-stable {
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
@keyframes blinker {
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.25;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
.lodoherbata {
  position: relative;
  z-index: 1;
  background: linear-gradient(0deg, rgb(240, 146, 69) 0%, rgb(237, 103, 45) 100%);
}
.lodoherbata-hero {
  position: relative;
  margin: 0 0 -10rem 0;
}
.lodoherbata-hero::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: 90rem;
  height: 86rem;
  margin-left: -90rem;
  background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-tin.png) 0 0/contain no-repeat;
  content: "";
}
@media screen and (max-width: 1024px) {
  .lodoherbata-hero {
    margin: 0;
  }
  .lodoherbata-hero::before {
    display: none;
  }
}
.lodoherbata-hero-content, .lodoherbata-hero-copy {
  display: flex;
}
.lodoherbata-hero-content {
  justify-content: flex-end;
  padding: 7.5rem 20rem 0 0;
}
@media screen and (max-width: 1680px) {
  .lodoherbata-hero-content {
    padding: 7.5rem 15rem 0 0;
  }
}
@media screen and (max-width: 1366px) {
  .lodoherbata-hero-content {
    padding: 7.5rem 7.5rem 0 0;
  }
}
@media screen and (max-width: 1024px) {
  .lodoherbata-hero-content {
    justify-content: center;
    padding: 6rem 0 0 0;
  }
}
.lodoherbata-hero-copy {
  width: 62rem;
  flex-direction: column;
  align-items: center;
}
.lodoherbata-hero-label, .lodoherbata-hero-title, .lodoherbata-hero-flavor {
  margin: 0 0 3.5rem 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  font-size: 0;
}
.lodoherbata-hero-label {
  width: 39.3rem;
  height: 9.5rem;
  background-image: url(/wp-content/themes/harnasxosp/src/images/nowosc.svg);
}
@media screen and (max-width: 991px) {
  .lodoherbata-hero-label {
    width: 30rem;
    height: 7.5rem;
  }
}
.lodoherbata-hero-title {
  width: 53.2rem;
  height: 7rem;
  background-image: url(/wp-content/themes/harnasxosp/src/images/lodoherbata.svg);
}
@media screen and (max-width: 991px) {
  .lodoherbata-hero-title {
    width: 37rem;
    height: 5.5rem;
  }
}
.lodoherbata-hero-flavor {
  width: 39.2rem;
  height: 19.8rem;
  background-image: url(/wp-content/themes/harnasxosp/src/images/brzoskwinia.png);
}
@media screen and (max-width: 991px) {
  .lodoherbata-hero-flavor {
    width: 30rem;
    height: 16rem;
  }
}
.lodoherbata-hero-subtitle, .lodoherbata-hero-text, .lodoherbata-hero-alcohol {
  color: #133b6d;
}
.lodoherbata-hero-subtitle {
  margin: 0 0 2rem 0;
  font-size: 3rem;
}
.lodoherbata-hero-text {
  margin: 0 0 3rem 0;
  font-size: 2.2rem;
  line-height: 1.36;
}
.lodoherbata-hero-alcohol {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.lodoherbata-hero-alcohol span {
  display: block;
  margin: -2rem 0 -2rem 0;
  font-size: 4rem;
}
.lodoherbata-video-top {
  padding: 20rem 0 0 0;
  background: linear-gradient(0deg, rgb(231, 109, 54) 50%, rgba(231, 109, 54, 0) 80%), url(/wp-content/themes/harnasxosp/src/images/lodoherbata-bg.png) center top/cover no-repeat;
}
.lodoherbata-video-bottom {
  background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-harnas.png) calc(50% + 55rem) bottom/62.5rem 112.5rem no-repeat, linear-gradient(180deg, rgb(231, 109, 54) 0%, rgb(23, 44, 94) 15%);
}
@media screen and (max-width: 991px) {
  .lodoherbata-video-bottom {
    background: none, linear-gradient(180deg, rgb(231, 109, 54) 0%, rgb(23, 44, 94) 15%);
  }
}
.lodoherbata-caption {
  max-width: 95rem;
  padding: 6rem 6rem 0 6rem;
}
@media screen and (max-width: 991px) {
  .lodoherbata-caption {
    max-width: none;
    padding: 6rem 3.5rem 0 0;
  }
}
.lodoherbata-caption-inner {
  padding: 0 0 0 3.5rem;
}
.lodoherbata-caption-title, .lodoherbata-caption-text {
  color: #fff;
}
.lodoherbata-caption-title {
  position: relative;
  margin: 0 0 2rem 0;
  font-size: 3rem;
}
.lodoherbata-caption-title::before {
  position: absolute;
  top: 0.2rem;
  left: -3.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}
.lodoherbata-caption-text {
  font-size: 2.2rem;
  line-height: 1.36;
}
.lodoherbata-caption-text:not(:last-of-type) {
  margin: 0 0 2rem 0;
}
.lodoherbata-caption-text-important {
  font-weight: 700;
}
.lodoherbata-products {
  padding: 10rem 0 20rem 0;
}
@media screen and (max-width: 991px) {
  .lodoherbata-products {
    padding: 5rem 0 12rem 0;
  }
}
.lodoherbata-products-list {
  display: flex;
}
@media screen and (max-width: 991px) {
  .lodoherbata-products-list {
    display: block;
  }
}
.lodoherbata-products-img {
  display: block;
  width: 100%;
  max-width: 55rem;
  height: auto;
}
@media screen and (max-width: 1680px) {
  .lodoherbata-products-img {
    max-width: 50rem;
  }
}
@media screen and (max-width: 991px) {
  .lodoherbata-products-img {
    margin: 0 auto;
  }
}
.lodoherbata-products-only-in {
  display: block;
  width: 43.7rem;
  height: 13.2rem;
  margin: 5rem 0 0 33.5rem;
  background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-only-in.svg) center center/contain no-repeat;
  color: transparent;
  font-size: 0;
}
@media screen and (max-width: 1680px) {
  .lodoherbata-products-only-in {
    width: 36rem;
    height: 12rem;
    margin: 5rem 0 0 30rem;
  }
}
@media screen and (max-width: 991px) {
  .lodoherbata-products-only-in {
    margin: 8rem auto 0 auto;
  }
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.movies-container {
  display: flex;
  padding: 5rem 10rem 10rem 10rem;
}
@media screen and (max-width: 991px) {
  .movies-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .movies-container {
    padding: 5rem 15px 10rem 15px;
  }
}
.movies-info {
  display: flex;
  width: 66.6666667%;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .movies-info {
    width: 100%;
    margin: 0 0 50px 0;
  }
}
@media screen and (max-width: 575px) {
  .movies-info .movies-cover {
    display: none;
  }
}
.movies-cover {
  width: 13.5rem;
  height: 20rem;
}
.movies-cover img {
  height: 100%;
}
.movies-copy {
  max-width: 65%;
  padding: 0 0 0 5.5rem;
}
@media screen and (max-width: 1199px) {
  .movies-copy {
    max-width: 100%;
    padding: 0 5rem 0 5rem;
  }
}
@media screen and (max-width: 991px) {
  .movies-copy {
    padding: 0 0 0 5rem;
  }
}
@media screen and (max-width: 575px) {
  .movies-copy {
    padding: 0;
  }
}
.movies-title {
  margin: 0 0 1.5rem 0;
  color: #fff;
  font-size: 3rem;
  line-height: 1;
}
.movies-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 2rem;
  line-height: 1.5;
}
.movies-slider {
  position: relative;
  width: 33.3333333%;
}
@media screen and (max-width: 991px) {
  .movies-slider {
    width: 100%;
  }
}
.movies-slider .movies-cover {
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.movies-slider .movies-cover img {
  opacity: 0.8;
}
.movies-slider .movies-cover:hover img {
  opacity: 1;
}
.movies-slider::before, .movies-slider::after {
  position: absolute;
  z-index: -1;
  display: block;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  content: "";
}
.movies-slider::before {
  display: none;
  /*
  @media screen and (max-width: 991px) {
      display: none;
  }
  */
}
.movies-slider::after {
  top: 50%;
  left: 50%;
  height: 4rem;
  margin: -2rem 0 0 -0.05rem;
}
@media screen and (max-width: 1199px) {
  .movies-slider::after {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .movies-slider::after {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .movies-slider::after {
    display: none;
  }
}
.movies-slider-btn {
  position: absolute;
  top: 50%;
  display: block;
  width: 2.5rem;
  height: 4rem;
  padding: 0;
  border: 0;
  margin-top: -2rem;
  background: 0;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  cursor: pointer;
  opacity: 0.4;
}
.movies-slider-btn:hover {
  opacity: 1;
}
.movies-slider-btn-next {
  right: 0;
  background-image: url("/wp-content/themes/harnasxosp/src/images/btn-next.svg");
}
.movies-slider-btn-prev {
  left: 0;
  background-image: url("/wp-content/themes/harnasxosp/src/images/btn-prev.svg");
  /*
  @media screen and (max-width: 991px) {
      display: block;
  }
  */
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.promo {
  position: relative;
}
@media screen and (max-width: 1199px) {
  .promo {
    margin: 0;
  }
}
.promo-hero {
  position: relative;
  background: url(/wp-content/themes/harnasxosp/img/hero-bg.jpg) bottom center/cover no-repeat;
}
.promo-hero > .container {
  height: 100%;
}
.promo-hero > .slogan {
  position: absolute;
  top: 9.3rem;
  width: 100%;
}
.promo-hero > .slogan > .slogan-image {
  width: 100%;
}
.promo-hero > .slogan > .slogan-image.-mob {
  display: none;
}
.promo-hero::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0%;
  width: 100%;
  height: 100%;
  background-image: url(/wp-content/themes/harnasxosp/img/end-promotion/hero-harnas.png);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 65%;
}
@media screen and (max-width: 990px) {
  .promo-hero {
    height: calc(460px + 60vw);
    background-position: center;
  }
  .promo-hero > .slogan > .slogan-image.-mob {
    display: block;
    z-index: 3;
    position: relative;
    max-width: 100%;
    width: 100%;
  }
  .promo-hero > .slogan > .slogan-image.-des {
    display: none;
  }
  .promo-hero::after {
    background-image: url(/wp-content/themes/harnasxosp/img/end-promotion/hero-harnas-mob.png);
    right: 0;
    left: unset;
    width: 100%;
    max-width: 100%;
    background-position: center;
  }
}
.promo-hero-container {
  position: relative;
  z-index: 5;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 24rem;
}
@media screen and (max-width: 1680px) {
  .promo-hero-container {
    padding-top: 8rem;
  }
}
@media screen and (max-width: 990px) {
  .promo-hero-container {
    justify-content: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.promo-hero-copy {
  padding: 0 0 25rem 12rem;
  width: 55%;
}
@media screen and (max-width: 1680px) {
  .promo-hero-copy {
    padding: 0 0 5rem 0;
  }
}
@media screen and (max-width: 990px) {
  .promo-hero-copy {
    padding: 0 0 calc(15vw - 80px) 0;
    width: 100%;
    text-align: center;
    padding-bottom: 55rem;
  }
}
.promo-hero-title {
  margin: 0 0 0.5rem 0;
  font-size: calc(2vw + 2.6rem);
  color: #002d66;
  font-style: normal;
  letter-spacing: 0.05rem;
  text-align: center;
  white-space: nowrap;
}
@media screen and (max-width: 990px) {
  .promo-hero-title {
    white-space: normal;
    font-size: 40px;
  }
}
@media screen and (max-width: 512px) {
  .promo-hero-title {
    font-size: 24px;
  }
}
.promo-hero-subtitle {
  margin: 0 0 2rem 0;
  font-size: calc(2vw + 2rem);
  padding: 1rem 0.5rem 0.8rem;
  color: #fff;
  text-align: center;
  background-color: #e11a2b;
  font-style: normal;
  white-space: nowrap;
}
@media screen and (max-width: 990px) {
  .promo-hero-subtitle {
    line-height: 1;
    white-space: normal;
    display: inline-block;
    font-size: 40px;
  }
}
@media screen and (max-width: 512px) {
  .promo-hero-subtitle {
    font-size: 24px;
  }
}
.promo-hero-link {
  font-size: 2.2rem;
  text-decoration: underline;
}
@media screen and (max-width: 990px) {
  .promo-hero-link {
    font-size: 12px;
    white-space: wrap;
  }
}
.promo-hero-thx {
  text-align: center;
  color: #002d66;
  font-size: 3.7rem;
  line-height: 1.2em;
}
@media screen and (max-width: 990px) {
  .promo-hero-thx {
    font-size: 20px;
  }
}
@media screen and (max-width: 512px) {
  .promo-hero-thx {
    font-size: 14px;
  }
}
.promo-end {
  position: relative;
  min-height: 89.8rem;
  padding: 6.3rem 0 11.9rem 0;
  background: url(/wp-content/themes/harnasxosp/src/images/promo-end-bg.png) top center/cover no-repeat;
}
.promo-end-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 990px) {
  .promo-end-row {
    flex-direction: column;
  }
}
.promo-end-row > div {
  padding: 0 3.5rem;
  flex: 0 0 50%;
  width: 50%;
}
@media screen and (max-width: 990px) {
  .promo-end-row > div {
    flex: 0 0 100%;
    width: 100%;
  }
}
@media screen and (max-width: 990px) {
  .promo-end-image {
    margin-bottom: 7rem;
  }
}
.promo-end-image-img {
  max-width: 100%;
  height: auto;
  display: block;
}
.promo-end-header {
  font-size: 6.4rem;
  text-align: center;
  color: #fff;
  margin-bottom: 1rem;
  line-height: 1.3em;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1680px) {
  .promo-end-header {
    font-size: 40px;
  }
}
@media screen and (max-width: 480px) {
  .promo-end-header {
    font-size: 24px;
  }
}
.promo-end-text {
  font-size: 2.8rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  line-height: 1.3em;
}
@media screen and (max-width: 480px) {
  .promo-end-text {
    font-size: 14px;
  }
}
.promo-end-text::after {
  content: url(/wp-content/themes/harnasxosp/src/images/piktogram.png);
  display: block;
  margin-top: 2rem;
}
.promo-note {
  padding: 2rem 0;
  background-color: #000;
}
.promo-note-text {
  color: #fff;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 575px) {
  .promo-note-text {
    font-size: 2rem;
  }
}
@media screen and (max-width: 320px) {
  .promo-note-text {
    font-size: 1.8rem;
  }
}
.promo-note-cap {
  position: absolute;
  z-index: 5;
  top: -2rem;
  left: calc(50% - 21rem);
  width: 15rem;
  height: 15rem;
}
@media screen and (max-width: 575px) {
  .promo-note-cap {
    left: calc(50% - 23rem);
    width: 14rem;
    height: 14rem;
  }
}
.promo-visual {
  position: relative;
  height: 124.6rem;
  background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-bg.jpg) top center/cover no-repeat;
  background-position: 31.5% 0%;
}
@media screen and (max-width: 1680px) {
  .promo-visual {
    height: 120rem;
  }
}
@media screen and (max-width: 990px) {
  .promo-visual {
    background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-bg-mob.jpg) top center/cover no-repeat;
    background-position: 50% 0%;
    background-size: calc(50vw + 550px) auto;
    height: auto;
    padding-bottom: 8rem;
  }
}
.promo-visual::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/wp-content/themes/harnasxosp/src/images/promo-visual-people.png) calc(30% - 23rem) bottom no-repeat;
  background-size: 100rem;
  content: "";
}
@media screen and (max-width: 990px) {
  .promo-visual::after {
    display: none;
  }
}
.promo-visual-copy {
  display: flex;
  justify-content: flex-end;
  padding: 15rem 12rem 30rem 0;
}
@media screen and (max-width: 1680px) {
  .promo-visual-copy {
    padding: 10rem 0 25rem 0;
  }
}
@media screen and (max-width: 990px) {
  .promo-visual-copy {
    padding: 8rem 0rem 8rem 0;
  }
}
.promo-visual-text {
  max-width: 40%;
  color: #060812;
  font-size: 2.2rem;
  line-height: 1.25;
}
.promo-visual-text::after {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 1.5rem 0 0 0;
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-1.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
}
@media screen and (max-width: 990px) {
  .promo-visual-text {
    max-width: 100%;
  }
}
.promo-visual-video {
  position: relative;
  z-index: 5;
  max-width: 100rem;
  margin: 0 0 0 auto;
}
@media screen and (max-width: 991px) {
  .promo-visual-video {
    max-width: 100%;
  }
}
.promo-visual-caption {
  max-width: 60rem;
  margin: 0 auto;
}
.promo-visual-title {
  position: relative;
  text-align: center;
}
.promo-visual-title::before {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0 1rem 0 0;
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  vertical-align: top;
}
.promo-visual-list {
  text-align: center;
}
.promo-visual-list-item::before {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  margin: 0 1rem 0 0;
  background-color: #fff;
  content: "";
  vertical-align: middle;
}
.promo-visual-list-text {
  color: #fff;
  font-size: 2rem;
  line-height: 1;
}
.promo-video-caption {
  max-width: 70rem;
  padding: 6rem 6rem 0 3.5rem;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .promo-video-caption {
    max-width: 100%;
    padding: 6rem 3.5rem 0 3.5rem;
    margin: 0;
  }
}
.promo-headline-copy {
  position: absolute;
  top: 66rem;
  left: 10rem;
  max-width: 48rem;
}
@media screen and (max-width: 767px) {
  .promo-headline-copy {
    top: 45rem;
    left: 15px;
    max-width: 35rem;
  }
}
.promo-headline-title {
  font-size: 7rem;
}
@media screen and (max-width: 767px) {
  .promo-headline-title {
    font-size: 4.8rem;
  }
}
.promo-headline-title-row {
  display: block;
}
.promo-headline-subtitle {
  font-size: 4rem;
}
@media screen and (max-width: 767px) {
  .promo-headline-subtitle {
    font-size: 2.8rem;
  }
}
.promo-headline-title, .promo-headline-subtitle {
  margin: 0 0 2.5rem 0;
}
.promo-headline-title, .promo-headline-subtitle, .promo-headline-note {
  color: #fff;
  line-height: 1;
  text-shadow: 0 0 7.5rem #000;
}
.promo-headline-note {
  font-size: 2.5rem;
}
@media screen and (max-width: 767px) {
  .promo-headline-note {
    font-size: 2rem;
  }
}
.promo-title {
  margin: 0 0 1.5rem 0;
  font-size: 3rem;
  line-height: 1;
  /*
  &:before {
      content: '';

      display: block;
      width: 2rem;
      height: 2rem;

      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;

      position: absolute;
      left: -3.5rem;
      top: .2rem;
  }
  */
}
.promo-text {
  font-size: 2rem;
  line-height: 1.5;
}
.promo-visual-1 {
  background: url("/wp-content/themes/harnasxosp/src/images/promo-visual-1.jpg") top center/192rem no-repeat;
}
@media screen and (min-width: 1921px) {
  .promo-visual-1 {
    background-size: cover;
  }
}
@media screen and (max-width: 767px) {
  .promo-visual-1 {
    background-size: 750px;
  }
}
.promo-visual-1-content-top::after {
  display: block;
  width: 100%;
  height: 6rem;
  margin-top: -6rem;
  background-color: #fff;
  content: "";
}
.promo-visual-1-content-top-container {
  position: relative;
  padding: 100rem 10rem 0 10rem;
}
@media screen and (min-width: 1921px) {
  .promo-visual-1-content-top-container {
    padding: 110rem 10rem 0 10rem;
  }
}
@media screen and (max-width: 767px) {
  .promo-visual-1-content-top-container {
    padding: 70rem 15px 0 15px;
  }
}
.promo-visual-1-content-bottom {
  background-color: #fff;
}
.promo-visual-1-content-bottom-container {
  padding: 0 10rem 10rem 10rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-1-content-bottom-container {
    padding: 0 15px 6rem 15px;
  }
}
.promo-visual-1-title::before {
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-1.svg");
}
.promo-visual-2 {
  background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-2.jpg");
  background-position: 50% 0;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .promo-visual-2 {
    background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-2-sm.jpg");
  }
}
.promo-visual-2-content-top::after {
  display: block;
  width: 100%;
  height: 6rem;
  margin-top: -6rem;
  background-color: rgba(17, 17, 17, 0.85);
  content: "";
}
.promo-visual-2-content-top-container {
  padding: 15rem 10rem 0 10rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-2-content-top-container {
    padding: 45rem 15px 0 15px;
  }
}
.promo-visual-2-content-bottom {
  background-color: rgba(17, 17, 17, 0.85);
}
.promo-visual-2-content-bottom-container {
  padding: 0 10rem 10rem 10rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-2-content-bottom-container {
    padding: 0 15px 6rem 15px;
  }
}
.promo-visual-2-col {
  max-width: 95rem;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .promo-visual-2-col {
    max-width: 100%;
  }
}
.promo-visual-2-title {
  color: #fff;
}
.promo-visual-2-title::before {
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");
}
.promo-visual-2-text {
  color: rgba(255, 255, 255, 0.8);
}
.promo-visual-3-content-top {
  background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-3.jpg");
  background-position: right top;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .promo-visual-3-content-top {
    background-image: url("/wp-content/themes/harnasxosp/src/images/promo-visual-3-sm.jpg");
  }
  .promo-visual-3-content-top::after {
    display: block;
    width: 100%;
    height: 6rem;
    margin-top: -6rem;
    background-color: #fff;
    content: "";
  }
}
.promo-visual-3-content-top-container {
  padding: 15rem 10rem 10rem 10rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-3-content-top-container {
    padding: 45rem 15px 0 15px;
  }
}
.promo-visual-3-content-bottom {
  background-color: #fff;
}
.promo-visual-3-content-bottom-container {
  padding: 0 10rem 10rem 10rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-3-content-bottom-container {
    padding: 0 15px 6rem 15px;
  }
}
.promo-visual-3-col {
  max-width: 95rem;
}
@media screen and (max-width: 767px) {
  .promo-visual-3-col {
    max-width: 100%;
  }
}
.promo-visual-3-title {
  color: #464646;
}
.promo-visual-3-title::before {
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-1.svg");
}
.promo-visual-3-text {
  color: #464646;
}
.promo-visual-3-video-caption {
  margin: 0;
}
.promo-rules-container {
  position: relative;
  padding: 10rem 10rem 10rem 10rem;
}
@media screen and (max-width: 767px) {
  .promo-rules-container {
    padding: 6rem 15px;
  }
}
.promo-rules-copy {
  max-width: 86rem;
  margin: 0 0 10rem 3.5rem;
}
@media screen and (max-width: 767px) {
  .promo-rules-copy {
    max-width: 100%;
    margin: 0 0 6rem 3.5rem;
  }
}
.promo-rules-title {
  margin: 0 0 10rem 0;
  color: #113456;
  font-size: 4.8rem;
  text-align: center;
  /*
  &:before {
      background-image: url('/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-1.svg');
  }
  */
}
.promo-rules-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1.5rem;
}
@media screen and (max-width: 1199px) {
  .promo-rules-list {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
}
.promo-rules-list-item {
  max-width: 33.3333333333%;
  padding: 0 1.5rem;
}
@media screen and (max-width: 1199px) {
  .promo-rules-list-item {
    max-width: 100%;
  }
  .promo-rules-list-item:not(:last-of-type) {
    margin: 0 0 4rem 0;
  }
}
.promo-rules-list-item-1 .promo-rules-list-text {
  max-width: 30rem;
}
.promo-rules-list-item-3 .promo-rules-list-text {
  max-width: 40rem;
}
.promo-rules-list-content {
  position: relative;
}
.promo-rules-list-copy {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
.promo-rules-list-title {
  color: #c40f28;
  font-size: 4.8rem;
  line-height: 1;
}
.promo-rules-list-text {
  margin: 1rem auto 0 auto;
  font-size: 2.4rem;
  line-height: 1.25;
}
.promo-rules-list-text span {
  font-weight: 600;
}
.promo-rules-list-title, .promo-rules-list-text {
  text-align: center;
}
.promo-rules-list-img {
  display: block;
  max-width: 100%;
  padding: 14rem 0 0 0;
  margin: 0 auto;
}
.promo-rules-more-info {
  position: absolute;
  z-index: 2;
  top: 10rem;
  right: 10rem;
  width: 30rem;
}
@media screen and (max-width: 991px) {
  .promo-rules-more-info {
    position: static;
    top: auto;
    right: auto;
    margin: 0 0 4.5rem 0;
  }
}
.promo-rules-btn {
  position: relative;
  display: inline-block;
  padding: 0;
  border: 0;
  background: 0;
  color: #c40f28;
  cursor: pointer;
  font-size: 3rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  outline: 0;
  text-transform: uppercase;
}
.promo-rules-btn::after {
  position: absolute;
  top: 0.2rem;
  right: -3.5rem;
  display: block;
  width: 2rem;
  height: 2rem;
  background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-bottom-3.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: "";
  transform: rotateX(180deg);
}
.promo-rules-btn.is-active::after {
  transform: rotateX(0);
}
.promo-rules-links {
  margin: 6rem 0 0 0;
  text-align: center;
}
.promo-rules-link {
  display: inline-block;
  margin: 10px 15px;
  font-size: 2.2rem;
  font-style: normal;
  line-height: 1;
}
.promo-rules-link::after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0 0 -0.3rem 1rem;
  background: url("/wp-content/themes/harnasxosp/src/images/rules-link-arrow.svg") 50% 50%/contain no-repeat;
  content: "";
}
.promo-rules-link:hover, .promo-rules-link:focus {
  text-decoration: underline;
}
.promo-map {
  background-color: white;
  overflow: hidden;
  padding-top: 100px;
}
.promo-map iframe {
  display: block;
  width: 100%;
  overflow: hidden !important;
  height: 574px;
}
@media screen and (max-width: 780px) {
  .promo-map iframe {
    height: 635px;
  }
}
.promo-map-title {
  margin: 0 auto 5rem auto;
  color: #113456;
  font-size: 4.8rem;
  text-align: center;
  font-style: normal;
  font-weight: 700;
}
@media screen and (max-width: 600px) {
  .promo-map-title {
    font-size: 18px;
  }
}
.promo-map-img {
  display: block;
  width: 100%;
  height: auto;
}
.promo-overlay {
  max-width: 1920px;
  height: 60vh;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .promo-overlay {
    height: 300px;
  }
}
.promo-old-rules {
  padding: 6.5rem 0;
}
@media screen and (max-width: 991px) {
  .promo-old-rules {
    padding: 5rem 0 8.5rem 0;
  }
}
.promo-old-rules-outer {
  max-width: 100.5rem;
  padding: 1rem 2rem;
  margin: 5rem auto 0 auto;
  background-color: #fff;
}
.promo-old-rules-inner {
  padding: 2rem;
  border: 2px solid #c40f28;
}
.promo-old-rules-text {
  color: #c40f28;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  text-align: center;
}
.promo-old-rules-link {
  color: #c40f28;
  font-weight: 700;
  text-decoration: underline;
}
.promo .hero-text {
  position: relative;
  z-index: 2;
  color: #002d66;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 45rem;
  padding-bottom: 15rem;
}
.promo .hero-text > .inner {
  flex: 0 0 47%;
  text-align: center;
}
.promo .hero-text > .inner > .header {
  font-size: 5rem;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 2rem;
}
.promo .hero-text > .inner > .caption {
  font-size: 3.6rem;
  margin-top: 1rem;
  line-height: 1.3;
  margin-bottom: 3rem;
}
.promo .hero-text > .inner > .mail {
  color: #002d66;
  line-height: 1.3;
  font-weight: 900;
  margin-top: 8rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}
.promo .hero-text > .inner > .mail a {
  color: #002d66;
}
.promo .hero-text > .inner > .rules {
  font-size: 2.4rem;
  text-decoration: underline;
  color: #002d66;
  text-decoration: none;
  background-color: transparent;
  border: solid #002d66 2px;
  padding: 0px 13px;
  margin: 11px auto 5px 0;
  display: inline-block;
  width: fit-content;
  transition: all 0.3s linear;
}
.promo .hero-text > .inner > .rules:hover {
  text-decoration: none;
  color: #1a5198;
  border-color: #1a5198;
  background-color: #f9fbff;
}
@media screen and (max-width: 992px) {
  .promo .hero-text {
    display: flex;
    height: 100%;
    padding-bottom: 7rem;
    padding-top: 0;
  }
  .promo .hero-text > .inner {
    flex: 0 0 100%;
    align-self: flex-end;
  }
  .promo .hero-text > .inner > .header {
    font-size: 28px;
    color: #fff;
  }
  .promo .hero-text > .inner > .caption {
    font-size: 20px;
  }
  .promo .hero-text > .inner > .mail {
    margin-top: 20px;
    font-size: 11px;
  }
  .promo .hero-text > .inner > .rules {
    font-size: 15px;
    text-decoration: none;
    border: solid #fff 2px;
    color: #fff;
    padding: 0px 13px;
    margin: 11px auto 5px;
    display: block;
    width: fit-content;
  }
}
.promo-taste {
  background: url(/wp-content/themes/harnasxosp/img/end-promotion/taste-bg.jpg) bottom center/cover no-repeat;
  padding: 80px 0;
}
.promo-taste-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
.promo-taste .promo-text {
  flex: 0 0 50%;
  align-self: center;
}
.promo-taste .promo-title {
  font-size: calc(8px + 3vw);
  text-align: center;
  font-style: normal;
  color: #fff;
  font-weight: 700;
}
.promo-taste .txt {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}
.promo-taste .taste-img {
  max-width: 743px;
  margin: 30px auto 0;
  display: block;
  width: 80%;
}
@media screen and (max-width: 1480px) {
  .promo-taste .taste-img {
    max-width: 620px;
  }
}
@media screen and (max-width: 1200px) {
  .promo-taste .taste-img {
    max-width: 440px;
  }
}
@media screen and (max-width: 990px) {
  .promo-taste {
    background: url(/wp-content/themes/harnasxosp/img/end-promotion/taste-bg-mob.jpg) bottom center/cover no-repeat;
    padding: 35px 0 80px;
  }
  .promo-taste-wrapper {
    display: flex;
    flex-direction: column;
  }
  .promo-taste .promo-title {
    font-size: 36px;
  }
  .promo-taste .txt {
    font-size: 16px;
  }
  .promo-taste .taste-img {
    max-width: 320px;
    width: 100%;
  }
}
.promo-soon {
  background: url(/wp-content/themes/harnasxosp/img/end-promotion/soon-bg.jpg) 56% 50%/cover no-repeat;
  padding: 180px 0;
  min-height: 895px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}
.promo-soon-text {
  color: #fff;
  text-align: center;
}
.promo-soon-title {
  font-style: normal;
  line-height: 1.3em;
  font-size: 36px;
}
@media screen and (min-width: 990px) {
  .promo-soon-title {
    font-size: 48px;
  }
  .promo-soon .d-none-desktop {
    display: none;
  }
}
@media screen and (max-width: 989px) {
  .promo-soon .d-none-mobile {
    display: none;
  }
}
@media screen and (min-width: 1366px) {
  .promo-soon-title {
    font-size: 57px;
  }
}
@media screen and (min-width: 1520px) {
  .promo-soon-title {
    font-size: 64px;
  }
}

.contest-end-visual {
  position: relative;
}
.contest-end-visual > .copy {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 100%;
}
.contest-end-visual > .copy > div {
  height: 100%;
}
.contest-end-visual > .copy .teaser {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 40px 0;
}
.contest-end-visual > .copy .teaser .header {
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
}
.contest-end-visual > .copy .teaser .caption {
  font-size: 2.8rem;
  font-weight: 700;
  max-width: 1100px;
  margin: 20px auto 0;
  line-height: 1.2;
}
.contest-end-visual > .copy .teaser .final-video-btn {
  background-color: transparent;
  border: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  margin: 2rem auto 0;
  flex-direction: column;
  align-items: center;
  max-width: 28rem;
}
.contest-end-visual > .copy .teaser .final-video-btn > .play {
  max-width: 20rem;
}
.contest-end-visual > .copy .teaser .final-video-btn > span {
  text-transform: uppercase;
  font-size: 3.8rem;
  font-weight: 700;
  margin-top: 4rem;
}
@media screen and (max-width: 782px) {
  .contest-end-visual > .copy {
    width: 76%;
  }
}
@media screen and (max-width: 460px) {
  .contest-end-visual > .copy {
    width: 76%;
    max-width: 180px;
  }
  .contest-end-visual > .copy .teaser .header {
    font-size: 24px;
  }
  .contest-end-visual > .copy .teaser .caption {
    font-size: 14px;
  }
  .contest-end-visual > .copy .teaser .final-video-btn > .play {
    max-width: 93px;
  }
  .contest-end-visual > .copy .teaser .final-video-btn > span {
    font-size: 16px;
  }
}

/* Section 2 */
.promo-rules.-lottery {
  background-color: #002d66;
}
.promo-rules.-lottery .container {
  max-width: 1444px;
}
.promo-rules.-lottery .promo-rules-list {
  margin: 0 -1.5rem 8rem;
}
.promo-rules.-lottery .promo-rules-title {
  color: #fff;
  font-family: "Source Sans 3", arial, sans-serif;
  font-style: normal;
  font-size: 6.4rem;
}
@media screen and (max-width: 1199px) {
  .promo-rules.-lottery .promo-rules-title {
    font-size: 4.8rem;
  }
}
.promo-rules.-lottery .promo-rules-list-item {
  position: relative;
  flex: 0 0 33.3333333333%;
}
@media screen and (max-width: 1199px) {
  .promo-rules.-lottery .promo-rules-list-item {
    flex: 0 0 100%;
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .promo-rules.-lottery .promo-rules-list-item:not(:last-of-type) {
    margin: 0 0 10rem 0;
  }
}
.promo-rules.-lottery .promo-rules-list-item:before {
  position: absolute;
  bottom: -6rem;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}
.promo-rules.-lottery .promo-rules-list-item-1:before {
  content: url(/wp-content/themes/harnasxosp/img/icon-number-1.png);
}
.promo-rules.-lottery .promo-rules-list-item-2:before {
  content: url(/wp-content/themes/harnasxosp/img/icon-number-2.png);
}
.promo-rules.-lottery .promo-rules-list-item-3:before {
  content: url(/wp-content/themes/harnasxosp/img/icon-number-3.png);
}
.promo-rules.-lottery .promo-rules-list-content {
  background-color: #fff;
  border-radius: 10px;
  padding-top: 2rem;
  padding-bottom: 10rem;
  height: 100%;
}
.promo-rules.-lottery .promo-rules-list-img {
  padding: 0 0 8rem 0;
}
.promo-rules.-lottery .promo-rules-list-copy {
  position: relative;
  top: auto;
  left: auto;
  transform: translateX(0);
}
.promo-rules.-lottery .promo-rules-list-copy:before {
  content: "";
  width: 70%;
  height: 3px;
  position: absolute;
  top: -3rem;
  left: 15%;
  background-color: #002d66;
}
.promo-rules.-lottery .promo-rules-list-text {
  font-size: 3.2rem;
  font-weight: 700;
  color: #002d66;
  max-width: 37rem;
}
.promo-rules.-lottery .promo-rules-list-text strong {
  font-size: 3.2rem;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase;
  color: #d81732;
}
.promo-rules.-lottery .promo-rules-list-info {
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

/* Section 4 */
.promo-soon.-lottery {
  background-image: url(/wp-content/themes/harnasxosp/img/promo-soon-lottery.jpg);
  justify-content: flex-end;
  padding: 80px 0;
}
.promo-soon.-lottery .promo-soon-title {
  font-size: 3.5rem;
  text-shadow: 1px 1px 2px rgba(0, 15, 35, 0.455);
}
@media screen and (min-width: 768px) {
  .promo-soon.-lottery .promo-soon-title {
    font-size: 4.8rem;
  }
}

.harnas {
  overflow: hidden !important;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.products {
  position: relative;
}
.products::after {
  position: absolute;
  z-index: -1;
  top: 0;
  display: block;
  width: 100%;
  height: 60rem;
  background-image: url("/wp-content/themes/harnasxosp/src/images/products-bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
  content: "";
}
@media screen and (max-width: 767px) {
  .products::after {
    height: 45rem;
  }
}
.products-content {
  display: flex;
  flex-wrap: wrap;
  /*
  @media screen and (max-width: 1365px) {
      flex-direction: column;
  }
  */
}
.products-col {
  width: 33.3333333333%;
}
.products-col--images {
  position: relative;
}
@media screen and (max-width: 1199px) {
  .products-col:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    order: 1;
  }
  .products-col:nth-of-type(1) .products-inner-top {
    padding: 0;
  }
  .products-col:nth-of-type(2) {
    order: 2;
  }
  .products-col:nth-of-type(1), .products-col:nth-of-type(2) {
    width: 50%;
    height: 60rem;
    padding: 4rem 0;
  }
  .products-col:nth-of-type(3) {
    width: 100%;
    order: 3;
  }
}
@media screen and (max-width: 767px) {
  .products-col:nth-of-type(1) {
    height: auto;
    order: 2;
    padding: 0;
  }
  .products-col:nth-of-type(1) .products-inner-top {
    padding: 4rem 0 0 0;
  }
  .products-col:nth-of-type(2) {
    height: 45rem;
    order: 1;
  }
  .products-col:nth-of-type(1), .products-col:nth-of-type(2) {
    width: 100%;
  }
}
.products-inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
}
.products-inner-top {
  height: 60rem;
  padding: 0 0 4rem 0;
}
@media screen and (max-width: 1199px) {
  .products-inner-top {
    height: auto;
    padding: 4rem 0 0 0;
  }
}
.products-inner-bottom {
  min-height: 30.5rem;
  padding: 4rem 0 0 0;
}
@media screen and (max-width: 1199px) {
  .products-inner-bottom {
    min-height: auto;
  }
}
.products-name {
  color: #c29755;
  font-size: 8.5rem;
  line-height: 0.7777778;
}
@media screen and (max-width: 991px) {
  .products-name {
    font-size: 6rem;
  }
}
.products-logo {
  display: block;
  width: 14.9rem;
  height: 14.9rem;
  margin: 0 0 4rem 0;
}
.products-title {
  margin: 0 0 1.5rem 0;
  color: #c29755;
  font-size: 3rem;
  line-height: 1;
}
.products-text {
  margin: 0 0 3.5rem 0;
}
.products-details-list {
  display: flex;
}
.products-details-item:not(:last-of-type) {
  margin: 0 9.5rem 0 0;
}
@media screen and (max-width: 575px) {
  .products-details-item:not(:last-of-type) {
    margin: 0 4rem 0 0;
  }
}
.products-detail {
  color: #c29755;
  font-size: 2.4rem;
  line-height: 2.5;
}
.products-detail span {
  display: block;
  font-size: 8rem;
  font-weight: 600;
  line-height: 0.75;
}
.products-images {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 6rem 6rem 0 6rem;
}
@media screen and (max-width: 1199px) {
  .products-images {
    position: static;
    padding: 0 4rem;
  }
}
@media screen and (max-width: 767px) {
  .products-images {
    padding: 0;
  }
}
.products-images img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.products-text-bottom {
  font-size: 2rem;
  line-height: 1.25;
}
.products-text-bottom .accent {
  font-weight: 600;
}
.products-text-bottom .comment {
  display: block;
  font-size: 1.8rem;
  line-height: 1.66666667;
}
.products-ingredients {
  margin: 0 0 4.5rem 0;
}
.products-description {
  margin: 10rem 0;
  color: #898989;
  font-size: 2.4rem;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .products-description {
    margin: 5rem 0 10rem 0;
  }
}
.products-slider {
  display: flex;
  align-items: center;
}
.products-slider-images {
  display: flex;
  width: 14rem;
  height: 25rem;
  align-items: center;
}
.products-slider-images img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.products-slider-container {
  width: 40rem;
}
.products-slider-copy {
  padding: 0 0 2rem 6.5rem;
  border-bottom: 2px solid #ccc;
}
.products-slider-caption {
  color: #898989;
  font-size: 1.8rem;
  line-height: 2.2222222222;
}
.products-slider-name {
  color: #464646;
  font-size: 3rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.3333333333;
}
.products-slider-caption, .products-slider-name {
  display: block;
  text-transform: uppercase;
}
.products-slider-navigation {
  display: flex;
  justify-content: flex-end;
}
.products-slider-btn {
  position: relative;
  display: block;
  width: 10rem;
  height: 8rem;
  padding: 0;
  border: 0;
  background: 0;
  color: transparent;
  cursor: pointer;
}
.products-slider-btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 4rem;
  height: 4rem;
  margin: -2rem 0 0 -2rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: "";
  opacity: 0.4;
  transition: opacity 0.25s ease;
}
.products-slider-btn:hover::after {
  opacity: 1;
}
.products-slider-btn-next {
  border-left: 1px solid #ccc;
}
.products-slider-btn-next::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/btn-next.svg");
}
.products-slider-btn-prev {
  border-right: 1px solid #ccc;
}
.products-slider-btn-prev::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/btn-prev.svg");
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.qa {
  position: relative;
  overflow: hidden;
  padding: 16.5rem 0 15rem 0;
  background-color: #f4f5f5;
}
.qa::before, .qa::after {
  position: absolute;
  left: 0;
  width: 100%;
  content: "";
}
.qa::before {
  top: 0;
  height: 100rem;
  background: url(/wp-content/themes/harnasxosp/src/images/qa-bg-2.jpg) top center/cover no-repeat;
}
@media screen and (max-width: 991px) {
  .qa::before {
    height: 50rem;
  }
}
.qa::after {
  top: 90rem;
  height: 10rem;
  background: linear-gradient(0deg, #f4f5f5, transparent);
}
@media screen and (max-width: 991px) {
  .qa::after {
    top: 40rem;
  }
}
.qa-container {
  position: relative;
  z-index: 5;
}
.qa-title {
  margin: 0 0 2rem 0;
  color: #113456;
  font-size: 10rem;
}
@media screen and (max-width: 991px) {
  .qa-title {
    font-size: 8rem;
  }
}
.qa-subtitle {
  margin: 0 0 10rem 0;
  color: #c40f28;
  font-size: 3rem;
}
.qa-panel {
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.85);
}
.qa-item {
  padding: 2rem 6rem;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .qa-item {
    padding: 2rem 3rem;
  }
}
.qa-item:first-of-type {
  padding-top: 4rem;
}
.qa-item:last-of-type {
  padding-bottom: 4rem;
}
.qa-item:last-of-type.is-active {
  padding-bottom: 2rem;
}
.qa-item:hover, .qa-item.is-active {
  background-color: rgba(225, 228, 230, 0.5);
}
.qa-item:hover .qa-question, .qa-item.is-active .qa-question {
  font-weight: 700;
}
.qa-item.is-active .qa-question {
  color: #c40f28;
}
.qa-item.is-active .qa-question::before {
  transform: rotate(90deg);
}
.qa-item.is-active .qa-body {
  height: auto;
  padding: 4rem 8rem 2rem 8rem;
  visibility: visible;
}
@media screen and (max-width: 991px) {
  .qa-item.is-active .qa-body {
    padding: 4rem 3rem 2rem 3rem;
  }
}
.qa-item.is-active .qa-answer {
  opacity: 1;
  transition: opacity 0.35s 0.15s linear;
}
.qa-body {
  height: 0;
  visibility: hidden;
}
.qa-text {
  color: #113456;
  font-size: 2.6rem;
}
.qa .qa-item .qa-text a {
  color: #113456;
}
.qa-question {
  display: flex;
  align-items: flex-start;
  line-height: 1;
}
.qa-question::before {
  display: block;
  width: 1rem;
  height: 2rem;
  flex-shrink: 0;
  margin: 0 2rem 0 0;
  background: url(/wp-content/themes/harnasxosp/src/images/qa-arrow.svg) center center/contain no-repeat;
  content: "";
  transition: transform 0.25s ease;
}
.qa-answer {
  font-style: italic;
  line-height: 1.2;
  opacity: 0;
}
.qa-answer:not(:last-of-type) {
  margin: 0 0 2rem 0;
}
.qa-link {
  color: #113456;
}
.qa-link:hover {
  text-decoration: underline;
}
.qa-important {
  display: block;
  font-weight: 700;
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.support--section .header > .heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 6.8rem;
  margin: -24rem auto 5rem auto;
  max-width: 100rem;
}
.support--section .header > .img {
  margin: 0 auto;
  display: block;
}
.support--section .details {
  background-color: rgba(17, 52, 86, 0.8);
  width: 100%;
  max-width: 66rem;
  margin: 0 auto 6rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 0 0;
  color: #fff;
}
.support--section .details > p {
  text-align: center;
  padding: 0 1rem;
  font-weight: 700;
  margin: 0;
}
.support--section .details > .heading {
  text-transform: uppercase;
  font-size: 4.4rem;
  margin: 0 0 3rem 0;
  padding: 0 1rem;
}
@media screen and (min-width: 768px) {
  .support--section .details > .heading {
    font-size: 5.4rem;
  }
}
.support--section .details > .paragraph {
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0 0 3rem 0;
  line-height: normal;
}
.support--section .details > .paragraph.-item {
  margin-bottom: 0;
  padding: 0.3rem 0;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .support--section .details > .paragraph {
    font-size: 3rem;
  }
}
.support--section .details .spacer--small {
  margin-top: 2rem;
}
.support--section .details > .description {
  font-size: 2.3rem;
  text-transform: uppercase;
}
.support--section .details > .date {
  font-size: 4rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  line-height: normal;
}
@media screen and (min-width: 768px) {
  .support--section .details > .date {
    font-size: 6rem;
  }
}
.support--section .details > .info {
  text-transform: uppercase;
  color: #113456;
  background-color: #fff;
  padding: 0;
  display: block;
  width: 100%;
  font-size: 3rem;
}
.support--section .options {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 6rem;
}
@media screen and (min-width: 992px) {
  .support--section .options {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}
.support--section .options > .option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .support--section .options > .option {
    margin-top: 1rem;
  }
}
.support--section .options > .option > .button {
  background: #E21A2D;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  text-decoration: none;
  width: 100%;
  padding: 2rem 1rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .support--section .options > .option > .button {
    margin-bottom: 3rem;
    font-size: 2.8rem;
    padding: 2.5rem 1.5rem;
  }
}
.support--section .extra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}
@media screen and (min-width: 768px) {
  .support--section .extra {
    padding-bottom: 7rem;
  }
}
.support--section .extra > .regulations {
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  border-radius: 0.8rem;
  padding: 2rem 3rem;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}
.support--section .support-announcement {
  background-color: #fff;
  color: #002d66;
  font-size: 5.8rem;
  text-align: center;
  font-weight: 700;
  line-height: 1.1;
  padding: 8rem 0;
}
@media screen and (max-width: 1366px) {
  .support--section .support-announcement {
    font-size: 4.5rem;
    padding: 6rem 0;
  }
}
@media screen and (max-width: 767px) {
  .support--section .support-announcement {
    font-size: 3.6rem;
    padding: 5rem 0;
  }
}
.support--section .support-numbers {
  background-color: #052644;
  color: #fff;
  text-align: center;
  padding: 6rem 0;
}
.support--section .support-numbers .support-numbers-title {
  font-style: normal;
  font-size: 5rem;
  font-weight: 700;
  margin-top: 5rem;
  line-height: 1.2;
}
.support--section .support-numbers .support-numbers-quota {
  font-size: 17rem;
  font-weight: 900;
  line-height: 1;
  position: relative;
  display: inline-block;
}
.support--section .support-numbers .support-numbers-quota > .white {
  position: relative;
  z-index: 1;
}
.support--section .support-numbers .support-numbers-quota > .gradient {
  position: absolute;
  background: linear-gradient(to bottom, #3a497f -20%, #744381 50%, #db021d 150%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  top: 1rem;
  left: 1rem;
  z-index: 0;
}
.support--section .support-numbers .support-numbers-subtitle {
  font-style: normal;
  font-size: 4.5rem;
  font-weight: 400;
  text-transform: none;
  margin-top: 3rem;
  line-height: 1.2;
}
.support--section .support-numbers .support-numbers-caption {
  font-size: 3rem;
}
.support--section .support-numbers .support-numbers-hr {
  max-width: 58.8rem;
  width: 70%;
  border: none;
  border-top: solid 0.2rem #fff;
  margin: 10rem auto 8rem;
}
@media screen and (max-width: 1366px) {
  .support--section .support-numbers {
    padding: 9rem 0;
  }
  .support--section .support-numbers .support-numbers-title {
    font-size: 4rem;
  }
  .support--section .support-numbers .support-numbers-quota {
    font-size: 12.8rem;
  }
  .support--section .support-numbers .support-numbers-subtitle {
    font-size: 3rem;
  }
  .support--section .support-numbers .support-numbers-caption {
    font-size: 2.4rem;
  }
  .support--section .support-numbers .support-numbers-hr {
    margin: 8rem auto 6rem;
  }
}
@media screen and (max-width: 767px) {
  .support--section .support-numbers {
    padding: 6rem 0;
  }
  .support--section .support-numbers .support-numbers-title {
    font-size: 3rem;
  }
  .support--section .support-numbers .support-numbers-quota {
    font-size: 11.7rem;
  }
  .support--section .support-numbers .support-numbers-subtitle {
    font-size: 2.4rem;
  }
  .support--section .support-numbers .support-numbers-caption {
    font-size: 1.8rem;
  }
  .support--section .support-numbers .support-numbers-hr {
    margin: 6rem auto 5rem;
  }
}
@media screen and (max-width: 599px) {
  .support--section .support-numbers .support-numbers-quota {
    font-size: 6.2rem;
  }
  .support--section .support-numbers .support-numbers-quota > .gradient {
    top: 0.5rem;
    left: 0.5rem;
  }
}
.support--section.-register {
  overflow: hidden;
  background: url("/wp-content/themes/harnasxosp/src/images/support-bg-register.jpg") 50% -96px/1920px auto no-repeat;
  background-color: #0e1634;
  padding-bottom: 13rem;
  background-position: 48% -96px;
}
@media screen and (max-width: 1199px) {
  .support--section.-register {
    background-size: 1250px auto;
    background-position: 48% 0px;
  }
}
@media screen and (max-width: 767px) {
  .support--section.-register {
    padding-bottom: 60px;
    background-size: 767px auto;
    background-position: 48% 0px;
  }
}
@media screen and (max-width: 460px) {
  .support--section.-register {
    background-size: 540px auto;
    background-position: 48% 0px;
  }
}
.support--section.-winners {
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-bg-winners.jpg");
  background-size: contain;
  background-color: #073978;
  position: relative;
}
.support--section.-winners:after {
  content: "";
  position: absolute;
  width: 100%;
  aspect-ratio: 1.44;
  max-height: 994px;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-bg-winners-bottom.jpg");
}
.support--section.-form {
  padding-bottom: 0 !important;
}
.support--section .winners--box {
  position: relative;
  z-index: 1;
  background-image: url("/wp-content/themes/harnasxosp/src/images/winners-box.png");
  background-size: cover;
  mix-blend-mode: luminosity;
  max-width: 82rem;
  margin: 0 auto;
  padding: 10rem 2rem;
}
.support--section .winners--box .header {
  color: #fff;
  font-size: 7rem;
  font-style: normal;
}
.support--section .winners--box .paragraph {
  color: #fff;
  font-size: 3.3rem;
  font-weight: 700;
  text-transform: uppercase;
  max-width: 55rem;
  margin: 8rem auto 0;
  font-family: "Myriad Pro", sans-serif;
  letter-spacing: 0.18rem;
  line-height: 1.3;
}
@media screen and (max-width: 767px) {
  .support--section .winners--box {
    padding: 6rem 2rem;
  }
  .support--section .winners--box .header {
    font-size: 4.5rem;
  }
  .support--section .winners--box .paragraph {
    font-size: 2.5rem;
    margin: 4rem auto 0;
  }
}
.support--section .winners--caption {
  position: relative;
  display: block;
  z-index: 1;
  color: #063165;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 1rem 1rem 0.7rem;
  margin: 0 auto;
  max-width: 82rem;
}
@media screen and (max-width: 767px) {
  .support--section .winners--caption {
    font-size: 2rem;
  }
}
.support--section .winners--list {
  list-style: decimal;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.support--section .winners--list > .item {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  margin-left: 3rem;
  text-transform: uppercase;
}
.support--section .winners--list > .item:first-child {
  margin-top: 3rem;
}
.support--section .winners--list > .item:last-child {
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .support--section .winners--list > .item {
    font-size: 2rem;
  }
}
.support--section .contest--box {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #002d66;
  background-color: #fff;
  overflow: hidden;
  max-width: 107.7rem;
  border-radius: 4rem;
  margin-left: auto;
  margin-right: auto;
  padding: 6rem 3rem;
}
.support--section .contest--box .buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.7rem;
  row-gap: 3.3rem;
  margin: 0 auto;
  max-width: 89rem;
}
.support--section .contest--box .buttons a {
  color: #002d66;
  max-width: none;
  min-width: auto;
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 4.4rem;
}
.support--section .contest--box .buttons a:nth-child(1) {
  flex: 1 0 100%;
}
.support--section .contest--box .buttons a:nth-child(2), .support--section .contest--box .buttons a:nth-child(3) {
  flex: 1 1 45%;
}
@media screen and (max-width: 1280px) {
  .support--section .contest--box .buttons a {
    font-size: 4rem;
  }
}
@media screen and (max-width: 990px) {
  .support--section .contest--box .buttons a {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 767px) {
  .support--section .contest--box .buttons {
    column-gap: 16px;
    row-gap: 16px;
  }
  .support--section .contest--box .buttons a {
    font-size: 2.6rem;
  }
}
.support--section .contest--box.margin {
  margin-top: 7rem;
}
.support--section .contest--box > .header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  background: linear-gradient(to right, #dcb973 -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #dcb973 8.53%, #C99447 45.63%, #edca88 70.56%, #B37226 115.48%, #C99447 159.13%);
  font-size: 8.5rem;
  color: #fff;
  font-style: normal;
  padding: 2.2rem 0;
}
.support--section .contest--box .paragraph {
  font-size: 4.3rem;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 5rem;
  letter-spacing: -0.09rem;
  max-width: 93rem;
  margin-left: auto;
  margin-right: auto;
}
.support--section .contest--box .paragraph.lineheight15 {
  line-height: 1.3;
}
.support--section .contest--box .paragraph.big {
  font-size: 5rem;
}
.support--section .contest--box .paragraph.huge {
  font-size: 14rem;
}
.support--section .contest--box .paragraph.small {
  font-weight: 500;
  font-size: 3.4rem;
  max-width: 74rem;
}
.support--section .contest--box .paragraph.underline {
  text-decoration: underline;
}
.support--section .contest--box .paragraph span {
  display: inline-block;
  background: linear-gradient(to right, #dcb973 -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #dcb973 8.53%, #C99447 45.63%, #edca88 70.56%, #B37226 115.48%, #C99447 159.13%);
  padding: 0 1.6rem;
  margin: 0.6rem 0;
  line-height: 1.5;
}
.support--section .contest--box .hr {
  width: 100%;
  max-width: 73.5rem;
  border: none;
  border-top: 2px solid rgba(0, 50, 90, 0.43);
  margin: 3rem auto 3rem;
}
.support--section .contest--box.-header {
  padding-top: 19rem;
}
@media screen and (max-width: 990px) {
  .support--section .contest--box .paragraph.huge {
    font-size: 12rem;
  }
}
@media screen and (max-width: 767px) {
  .support--section .contest--box > .header {
    font-size: 5rem;
  }
  .support--section .contest--box .paragraph {
    font-size: 2.6rem;
    margin-bottom: 3rem;
  }
  .support--section .contest--box .paragraph.big {
    font-size: 3rem;
  }
  .support--section .contest--box .paragraph.huge {
    font-size: 5.6rem;
  }
  .support--section .contest--box .paragraph.small {
    font-size: 2.4rem;
  }
  .support--section .contest--box.-header {
    padding-top: 12rem;
  }
  .support--section .contest--box.margin {
    margin-top: 30px;
  }
}
.support--section .contest--paragraph {
  color: #fff;
  font-size: 3.4rem;
  line-height: 1;
  margin: 12rem 0 8rem;
  letter-spacing: -0.07rem;
}
@media screen and (max-width: 767px) {
  .support--section .contest--paragraph {
    font-size: 2rem;
    margin: 3rem 0 4rem;
  }
}
.support--section .contest--spacer {
  height: 5rem;
}
.support--section .support--slogan {
  text-align: center;
}
.support--section .support--slogan > .svg {
  max-width: 90rem;
}
.support--section .vote-steps {
  color: #fff;
  text-align: center;
  display: flex;
  column-gap: 3rem;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  max-width: 108rem;
}
.support--section .vote-steps .step {
  flex: 1 1 auto;
  max-width: 30%;
}
.support--section .vote-steps .step .number {
  font-size: 9rem;
  font-weight: 700;
  font-style: normal;
}
.support--section .vote-steps .step .description {
  font-size: 2.4rem;
  line-height: 1.2;
}
@media screen and (max-width: 990px) {
  .support--section .vote-steps {
    column-gap: 2rem;
  }
  .support--section .vote-steps .step {
    max-width: 25%;
  }
  .support--section .vote-steps .step .number {
    font-size: 6rem;
  }
  .support--section .vote-steps .step .description {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .support--section .vote-steps {
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
  }
  .support--section .vote-steps .step {
    max-width: 100%;
  }
}
.support--section .sub-hero-vote {
  margin-bottom: 4rem;
}
.support--section .rank--wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 99.4rem;
  padding: 6rem 0 10rem;
}
.support--section .rank--list {
  max-width: 60rem;
  padding: 7rem 1rem;
  margin: 0 auto;
}
.support--section .rank--list > .item {
  position: relative;
  padding-left: 7rem;
  color: #002d66;
  line-height: 1.2;
  margin-bottom: 3rem;
}
.support--section .rank--list > .item .num {
  position: absolute;
  top: 0;
  left: 0;
}
.support--section .rank--list > .item .num,
.support--section .rank--list > .item .osp {
  font-size: 3rem;
  font-weight: 700;
}
.support--section .rank--list > .item .adr {
  font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
  .support--section .rank--list > .item {
    padding-left: 50px;
    margin-bottom: 25px;
  }
  .support--section .rank--list > .item .num,
  .support--section .rank--list > .item .osp {
    font-size: 20px;
  }
  .support--section .rank--list > .item .adr {
    font-size: 16px;
  }
}
.support--section .search--input {
  position: relative;
}
.support--section .search--input > .input {
  max-width: 100%;
  width: 100%;
  border: 2px solid #002d66;
  color: #002d66;
  border-radius: 1rem;
  padding: 1.5rem 6.5rem 1.5rem 1.5rem;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.1;
  background-color: #fff;
}
.support--section .search--input > .input::placeholder {
  color: rgba(0, 44, 102, 0.3);
  text-transform: uppercase;
}
.support--section .search--input .glass {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  width: 34px;
  aspect-ratio: 1;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.support--section .search--input .glass:before {
  content: "";
  position: absolute;
  width: 85%;
  height: 85%;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 3px solid #002d66;
}
.support--section .search--input .glass:after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #002d66;
  height: 40%;
  transform-origin: center bottom;
  transform: rotate(-45deg);
  bottom: 1.3px;
  right: -0.4px;
}

.form--wrapper {
  background-color: #fff;
  color: #002d66;
}
.form--wrapper .paragraph {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
}
.form--wrapper .paragraph.big {
  font-size: 3rem;
  line-height: 1.1;
  text-transform: none;
}
.form--wrapper .paragraph.thin {
  font-weight: 500;
}
.form--wrapper .form--inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 108rem;
  padding: 6rem 0 10rem;
}
.form--wrapper .form--inner .form-spacer {
  height: 4rem;
}
@media screen and (max-width: 767px) {
  .form--wrapper .form--inner .form-spacer {
    height: 20px;
  }
}
.form--wrapper .form--inner .hint {
  font-style: italic;
  font-size: 1.8rem;
  margin-top: -1.9rem;
  line-height: 1;
}
.form--wrapper .form--inner .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.form--wrapper .form--inner .input label {
  font-size: 1.6rem;
  font-weight: 500;
}
.form--wrapper .form--inner .input input,
.form--wrapper .form--inner .input textarea {
  max-width: 59rem;
  width: 100%;
  border: 2px solid #002d66;
  color: #002d66;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 1.1;
  background-color: #fff;
  resize: none;
}
.form--wrapper .form--inner .checkbox--text {
  color: #002d66;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.8rem;
  cursor: pointer;
}
.form--wrapper .form--inner .checkbox--text a {
  color: #002d66;
  text-decoration: underline;
}
.form--wrapper .form--inner .checkbox--text a:hover {
  text-decoration: none;
  color: #003f92;
}
.form--wrapper .form--inner label.checkbox {
  color: #002d66;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  display: inline-block;
  display: grid;
  grid-template-columns: 2rem auto;
  gap: 1rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
}
.form--wrapper .form--inner label.checkbox a {
  color: #002d66;
  text-decoration: underline;
}
.form--wrapper .form--inner label.checkbox a:hover {
  text-decoration: none;
  color: #003f92;
}
.form--wrapper .form--inner label.checkbox input[type=checkbox] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 1px solid currentColor;
  border-radius: 2px;
  transform: translate(3px, -2px);
  display: grid;
  place-content: center;
}
.form--wrapper .form--inner label.checkbox input[type=checkbox]:before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: currentColor;
}
.form--wrapper .form--inner label.checkbox input[type=checkbox]:checked::before {
  transform: scale(1);
}
.form--wrapper .form--inner .submit-group {
  text-align: center;
}
.form--wrapper .form--inner .message--image > .img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.form--wrapper .unit--wrapper {
  display: flex;
  flex-wrap: wrap;
}
.form--wrapper .unit--wrapper > .selects {
  display: flex;
  flex-direction: column;
  flex: 1 0 55%;
}
.form--wrapper .unit--wrapper > .selects .select {
  margin-bottom: 1.2rem;
  display: block;
  max-width: 47.7rem;
  position: relative;
}
.form--wrapper .unit--wrapper > .selects .select:after {
  content: "";
  position: absolute;
  width: 2.5rem;
  height: 1.8rem;
  right: 7.4%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #002d66;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  pointer-events: none;
}
.form--wrapper .unit--wrapper > .selects .select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: 2px solid #002d66;
  color: #002d66;
  border-radius: 1rem;
  padding: 1.7rem 13%;
  font-size: 2.3rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  display: grid;
}
.form--wrapper .unit--wrapper > .selects .select select:-ms-expand {
  display: none;
}
.form--wrapper .unit--wrapper .info {
  font-style: italic;
  max-width: 47.7rem;
  margin-top: 1.5rem;
  font-size: 17px;
  line-height: 1.2;
}
.form--wrapper .unit--wrapper .info.-red {
  font-style: normal;
  color: #e21a2d;
  font-size: 18px;
  font-weight: 700;
}
.form--wrapper .unit--wrapper .info.-red a {
  color: #e21a2d;
}
.form--wrapper .unit--wrapper .info.-red a:hover {
  text-decoration: underline;
  color: #d74856;
}
.form--wrapper .unit--wrapper .selected-unit {
  flex: 1 0 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  max-width: 59rem;
}
.form--wrapper .unit--wrapper .selected-unit > .txt {
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 3rem;
  max-width: 46rem;
}
.form--wrapper .unit--wrapper .selected-unit > .txt span {
  font-weight: 500;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  display: inline-block;
}
@media screen and (min-width: 767px) and (max-width: 990px) {
  .form--wrapper .unit--wrapper .selected-unit > .txt {
    font-size: 2.2rem;
  }
  .form--wrapper .unit--wrapper .selected-unit > .txt span {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 767px) {
  .form--wrapper .unit--wrapper > .selects {
    flex: 1 0 80%;
  }
  .form--wrapper .unit--wrapper > .selects .select {
    max-width: 59rem;
  }
  .form--wrapper .unit--wrapper > .selects .info {
    max-width: 59rem;
  }
  .form--wrapper .unit--wrapper .selected-unit > .txt {
    font-size: 2.6rem;
  }
  .form--wrapper .unit--wrapper .selected-unit > .txt span {
    font-size: 2rem;
  }
}

.support--counter {
  display: flex;
  max-width: 102rem;
  margin: 2rem auto 0;
  align-items: stretch;
  column-gap: 5.3%;
}
.support--counter > .group {
  display: flex;
  flex: 1 0;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 8.6%;
  row-gap: 3rem;
}
.support--counter > .group > .digit {
  border-radius: 2rem;
  background: linear-gradient(to bottom, #3a497f -20%, #744381 50%, #db021d 150%);
  font-size: 12.6rem;
  line-height: 0.7;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0;
  aspect-ratio: 0.627;
}
.support--counter > .group > .caption {
  flex: 1 0 100%;
}
@media screen and (max-width: 1366px) {
  .support--counter > .group > .digit {
    font-size: 10rem;
  }
}
@media screen and (max-width: 990px) {
  .support--counter > .group > .digit {
    font-size: 8rem;
  }
}
@media screen and (max-width: 599px) {
  .support--counter {
    flex-direction: column;
    max-width: 120px;
    row-gap: 2rem;
  }
  .support--counter > .group {
    row-gap: 0.5rem;
  }
  .support--counter > .group > .digit {
    aspect-ratio: 0.8;
    border-radius: 1rem;
  }
}

.support-hero {
  position: relative;
  padding: 70rem 0 10rem 0;
  background: url(/wp-content/themes/harnasxosp/img/support-bg.jpg) top center/cover no-repeat;
}
@media screen and (max-width: 1680px) {
  .support-hero {
    padding: 60rem 0 10rem 0;
  }
}
@media screen and (max-width: 1366px) {
  .support-hero {
    padding: 50rem 0 10rem 0;
  }
}
@media screen and (max-width: 767px) {
  .support-hero {
    padding: 20rem 0 10rem 0;
  }
}
.support-hero::before {
  position: absolute;
  content: "";
  top: -90px;
  left: 50%;
  width: 100%;
  height: 100%;
  background: url(/wp-content/themes/harnasxosp/src/images/fireman.png) top center no-repeat;
  transform: translateX(-50%);
}
@media screen and (max-width: 1680px) {
  .support-hero::before {
    background-size: 1000px;
  }
}
@media screen and (max-width: 1366px) {
  .support-hero::before {
    background-size: 800px;
  }
}
@media screen and (max-width: 767px) {
  .support-hero::before {
    top: -40px;
    background-size: 450px;
  }
}
.support-hero.-register {
  background: none;
  padding: 9rem 0;
}
.support-hero.-register.-thanks {
  padding: 6rem 0;
}
.support-hero.-register::before {
  display: none;
}
@media screen and (max-width: 767px) {
  .support-hero.-register.-thanks {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 460px) {
  .support-hero.-register {
    padding: 2rem 0 2rem;
  }
}
.support-hero.-vote {
  background: url(/wp-content/themes/harnasxosp/src/images/support-bg-vote.jpg) bottom center no-repeat;
  background-size: cover;
  padding-top: 10rem;
  padding-bottom: 8rem;
  min-height: calc(100vh - 12rem);
}
.support-hero.-vote::before {
  display: none;
}
.support-hero.-vote .hr {
  margin: 8rem 0;
  width: 100%;
  border: none;
  border-top: 3px solid #fff;
}
.support-hero-container {
  position: relative;
  z-index: 5;
}
.support-hero-title, .support-hero-video {
  margin: 0 0 6rem 0;
}
@media screen and (max-width: 767px) {
  .support-hero-title, .support-hero-video {
    margin: 0 0 3rem 0;
  }
}
.support-hero-title, .support-hero-subtitle, .support-hero-text, .support-hero-winner, .support-hero-link {
  color: #fff;
}
.support-hero-title, .support-hero-subtitle-container, .support-hero-text, .support-hero-winner, .support-hero-btn-container, .support-hero-rules {
  text-align: center;
}
.support-hero-title {
  font-size: 9rem;
  text-align: center;
  line-height: normal;
  text-shadow: 0 0 20px #000;
}
.support-hero-title.-sub {
  font-size: 4.5rem;
  text-transform: none;
}
@media screen and (max-width: 1366px) {
  .support-hero-title {
    font-size: 7rem;
  }
  .support-hero-title.-sub {
    font-size: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .support-hero-title {
    font-size: 4rem;
  }
  .support-hero-title.-sub {
    font-size: 2.5rem;
  }
}
.support-hero-title-row {
  display: block;
}
.support-hero-subtitle-container {
  margin: 0 0 4rem 0;
}
@media screen and (max-width: 767px) {
  .support-hero-subtitle-container {
    margin: 0 0 2.5rem 0;
  }
}
.support-hero-subtitle {
  display: inline-block;
  padding: 5rem 10rem 0 10rem;
  border-top: 1px solid #a1772d;
  font-family: "Artegra Slab", arial, sans-serif;
  font-size: 11.5rem;
  font-style: initial;
}
@media screen and (max-width: 1366px) {
  .support-hero-subtitle {
    font-size: 8rem;
  }
}
@media screen and (max-width: 767px) {
  .support-hero-subtitle {
    display: block;
    padding: 3rem 1.5rem 0 1.5rem;
    font-size: 6rem;
  }
}
.support-hero-text {
  font-size: 4rem;
  line-height: 1.2;
}
@media screen and (max-width: 1366px) {
  .support-hero-text {
    font-size: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .support-hero-text {
    font-size: 2rem;
  }
}
.support-hero-row {
  margin: 0 0 4rem 0;
}
@media screen and (max-width: 767px) {
  .support-hero-row {
    margin: 0 0 3rem 0;
  }
}
.support-hero-prize {
  display: block;
  height: 8rem;
  margin: 0 auto 2rem auto;
}
.support-hero-winner {
  font-size: 2rem;
  text-transform: uppercase;
}
.support-hero-winner-important {
  font-size: 2.5rem;
  font-weight: 700;
}
.support-hero-btn-container {
  margin: 0 0 5rem 0;
}
.support-hero-btn, .support-hero-btn-text {
  display: inline-block;
}
.support-hero-btn {
  padding: 1.5rem 4.5rem;
  border: 0;
  background: #ab7c2b;
  color: #fff;
  font-size: 4rem;
  outline: 0;
  text-decoration: none;
  transform: skew(-20deg);
  transition: background 0.35s ease;
}
.support-hero-btn.is-disabled {
  pointer-events: none;
}
.support-hero-btn:hover {
  background: #976d26;
}
@media screen and (max-width: 1366px) {
  .support-hero-btn {
    font-size: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .support-hero-btn {
    font-size: 2rem;
  }
}
.support-hero-btn-text {
  transform: skew(20deg);
}
.support-hero-rules {
  margin: 8rem 0 0 0;
}
.support-hero-link {
  display: inline-block;
  font-size: 2.6rem;
}
@media screen and (max-width: 1366px) {
  .support-hero-link {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .support-hero-link {
    font-size: 2.2rem;
  }
}
.support-hero-link-important {
  font-weight: 700;
  text-decoration: underline;
}
.support-hero-img {
  display: block;
  width: 13rem;
  margin: 0 auto 1.5rem auto;
}
.support-qa {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1199px) {
  .support-qa {
    flex-direction: column;
  }
}
@media screen and (max-width: 1680px) {
  .support-qa-img {
    height: 1000px;
  }
}
@media screen and (max-width: 1366px) {
  .support-qa-img {
    height: 800px;
  }
}
@media screen and (max-width: 1199px) {
  .support-qa-img {
    height: 600px;
    margin: 0 0 2rem 0;
  }
}
@media screen and (max-width: 767px) {
  .support-qa-img {
    height: 450px;
  }
}
.support-qa-content {
  max-width: 85rem;
  margin: 0 0 0 18rem;
}
@media screen and (max-width: 1366px) {
  .support-qa-content {
    margin: 0 0 0 10rem;
  }
}
@media screen and (max-width: 1199px) {
  .support-qa-content {
    max-width: none;
    margin: 0 0 5rem 0;
  }
}
.support-qa-item:not(:last-of-type) {
  margin: 0 0 5rem 0;
}
@media screen and (max-width: 1199px) {
  .support-qa-item:not(:last-of-type) {
    margin: 0 0 5rem 0;
  }
}
.support-qa-question, .support-qa-answer, .support-qa-link, .support-qa-read-more-text {
  color: #fff;
}
.support-qa-question {
  position: relative;
  margin: 0 0 1rem 3.5rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .support-qa-question {
    margin: 0 0 1rem 0;
  }
}
.support-qa-question::before {
  position: absolute;
  top: 50%;
  left: -3.5rem;
  display: block;
  width: 1rem;
  height: 2rem;
  background: url(/wp-content/themes/harnasxosp/src/images/support-qa-arrow.svg) center center/contain no-repeat;
  content: "";
  transform: translateY(-50%);
}
@media screen and (max-width: 1199px) {
  .support-qa-question::before {
    display: none;
  }
}
.support-qa-answer, .support-qa-read-more, .support-qa-read-more-text {
  font-size: 2.2rem;
  line-height: 1.36;
}
.support-qa-answer {
  margin: 0 0 1.5rem 3.5rem;
}
@media screen and (max-width: 1199px) {
  .support-qa-answer {
    margin: 0 0 1.5rem 0;
  }
}
.support-qa-read-more {
  position: relative;
  padding: 0;
  border: 0;
  margin: 0 0 0 5.5rem;
  background: 0;
  color: #c40f28;
  cursor: pointer;
  font-family: "Source Sans 3", arial, sans-serif;
  font-weight: 700;
}
.support-qa-read-more::before {
  position: absolute;
  top: 50%;
  left: -2rem;
  display: block;
  width: 1rem;
  height: 1rem;
  background: url(/wp-content/themes/harnasxosp/src/images/support-qa-read-more-arrow.svg) center center/contain no-repeat;
  content: "";
  transform: translateY(-50%);
}
.support-qa-read-more.is-active::before {
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 1199px) {
  .support-qa-read-more {
    margin: 0 0 0 2rem;
  }
}
.support-qa-link:hover {
  text-decoration: underline;
}
.support-qa-read-more-text {
  margin: 1.5rem 0 0 3.5rem;
}
@media screen and (max-width: 1199px) {
  .support-qa-read-more-text {
    margin: 1.5rem 0 0 0;
  }
}
.support-qa-important {
  font-weight: 700;
}
.support-border {
  display: block;
  height: 2rem;
  background: linear-gradient(90deg, #824d1c, #b2873d);
}
.support-curiosities {
  padding: 8rem 0 10rem 0;
  background: linear-gradient(0deg, #7f171c 0%, #9f242a 50%, #7f171c 100%);
}
@media screen and (max-width: 767px) {
  .support-curiosities {
    padding: 8rem 0 15rem 0;
  }
}
.support-curiosities-title {
  position: relative;
  margin: 0 0 10rem 4rem;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
.support-curiosities-title::before {
  position: absolute;
  top: 50%;
  left: -4rem;
  display: block;
  width: 2rem;
  height: 1rem;
  background: url(/wp-content/themes/harnasxosp/src/images/support-curiosities-arrow.svg) center center/contain no-repeat;
  content: "";
  transform: translateY(-50%);
}
.support-curiosities-content {
  position: relative;
  padding: 0 10rem;
}
@media screen and (max-width: 767px) {
  .support-curiosities-content {
    padding: 0;
  }
}
.support .redBox {
  background-color: #e21a2d;
  padding: 25px 60px;
}
.support .redBox h1 {
  font-size: 10rem;
  color: #fff;
  font-style: normal !important;
  font-family: jubilat, serif;
  margin: 0;
}
.support .blueBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  background-color: #002d66;
  padding: 17px 22px;
  margin-bottom: 30px;
}
.support .blueBox p {
  font-size: 2.5rem;
  color: #fff;
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
}
.support .blueBox h1 {
  font-size: 6.8rem;
  color: #fff;
  font-style: normal !important;
  font-family: jubilat, serif;
  margin: 0;
}
.support .flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.support .redBtn {
  border-radius: 8px;
  background-color: #e21a2d;
}
.support .outlineBtn,
.support .redBtn {
  text-align: center;
  font-family: myriad-pro, sans-serif;
  font-weight: 700;
  margin: 60px 0 16px;
  color: #fff;
  padding: 15px 33px;
  line-height: 1.2;
}
.support .outlineBtn {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 8px;
}
.support .support-hero.support-hero--edit {
  padding: 10rem 0;
}
.support .support-hero-register {
  color: #fff;
  font-style: normal;
  text-align: center;
  font-size: 7.7rem;
  max-width: 140rem;
  display: block;
  margin: 0px auto 890px;
}
.support .support-hero-register span {
  font-size: 4.8rem;
  line-height: 1.1;
  display: inline-block;
}
.support .support-hero-register.-thanks {
  font-size: 11.3rem;
  color: #e21a2d;
  line-height: 0.9;
  text-shadow: rgba(17, 25, 47, 0.8) 1.2rem 1.2rem 0;
  margin: 0 auto 630px;
}
.support .support-hero-register.-thanks span {
  font-size: 7.6rem;
  color: #fff;
}
.support .support-hero-register.-winners {
  margin: 61rem auto 2.8rem;
  text-shadow: rgba(17, 25, 47, 0.8) 0 0 15px;
  line-height: 1.3;
}
@media screen and (max-width: 1680px) {
  .support .support-hero-register {
    margin: 0px auto 940px;
  }
  .support .support-hero-register.-thanks {
    margin: 0 auto 710px;
  }
  .support .support-hero-register.-winners {
    margin: 77rem auto 0;
  }
}
@media screen and (max-width: 1199px) {
  .support .support-hero-register {
    margin: 0px auto 64rem;
  }
  .support .support-hero-register.-winners {
    margin: 53rem auto 0;
  }
}
@media screen and (max-width: 767px) {
  .support .support-hero-register {
    margin: 0 auto 42.5rem;
    font-size: 4.2rem;
  }
  .support .support-hero-register span {
    font-size: 3.2rem;
  }
  .support .support-hero-register.-thanks {
    margin: 0 auto 32.5rem;
    font-size: 5.4rem;
    line-height: 1.4;
    text-shadow: rgba(17, 25, 47, 0.8) 4px 4px 0;
  }
  .support .support-hero-register.-thanks span {
    font-size: 3.3rem;
    line-height: 1.1;
  }
  .support .support-hero-register.-winners {
    margin: 30rem auto 0;
  }
}
@media screen and (max-width: 460px) {
  .support .support-hero-register {
    margin: 0 auto 32.8rem;
    font-size: 3.2rem;
  }
  .support .support-hero-register span {
    font-size: 2.2rem;
  }
  .support .support-hero-register.-winners {
    margin: 26rem auto 2.8rem;
  }
}
.support .support-hero.support-hero--edit:before {
  display: none;
}
.support .support-hero-title {
  font-style: normal;
  line-height: 1.3;
}
.support .support-hero-title .harnas-solo {
  position: relative;
  max-height: 14.2rem;
  top: 2.7rem;
}
.support .support-hero-title.-register {
  line-height: 1;
  font-size: 9rem;
  text-shadow: rgba(17, 25, 47, 0.8) 10px 10px 0;
  margin-bottom: 0;
}
.support .support-hero-title.-register .harnas-solo {
  top: 0;
  max-height: 17.3rem;
}
.support .support-hero-title.-red {
  color: #e21a2d;
}
@media screen and (max-width: 1680px) {
  .support .support-hero-title .harnas-solo {
    top: 3.4rem;
  }
}
@media screen and (max-width: 1366px) {
  .support .support-hero-title .harnas-solo {
    max-height: 11rem;
    top: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .support .support-hero-title .harnas-solo {
    max-height: 5.6rem;
    top: 1.4rem;
  }
  .support .support-hero-title.-register {
    font-size: 5.4rem;
    text-shadow: rgba(17, 25, 47, 0.8) 5px 5px 0;
  }
  .support .support-hero-title.-register .harnas-solo {
    max-height: 10rem;
  }
}
.support .support-hero-paragraph {
  text-align: center;
  color: #fff;
  font-size: 3rem;
  line-height: 1.3;
  max-width: 122rem;
  margin: 0 auto;
}
.support .support-hero-paragraph.-vote {
  font-size: 3rem;
  margin-bottom: 3rem;
}
.support .support-hero-paragraph.-vote span {
  font-size: 3.6rem;
}
@media screen and (max-width: 1366px) {
  .support .support-hero-paragraph {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .support .support-hero-paragraph {
    font-size: 2rem;
  }
}
.support .blueBoxVoting {
  width: 100%;
  max-width: 660px;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #002d66;
  margin-bottom: 2rem;
}
.support .blueBoxVoting p {
  margin: 0;
  color: #fff;
  font-size: 4rem;
  text-transform: uppercase;
  font-family: jubilat, serif;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
}
.support .redBoxVoting {
  width: 100%;
  max-width: 660px;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #e21a2d;
  margin: 4rem 0;
}
.support .redBoxVoting p {
  font-family: myriad-pro, sans-serif;
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 1.25;
  font-size: 3rem;
  margin-bottom: 35px;
}
.support .redBoxVoting p span {
  font-size: 5rem;
}
.support .spacer {
  display: block;
  width: 100%;
}
.support .spacer--100 {
  height: 10reml;
}
.support-curiosities-item {
  display: flex;
  align-items: center;
  margin: 2.5rem 0;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item {
    flex-direction: column;
    margin: 0;
  }
}
.support-curiosities-item--is-reversed {
  flex-direction: row-reverse;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item--is-reversed {
    flex-direction: column;
    margin: 5rem 0 0 0;
  }
}
.support-curiosities-item--is-reversed .support-curiosities-avatar {
  margin: 0 0 0 -13.85rem;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item--is-reversed .support-curiosities-avatar {
    margin: 0 0 -10rem 0;
  }
}
.support-curiosities-item--is-reversed .support-curiosities-box {
  padding: 0 21.35rem 0 7.5rem;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item--is-reversed .support-curiosities-box {
    padding: 15rem 4rem 4rem 4rem;
  }
}
.support-curiosities-item--is-reversed .support-curiosities-box::after {
  right: 17rem;
  left: auto;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item--is-reversed .support-curiosities-box::after {
    right: auto;
    left: 50%;
  }
}
.support-curiosities-item--is-reversed .support-curiosities-text {
  text-align: right;
}
@media screen and (max-width: 991px) {
  .support-curiosities-item--is-reversed .support-curiosities-text {
    text-align: center;
  }
}
.support-curiosities-avatar, .support-curiosities-avatar-inner {
  border-radius: 50%;
}
.support-curiosities-avatar {
  position: relative;
  z-index: 5;
  width: 27.7rem;
  height: 27.7rem;
  flex-shrink: 0;
  padding: 8px;
  margin: 0 -13.85rem 0 0;
  background: linear-gradient(0deg, #824d1c, #b2873d);
}
@media screen and (max-width: 991px) {
  .support-curiosities-avatar {
    width: 20rem;
    height: 20rem;
    margin: 0 0 -10rem 0;
  }
}
.support-curiosities-avatar-inner {
  overflow: hidden;
  height: 100%;
  background-color: #061c3d;
}
.support-curiosities-img {
  display: block;
  width: 100%;
  height: auto;
}
.support-curiosities-box, .support-curiosities-box-inner {
  display: flex;
  align-items: center;
}
.support-curiosities-box {
  position: relative;
  width: 90rem;
  height: 24rem;
  padding: 0 7.5rem 0 21.35rem;
  border-radius: 2rem;
  background: linear-gradient(0deg, #061c3d 0%, #0a2c50 50%);
}
@media screen and (max-width: 991px) {
  .support-curiosities-box {
    width: 100%;
    height: auto;
    padding: 15rem 4rem 4rem 4rem;
  }
}
.support-curiosities-box::after {
  position: absolute;
  top: 50%;
  left: 17rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #fff;
  content: "";
  transform: translateY(-50%);
}
@media screen and (max-width: 991px) {
  .support-curiosities-box::after {
    top: 12rem;
    left: 50%;
    margin: 0 0 0 -0.75rem;
    transform: none;
  }
}
@media screen and (max-width: 991px) {
  .support-curiosities-box-inner {
    height: 15rem;
    overflow-y: auto;
  }
}
.support-curiosities-text {
  color: #fff;
  font-size: 2.2rem;
  font-style: italic;
  line-height: 1.36;
}
@media screen and (max-width: 991px) {
  .support-curiosities-text {
    margin: auto;
    text-align: center;
  }
}
.support-curiosities-slider-btn {
  position: absolute;
  top: 50%;
  display: block;
  width: 6rem;
  height: 6rem;
  padding: 0;
  border: 0;
  margin: -3rem 0 0 0;
  background: 0;
  color: transparent;
  cursor: pointer;
  font-size: 0;
}
.support-curiosities-slider-btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 4rem;
  height: 4rem;
  margin: -2rem 0 0 -2rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: "";
  opacity: 0.4;
  transition: opacity 0.25s ease;
}
.support-curiosities-slider-btn:hover::after {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .support-curiosities-slider-btn {
    top: auto;
    bottom: -10rem;
    margin: 0;
    transform: translateX(-50%);
  }
}
.support-curiosities-slider-btn-next {
  right: -1.8rem;
}
@media screen and (max-width: 767px) {
  .support-curiosities-slider-btn-next {
    right: auto;
    left: 50%;
    margin: 0 0 0 50px;
  }
}
.support-curiosities-slider-btn-next::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-next.svg");
}
.support-curiosities-slider-btn-prev {
  left: -1.8rem;
}
@media screen and (max-width: 767px) {
  .support-curiosities-slider-btn-prev {
    left: 50%;
    margin: 0 0 0 -50px;
  }
}
.support-curiosities-slider-btn-prev::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-prev.svg");
}
.support-curiosities-source {
  display: block;
  margin: 2rem 0 0 0;
  color: #c40f28;
  font-size: 1.6rem;
}
.support-fire-department {
  padding: 15rem 0 15rem 0;
  background: linear-gradient(0deg, rgb(12, 40, 75) 0%, rgb(7, 31, 63) 50%);
}
@media screen and (max-width: 767px) {
  .support-fire-department {
    padding: 10rem 0 10rem 0;
  }
}
.support-fire-department-content {
  display: flex;
  align-items: center;
  /*
  @media screen and (max-width: 767px) {
      margin: 0 0 10rem 0;
  }
  */
}
@media screen and (max-width: 1199px) {
  .support-fire-department-content {
    flex-direction: column-reverse;
    align-items: unset;
  }
}
.support-fire-department-frame {
  width: calc(100% - 60rem);
  padding: 3px;
  aspect-ratio: 16/9;
  background: linear-gradient(0deg, #824d1c, #b2873d);
}
@media screen and (max-width: 1366px) {
  .support-fire-department-frame {
    width: calc(100% - 50rem);
  }
}
@media screen and (max-width: 1199px) {
  .support-fire-department-frame {
    width: auto;
    margin: 0 0 2rem 0;
  }
}
.support-fire-department-frame-inner {
  overflow: hidden;
  background-color: #000;
}
.support-fire-department-images img {
  display: block;
  width: 100%;
  height: auto;
}
.support-fire-department-slider {
  width: 60rem;
}
@media screen and (max-width: 1366px) {
  .support-fire-department-slider {
    width: 50rem;
  }
}
@media screen and (max-width: 1199px) {
  .support-fire-department-slider {
    width: auto;
  }
}
.support-fire-department-slider-header {
  padding: 0 2rem 2rem 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}
.support-fire-department-slider-crests img {
  display: block;
}
.support-fire-department-slider-caption, .support-fire-department-slider-name {
  display: block;
  line-height: 1;
  text-transform: uppercase;
}
.support-fire-department-slider-caption {
  margin: 0 0 2rem 0;
  color: #898989;
  font-size: 1.8rem;
}
.support-fire-department-slider-name {
  color: #fff;
  font-size: 3rem;
}
.support-fire-department-slider-navigation {
  display: flex;
}
.support-fire-department-slider-btn {
  position: relative;
  display: block;
  width: 10rem;
  height: 8rem;
  padding: 0;
  border: 0;
  background: 0;
  color: transparent;
  cursor: pointer;
  font-size: 0;
}
.support-fire-department-slider-btn::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 4rem;
  height: 4rem;
  margin: -2rem 0 0 -2rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  content: "";
  opacity: 0.4;
  transition: opacity 0.25s ease;
}
.support-fire-department-slider-btn:hover::after {
  opacity: 1;
}
.support-fire-department-slider-btn-next {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}
.support-fire-department-slider-btn-next::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-next.svg");
}
.support-fire-department-slider-btn-prev {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.support-fire-department-slider-btn-prev::after {
  background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-prev.svg");
}
.support-fire-department-slider-scrollable {
  height: 20rem;
  margin: 2rem 2rem 0 0;
  overflow-y: scroll;
}
@media screen and (max-width: 1199px) {
  .support-fire-department-slider-scrollable {
    margin: 2rem 0 0 0;
  }
}
.support-fire-department-slider-texts {
  padding: 0 2rem 0 0;
}
.support-fire-department-slider-text, .support-fire-department-slider-title {
  color: #fff;
  font-size: 2.2rem;
  line-height: 1.36;
}
.support-fire-department-slider-title {
  font-weight: 700;
}
.support-fire-department-slider-text:not(:last-of-type) {
  margin: 0 0 2rem 0;
}
.support-fire-department-btn {
  text-align: center;
}
.support-overlay {
  height: 60vh;
}
@media screen and (max-width: 767px) {
  .support-overlay {
    height: 300px;
  }
}

.contest-container {
  color: white;
}

.contest-container a {
  color: white;
}

.contest-container .mobile {
  display: block;
}

.contest-container .desktop {
  display: none;
}

.contest-container .contest-logo {
  margin-bottom: 60px;
  text-align: center;
}

.contest-container .contest-logo img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.contest-container .contest-intro {
  padding-bottom: 48px;
  margin-bottom: 40px;
  background: url("/wp-content/themes/harnasxosp/src/images/contest-arrow.svg") center bottom no-repeat transparent;
  background-size: 20px 10px;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

.contest-container .contest-intro strong {
  color: #dba952;
}

.contest-container .contest-more {
  margin-bottom: 100px;
  font-size: 22px;
  text-align: center;
}

.contest-container .contest-more strong {
  font-weight: 700;
  line-height: 1.2;
}

.contest-container .contest-column-1 {
  padding: 40px 0;
  text-align: center;
}

.contest-container .contest-column-1 h5 {
  font-size: 5rem;
}

.contest-container .contest-column-1 div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contest-container .contest-column-1 div span {
  width: 70px;
  border-radius: 50%;
  margin-right: 7px;
  background-color: white;
  color: #082548;
  font-size: 30px;
  font-weight: 700;
  line-height: 70px;
}

.contest-container .contest-column-1 div strong {
  color: #dba952;
  font-size: 80px;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
}

.contest-container .contest-column-1 p {
  font-size: 2rem;
}

.contest-container .contest-column-2 {
  padding: 40px 0 80px;
  text-align: center;
}

.contest-container .contest-column-2 img {
  margin-bottom: 23px;
}

.contest-container .contest-column-2 p {
  font-size: 3rem;
}

.contest-container .contest-column-2 p a {
  font-weight: 700;
  text-decoration: underline;
}

.contest-container .contest-column-2 .contest-box {
  position: relative;
  width: 100%;
  max-width: 864px;
  height: 0;
  max-height: 175px;
  padding-bottom: 20.25%;
  margin: 64px auto 0;
  background: url("/wp-content/themes/harnasxosp/src/images/contest-box.png") center center no-repeat transparent;
  background-size: contain;
}

.contest-container .contest-column-2 .contest-box p {
  position: absolute;
  top: 50%;
  right: 39%;
  left: 16.3%;
  display: block;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  text-align: left;
  transform: translateY(-50%);
  transform-origin: center;
}

.contest-container .contest-column-2 .contest-box p a {
  text-decoration: underline;
}

.contest-container .contest-column-2 .contest-box > a {
  position: absolute;
  top: 50%;
  left: 62%;
  display: block;
  padding: 1.5rem;
  background-color: white;
  color: #895520;
  transform: translateY(-50%);
  transform-origin: center;
}

@media (min-width: 460px) {
  .contest-container .contest-column-2 .contest-box p {
    font-size: 1.5rem;
  }
  .contest-container .contest-column-2 .contest-box > a {
    padding: 1.5rem 3rem;
  }
}
@media (min-width: 600px) {
  .contest-container .contest-column-2 .contest-box p {
    font-size: 1.75rem;
  }
  .contest-container .contest-column-2 .contest-box > a {
    padding: 2rem 4rem;
  }
}
@media (min-width: 900px) {
  .contest-container .contest-column-2 .contest-box p {
    font-size: 2.5rem;
  }
  .contest-container .contest-column-2 .contest-box > a {
    padding: 2.5rem 6rem;
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  .contest-container .mobile {
    display: none;
  }
  .contest-container .desktop {
    display: block;
  }
  .contest-container .contest-columns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 160px;
  }
  .contest-container .contest-column-1 {
    padding-right: 90px;
  }
  .contest-container .contest-column-2 {
    flex-grow: 1;
    padding-left: 90px;
    border-left: 3px solid #dba952;
  }
  .contest-container .contest-column-2 .contest-box p {
    font-size: 1.75rem;
  }
  .contest-container .contest-column-2 .contest-box > a {
    padding: 1.5rem 3rem;
    font-size: 3rem;
  }
}
@media (min-width: 1300px) {
  .contest-container .contest-column-2 .contest-box p {
    font-size: 2rem;
  }
}
@media (min-width: 1300px) {
  .contest-container .contest-column-2 .contest-box > a {
    padding: 2.5rem 6rem;
  }
}
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.rank--section {
  min-height: 100vh;
  padding: 3.5rem 0 6rem 0;
  background-image: url(/wp-content/themes/harnasxosp/img/support-bg.jpg);
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #052645;
}
@media screen and (max-width: 630px) {
  .rank--section {
    background-size: 200% auto;
  }
}
@media screen and (min-width: 768px) {
  .rank--section {
    padding: 3.5rem 0 14rem 0;
  }
}
.rank--section .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.rank--section .content > .heading {
  text-align: center;
  font-size: 3.6rem;
  font-style: normal;
  padding: 1rem;
  background-color: rgba(17, 52, 86, 0.8);
  display: inline-block;
  margin: 0 auto 2rem auto;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .heading {
    font-size: 8.4rem;
    padding: 2.3rem;
    margin: 0 auto 2.5rem auto;
  }
}
.rank--section .content > .subheading {
  text-transform: uppercase;
  font-size: 2.4rem;
  margin: 0 0 1.5rem 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .subheading {
    font-size: 3rem;
  }
}
.rank--section .content > .paragraph {
  font-size: 1.8rem;
  margin: 0 auto;
  text-align: center;
  max-width: 85rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .paragraph {
    font-size: 2.2rem;
  }
}
.rank--section .content > .paragraph:last-of-type {
  margin: 0 auto 3rem auto;
}
.rank--section .content > .label {
  font-size: 2.2rem;
  display: inline-block;
  margin: 0 0 2rem 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .label {
    font-size: 3rem;
  }
}
.rank--section .content > .input {
  border: none;
  background-color: #fff;
  display: inline-block;
  margin: 0 auto 2.5rem auto;
  line-height: 4rem;
  padding: 0 4.7rem 0 1.5rem;
  max-width: 63rem;
  width: 100%;
  font-size: 1.8rem;
  box-sizing: border-box;
  background-image: url(/wp-content/themes/harnasxosp/img/search.svg);
  background-size: 3.2rem auto;
  background-position: calc(100% - 15px) center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .input {
    font-size: 2.2rem;
    line-height: 5rem;
  }
}
.rank--section .content > .winner {
  display: flex;
  margin-bottom: 2.5rem;
}
.rank--section .content > .winner > img {
  width: 100%;
  height: auto;
}
.rank--section .content > .list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .list {
    gap: 3.5rem;
  }
}
.rank--section .content > .list > .block {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .list > .block {
    grid-template-columns: 6rem 1fr;
    grid-template-rows: auto auto;
  }
}
.rank--section .content > .list > .block > .position {
  font-weight: 700;
  font-size: 2.4rem;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .list > .block > .position {
    grid-column: 1/2;
    grid-row: 1/3;
    text-align: left;
    font-size: 3rem;
  }
}
.rank--section .content > .list > .block > .name {
  font-weight: 700;
  font-size: 2.4rem;
  margin: 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .list > .block > .name {
    text-align: left;
    font-size: 3rem;
  }
}
.rank--section .content > .list > .block > .address {
  font-size: 1.8rem;
  margin: 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .rank--section .content > .list > .block > .address {
    text-align: left;
    font-size: 2.2rem;
  }
}

/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * General
 */
/*
 * Components
 */
.vote--section {
  padding: 3.5rem 0 6rem 0;
  background-image: url(/wp-content/themes/harnasxosp/img/support-bg.jpg);
  background-size: cover;
  background-position: center;
}
.vote--section .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.vote--section .description > .heading {
  text-align: center;
  font-size: 3.6rem;
  font-style: normal;
  padding: 1rem;
  background-color: rgba(17, 52, 86, 0.8);
  display: inline-block;
  margin: 0 auto 2rem auto;
  color: #fff;
  text-transform: uppercase;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .vote--section .description > .heading {
    font-size: 8.4rem;
    padding: 2.3rem;
    margin: 0 auto 2.5rem auto;
  }
}
.vote--section .description > .info {
  text-align: center;
  margin: 0 auto 4.6rem auto;
  font-size: 2.2rem;
  width: 100%;
  max-width: 80rem;
}
.vote--section .description > .info > .text {
  margin: 0;
  font-size: 1.8rem;
}
.vote--section .description > .info > .text.-bg {
  width: fit-content;
  margin: 4rem auto 0 auto;
  padding: 0.5rem 2rem;
  background: #fff;
  text-transform: uppercase;
  color: #113456;
  font-weight: 700;
}
.vote--section .description > .steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 3rem auto;
}
.vote--section .description > .steps > .step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 992px) {
  .vote--section .description > .steps > .step:first-of-type > .number {
    position: relative;
  }
  .vote--section .description > .steps > .step:first-of-type > .number::after {
    position: absolute;
    left: 100%;
    top: 50%;
    width: 120%;
    content: "";
    background: #fff;
    height: 1px;
  }
  .vote--section .description > .steps > .step:last-of-type > .number {
    position: relative;
  }
  .vote--section .description > .steps > .step:last-of-type > .number::after {
    position: absolute;
    right: 100%;
    top: 50%;
    width: 120%;
    content: "";
    background: #fff;
    height: 1px;
  }
  .vote--section .description > .steps > .step:nth-of-type(2) > .number {
    position: relative;
  }
  .vote--section .description > .steps > .step:nth-of-type(2) > .number::after {
    position: absolute;
    left: 100%;
    top: 50%;
    width: 120%;
    content: "";
    background: #fff;
    height: 1px;
  }
  .vote--section .description > .steps > .step:nth-of-type(2) > .number::before {
    position: absolute;
    right: 100%;
    top: 50%;
    width: 120%;
    content: "";
    background: #fff;
    height: 1px;
  }
}
.vote--section .description > .steps > .step > .number {
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border: 1px solid #fff;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.vote--section .description > .steps > .step > .text {
  font-weight: 700;
  text-align: center;
  font-size: 2.2rem;
  margin: 0;
}
.vote--section .description > .steps > .step > .text > .small {
  display: block;
  font-size: 1.3rem;
}
@media screen and (min-width: 1300px) {
  .vote--section .description > .steps {
    gap: 0rem;
  }
  .vote--section .description > .steps > .step:first-of-type > .number::after, .vote--section .description > .steps > .step:first-of-type > .number::before, .vote--section .description > .steps > .step:last-of-type > .number::after, .vote--section .description > .steps > .step:last-of-type > .number::before, .vote--section .description > .steps > .step:nth-of-type(2) > .number::after, .vote--section .description > .steps > .step:nth-of-type(2) > .number::before {
    width: 140%;
  }
}
@media screen and (max-width: 992px) {
  .vote--section .description > .steps {
    grid-template-columns: repeat(1, 1fr);
  }
}
.vote--section .checkbox--list--wrapper {
  margin-top: 2rem;
}
.vote--section .checkbox--list--wrapper .checkbox--wrapper, .vote--section .checkbox--list--wrapper a {
  color: white;
  font-size: 12px;
}
.vote--section .checkbox--list--wrapper a {
  font-weight: bold;
}

.select--unit {
  position: relative;
  color: #fff;
  z-index: 1;
}
.select--unit:before {
  position: absolute;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100%;
  transform: translateX(-50%);
  content: "";
  background: #113456;
  z-index: 0;
}
.select--unit > .grid {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 6rem;
}
@media screen and (max-width: 768px) {
  .select--unit > .grid {
    display: flex;
    flex-direction: column;
  }
  .select--unit > .grid > .select {
    width: 100%;
  }
}
.select--unit > .grid > .select > .paragraph {
  margin: 0 0 2.5rem 0;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
}
.select--unit > .grid > .select > select {
  font-size: 2.5rem;
  display: block;
  width: 100%;
  height: 6.5rem;
  text-align: center;
  color: #113456;
  font-weight: 700;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-image: url(/wp-content/themes/harnasxosp/img/triangle.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) center;
  padding-right: 5rem;
}
.select--unit > .grid > .select > select:not(:last-of-type) {
  margin-bottom: 1rem;
}
.select--unit > .grid > .selected {
  position: relative;
}
.select--unit > .grid > .selected > .description {
  display: grid;
  grid-template-columns: 7.5rem 1fr;
  background-color: rgba(17, 52, 86, 0.8);
  gap: 2rem;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
}
.select--unit > .grid > .selected > .description > img {
  width: 100%;
  height: auto;
  background: #fff;
}
@media screen and (min-width: 768px) {
  .select--unit > .grid > .selected > .description {
    padding: 2rem 2.5rem;
  }
}
.select--unit > .grid > .selected > .description > .details > .name {
  margin: 0 0 0.8rem 0;
  font-weight: 700;
  font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .select--unit > .grid > .selected > .description > .details > .name {
    font-size: 2.3rem;
  }
}
.select--unit > .grid > .selected > .description > .details > .address {
  margin: 0;
  font-size: 1.3rem;
}
.select--unit > .grid > .selected > .picture {
  display: flex;
}
.select--unit > .grid > .selected > .picture > img {
  width: 100%;
  height: auto;
  max-height: 400px;
}

.vote--button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8rem 0 4rem 0;
}
.vote--button > .paragraph {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}
.vote--button > button {
  color: #fff;
  font-weight: 700;
  font-size: 4rem;
  padding: 2.5rem 5rem;
  border-radius: 0.8rem;
  background-color: #E21A2D;
  border: none;
  margin: 0 0 3rem 0;
}
.vote--button > button:hover {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .vote--button > button {
    padding: 2.5rem 10rem;
    font-size: 6.2rem;
  }
}

@media screen and (max-width: 767px) {
  #applicationRegisterOspFrom {
    display: block;
  }
  .block-application-form .h-container {
    padding: 0 0rem;
  }
  .block-application-form .block-application-form__grid {
    display: block;
  }
}
.how-to {
  background-color: #002d66;
  padding: 8rem 0 4rem;
}
.how-to .header {
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  color: white;
  text-align: center;
}
.how-to-steps {
  display: flex;
  gap: 74px;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 50px;
  gap: 4.3%;
}
.how-to-step {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: contain;
  aspect-ratio: 0.88;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 20%;
  width: 20%;
  padding: 21% 25px 30px 25px;
}
.how-to-step.-one {
  background-image: url(/wp-content/themes/harnasxosp/img/how-to-step1.png);
}
.how-to-step.-two {
  background-image: url(/wp-content/themes/harnasxosp/img/how-to-step2.png);
}
.how-to-step.-three {
  background-image: url(/wp-content/themes/harnasxosp/img/how-to-step3.png);
}
.how-to-step > .big {
  text-transform: uppercase;
  font-size: 50px;
  color: #b70916;
  font-weight: 900;
  line-height: 1;
}
.how-to-step > .big > span {
  display: inline-block;
  background-color: #b70916;
  color: white;
  border-radius: 100px;
  width: 62px;
  height: 62px;
  padding: 3px 13px;
  margin-right: 20px;
}
.how-to-step > .small {
  text-align: center;
  font-size: calc(-5px + 1.6vw);
  color: #002d66;
  font-weight: 700;
  line-height: 1.3;
  margin-top: 10px;
  padding: 0px 45px;
  white-space: nowrap;
}
.how-to .disclaimer {
  color: white;
  text-align: center;
  line-height: 1.2;
  font-size: 2.4rem;
  font-weight: 600;
}
@media screen and (max-width: 1680px) {
  .how-to-step > .big {
    font-size: 36px;
  }
  .how-to-step > .big > span {
    width: 50px;
    height: 50px;
    padding: 5px 13px;
    margin-right: 9px;
  }
}
@media screen and (max-width: 1200px) {
  .how-to-step {
    padding: 20% 5px 0px 5px;
  }
  .how-to-step > .big {
    font-size: 27px;
  }
  .how-to-step > .big > span {
    width: 40px;
    height: 40px;
    padding: 4px 11px;
    margin-right: 11px;
  }
  .how-to-step > .small {
    font-size: calc(-2px + 1.6vw);
    margin-top: 2px;
  }
}
@media screen and (max-width: 990px) {
  .how-to .header {
    font-size: 28px;
  }
  .how-to-steps {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
  .how-to-step {
    flex: 1 1 100%;
    width: 100%;
    padding: 68% 25px 10px 25px;
  }
  .how-to-step > .small {
    font-size: 15px;
  }
  .how-to .disclaimer {
    font-size: 12px;
  }
}
@media screen and (max-width: 460px) {
  .how-to-step > .small {
    font-size: calc(10px + 1vw);
  }
}

.our--beers {
  overflow: hidden;
}

.content--volts {
  position: relative;
  padding: 2px 10px 12px 10px;
  border: 3px #D2A463 solid;
  border-left-color: transparent;
  border-right-color: transparent;
  min-width: 17.6rem;
}
.content--volts > .amount {
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}
.content--volts > .amount span {
  font-weight: 500;
  font-size: 1.2rem;
}
.content--volts > .desc {
  font-size: 1.6rem;
  position: absolute;
  text-align: center;
  font-weight: 700;
  color: #002D66;
  background: linear-gradient(to right, #E7C37B -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #F9E6C6 8.53%, #C99447 45.63%, #ECC67E 80.56%, #B37226 115.48%, #C99447 159.13%);
  line-height: 1;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, calc(50% + 1px));
  min-width: 8.2rem;
  padding: 0.3rem 0.6rem;
}

.beer--item {
  color: #fff;
  background-size: cover;
  position: relative;
}
.beer--item:nth-child(3n) .content--volts {
  border-top-color: #002D66;
  border-bottom-color: #002D66;
}
.beer--item:nth-child(3n) .content--volts > .desc {
  background: linear-gradient(to right, #002F6A 0%, #005ACC 33%, #002F6A 92%);
  color: #D2A463;
}
.beer--item:nth-child(3n) .inner > .details > .ingredients table tr td {
  border-color: #002D66;
}
.beer--item .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.beer--item .inner > .details {
  order: 2;
  padding: 5% 20px;
}
.beer--item .inner > .details h1, .beer--item .inner > .details h2, .beer--item .inner > .details h3, .beer--item .inner > .details h4, .beer--item .inner > .details h5, .beer--item .inner > .details h6 {
  font-style: normal;
  font-size: 3.6rem;
  font-weight: 900;
  margin-bottom: 5rem;
}
.beer--item .inner > .details em {
  font-style: italic;
}
.beer--item .inner > .details > .description p {
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 2.3rem;
}
.beer--item .inner > .details > .description button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  background-color: #D2A463;
  padding: 0.3rem 1.4rem 0.2rem 1.4rem;
  border-radius: 0.4rem;
  text-decoration: underline;
}
.beer--item .inner > .details > .description button:hover {
  text-decoration: none;
}
.beer--item .inner > .details > .content {
  margin: 2.3rem 0 2.6rem;
  display: flex;
  column-gap: 4.8rem;
  row-gap: 2rem;
  flex-wrap: wrap;
}
.beer--item .inner > .details > .ingredients {
  margin-top: 5rem;
}
.beer--item .inner > .details > .ingredients h1, .beer--item .inner > .details > .ingredients h2, .beer--item .inner > .details > .ingredients h3, .beer--item .inner > .details > .ingredients h4, .beer--item .inner > .details > .ingredients h5, .beer--item .inner > .details > .ingredients h6 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  margin-top: 5rem;
}
.beer--item .inner > .details > .ingredients p {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.beer--item .inner > .details > .ingredients table {
  font-size: 13px;
  text-align: center;
  line-height: 1.1;
}
.beer--item .inner > .details > .ingredients table tr td {
  vertical-align: middle;
  border: 2px solid #D2A463;
  padding: 8px 6px;
}
@media screen and (max-width: 599px) {
  .beer--item .inner > .details > .ingredients table {
    height: auto !important;
  }
  .beer--item .inner > .details > .ingredients table tbody {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .beer--item .inner > .details > .ingredients table tbody tr {
    height: auto !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-right: -2px;
    flex: 1 1 50%;
  }
  .beer--item .inner > .details > .ingredients table tbody tr td {
    width: 100% !important;
    height: 50px !important;
    margin-top: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 8px;
  }
}
.beer--item .inner > .packshot {
  order: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 550px;
  margin-top: -60px;
  margin-bottom: -70px;
}
.beer--item .inner > .packshot > .image {
  max-width: 100%;
}
@media screen and (min-width: 1024px) {
  .beer--item .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .beer--item .inner > .details {
    flex: 1 0 50%;
  }
  .beer--item .inner > .packshot {
    flex: 1 1 50%;
  }
  .beer--item .inner > .packshot > .image {
    margin-left: -7%;
    margin-right: auto;
  }
  .beer--item:nth-child(2n-1) .inner > .details {
    order: 1;
  }
  .beer--item:nth-child(2n-1) .inner > .packshot {
    order: 2;
  }
  .beer--item:nth-child(2n-1) .inner > .packshot > .image {
    margin-right: -7%;
    margin-left: auto;
  }
}

.our--beers--popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 45, 102, 0.67);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s linear;
}
.our--beers--popup > .packshoot {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}
.our--beers--popup.-visible {
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
}

.bg--desk {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-position: center;
}
@media screen and (min-width: 1024px) {
  .bg--desk {
    display: block;
  }
}

.bg--mob {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
@media screen and (min-width: 1024px) {
  .bg--mob {
    display: none;
  }
}

.lottery-hero {
  overflow: hidden;
  background-image: url("/wp-content/themes/harnasxosp/src/images/hero-bg-lottery.jpg");
  background-position: center bottom;
  background-size: cover;
}
.lottery-hero .wrapper {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 430px;
  margin: 30px auto 0;
}
.lottery-hero .wrapper > .hero-image {
  max-width: 100%;
  display: flex;
}
.lottery-hero .wrapper > .hero-image img {
  max-width: 100%;
  margin-bottom: -53px;
}
.lottery-hero .wrapper > .hero-slogan {
  max-width: 520px;
}
.lottery-hero .wrapper > .hero-slogan img {
  max-width: 100%;
}
.lottery-hero .wrapper > .hero-slogan > .txt {
  font-size: 20px;
  line-height: 1.2;
  color: #002d66;
  font-family: "Source Sans 3", arial, sans-serif;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
}
.lottery-hero .wrapper > .hero-slogan .rules {
  font-size: 20px;
  font-weight: 700;
  color: #002d66;
  background-color: #fff;
  padding: 2px 11px;
  margin-bottom: 50px;
  display: inline-block;
  border-radius: 4px;
  transition: all 200ms linear;
  white-space: nowrap;
}
.lottery-hero .wrapper > .hero-slogan .rules:hover {
  background-color: #002d66;
  color: #fff;
}
.lottery-hero .hero-contact {
  color: #fff;
  text-align: center;
  line-height: 1.1;
  height: 52px;
  padding: 10px 0px;
  font-size: 12px;
  font-weight: 600;
  text-shadow: 1px 1px 1px #101728;
}
.lottery-hero .hero-contact a {
  color: #fff;
  white-space: nowrap;
}
.lottery-hero .hero-contact a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 460px) {
  .lottery-hero .hero-contact {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .lottery-hero .wrapper {
    flex-direction: row;
    align-items: end;
    max-width: unset;
    gap: 50px;
  }
  .lottery-hero .wrapper > .hero-image {
    flex: 1 1 50%;
    max-width: 50%;
  }
  .lottery-hero .wrapper > .hero-image > .img {
    margin-top: -20px;
    margin-left: -93px;
    margin-right: -100px;
    margin-bottom: -35px;
    flex-grow: 1;
    width: 100%;
    max-width: unset;
  }
  .lottery-hero .wrapper > .hero-slogan {
    flex: 1 1 50%;
    margin-left: 100px;
  }
  .lottery-hero .wrapper > .hero-slogan > .txt {
    font-size: 30px;
  }
  .lottery-hero .hero-contact {
    white-space: nowrap;
    font-size: 15px;
    height: 34px;
  }
}
@media screen and (min-width: 900px) {
  .lottery-hero .wrapper > .hero-image > .img {
    margin-bottom: -65px;
  }
  .lottery-hero .wrapper > .hero-slogan .rules {
    font-size: 24px;
  }
  .lottery-hero .hero-contact {
    font-size: 18px;
    padding: 10px 0px 20px;
    height: 64px;
  }
}
@media screen and (min-width: 1200px) {
  .lottery-hero .hero-contact {
    font-size: 24px;
  }
}
@media screen and (min-width: 1300px) {
  .lottery-hero .wrapper > .hero-image .img {
    margin-top: -90px;
    margin-left: -153px;
    margin-right: -120px;
  }
  .lottery-hero .wrapper > .hero-slogan .rules {
    margin-top: 10px;
    margin-left: 95px;
    padding: 4px 8px;
    line-height: 1;
  }
}
@media screen and (min-width: 1520px) {
  .lottery-hero .wrapper > .hero-image .img {
    margin-top: -280px;
    margin-left: -3px;
    margin-right: -87px;
  }
}