
// darkmode.scss

@import "../utilities/variables";

.darkmode {

    &,
    .navbar,
    .navigation,
    .footer {
        background-color: $darkmode-color-1;
    }

    a {
        color: $darkmode-color-2;
        transition: color .25s ease;

        &:hover {
            color: $darkmode-color-3;
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $darkmode-color-2;
    }

    .hamburger {

        &:hover {

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $darkmode-color-3;
            }
        }
    }

    .navigation-link.is-active {
        color: $darkmode-color-3;
    }

    .footer {
        border-top: 1px solid $darkmode-color-2;

        &-navigation-item:not(:last-of-type)::after {
            background-color: $darkmode-color-2;
        }
    }
}
