@import "../utilities/variables";

// support.scss

.support--section {

    .header {
        >.heading {
            text-align: center;
            text-transform: uppercase;
            font-size: 6.8rem;
            margin: -24rem auto 5rem auto;
            max-width: 100rem;
        }

        >.img {
            margin: 0 auto;
            display: block;
        }
    }

    .details {
        background-color: rgba($blue, 0.8);
        width: 100%;
        max-width: 66rem;
        margin: 0 auto 6rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem 0 0 0;
        color: #fff;

        >p {
            text-align: center;
            padding: 0 1rem;
            font-weight: $font-weight-bold;
            margin: 0;
        }

        >.heading {
            text-transform: uppercase;
            font-size: 4.4rem;
            margin: 0 0 3rem 0;
            padding: 0 1rem;

            @media screen and (min-width: 768px) {
                font-size: 5.4rem;
            }
        }

        >.paragraph {
            text-transform: uppercase;
            font-size: 2rem;
            margin: 0 0 3rem 0;
            line-height: normal;

            &.-item {
                margin-bottom: 0;
                padding: 0.3rem 0;
                font-size: 2rem;
            }

            @media screen and (min-width: 768px) {
                font-size: 3rem;
            }
        }

        .spacer--small {
            margin-top: 2rem;
        }



        >.description {
            font-size: 2.3rem;
            text-transform: uppercase;
        }

        >.date {
            font-size: 4rem;
            margin-bottom: 3rem;
            text-transform: uppercase;
            line-height: normal;

            @media screen and (min-width: 768px) {
                font-size: 6rem;
            }
        }

        >.info {
            text-transform: uppercase;
            color: $blue;
            background-color: $white;
            padding: 0;
            display: block;
            width: 100%;
            font-size: 3rem;
        }
    }

    .options {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 6rem;

        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
        }

        >.option {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 991px) {
                margin-top: 1rem;
            }

            >.button {
                background: $red-5;
                color: $white;
                font-weight: $font-weight-bold;
                text-align: center;
                border-radius: 0.8rem;
                font-size: 2.4rem;
                text-decoration: none;
                width: 100%;
                padding: 2rem 1rem;
                box-sizing: border-box;
                margin-bottom: 2rem;

                @media screen and (min-width: 768px) {
                    margin-bottom: 3rem;
                    font-size: 2.8rem;
                    padding: 2.5rem 1.5rem;
                }
            }
        }
    }

    .extra {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 4rem;

        @media screen and (min-width: 768px) {
            padding-bottom: 7rem;
        }

        >.regulations {
            color: $white;
            border: 1px solid $white;
            text-decoration: none;
            border-radius: 0.8rem;
            padding: 2rem 3rem;
            font-size: 2rem;
            margin-bottom: 1.5rem;
            font-weight: $font-weight-bold;
        }
    }

    .support-announcement {
        background-color: #fff;
        color: #002d66;
        font-size: 5.8rem;
        text-align: center;
        font-weight: 700;
        line-height: 1.1;
        padding: 8rem 0;

        @media screen and (max-width: 1366px) {
            font-size: 4.5rem;
            padding: 6rem 0;
        }

        @media screen and (max-width: 767px) {
            font-size: 3.6rem;
            padding: 5rem 0;
        }
    }

    .support-numbers {
        background-color: #052644;
        color: #fff;
        text-align: center;
        padding: 6rem 0;

        .support-numbers-title {
            font-style: normal;
            font-size: 5rem;
            font-weight: 700;
            margin-top: 5rem;
            line-height: 1.2;
        }

        .support-numbers-quota {
            font-size: 17rem;
            font-weight: 900;
            line-height: 1;
            position: relative;
            display: inline-block;

            >.white {
                position: relative;
                z-index: 1;
            }

            >.gradient {
                position: absolute;
                background: linear-gradient(to bottom, #3a497f -20%, #744381 50%, #db021d 150%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                top: 1rem;
                left: 1rem;
                z-index: 0;
            }
        }

        .support-numbers-subtitle {
            font-style: normal;
            font-size: 4.5rem;
            font-weight: 400;
            text-transform: none;
            margin-top: 3rem;
            line-height: 1.2;
        }

        .support-numbers-caption {
            font-size: 3rem;
        }

        .support-numbers-hr {
            max-width: 58.8rem;
            width: 70%;
            border: none;
            border-top: solid .2rem #fff;
            margin: 10rem auto 8rem;
        }

        @media screen and (max-width: 1366px) {
            padding: 9rem 0;

            .support-numbers-title {
                font-size: 4rem;
            }

            .support-numbers-quota {
                font-size: 12.8rem;
            }

            .support-numbers-subtitle {
                font-size: 3rem;
            }

            .support-numbers-caption {
                font-size: 2.4rem;
            }

            .support-numbers-hr {
                margin: 8rem auto 6rem;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 6rem 0;

            .support-numbers-title {
                font-size: 3rem;
            }

            .support-numbers-quota {
                font-size: 11.7rem;
            }

            .support-numbers-subtitle {
                font-size: 2.4rem;
            }

            .support-numbers-caption {
                font-size: 1.8rem;
            }

            .support-numbers-hr {
                margin: 6rem auto 5rem;
            }
        }

        @media screen and (max-width: 599px) {
            .support-numbers-quota {
                font-size: 6.2rem;

                >.gradient {
                    top: .5rem;
                    left: .5rem;
                }
            }
        }
    }

    &.-register {
        overflow: hidden;
        background: url('/wp-content/themes/harnasxosp/src/images/support-bg-register.jpg') 50% -96px / 1920px auto no-repeat;
        background-color: #0e1634;
        padding-bottom: 13rem;
        background-position: 48% -96px;

        @media screen and (max-width: 1199px) {
            background-size: 1250px auto;
            background-position: 48% 0px;
        }

        @media screen and (max-width: 767px) {
            padding-bottom: 60px;
            background-size: 767px auto;
            background-position: 48% 0px;

            &.-thanks {
                // background-size: 1110px auto;
            }
        }

        @media screen and (max-width: 460px) {
            background-size: 540px auto;
            background-position: 48% 0px;
        }

    }

    &.-winners {
        background-image: url('/wp-content/themes/harnasxosp/src/images/support-bg-winners.jpg');
        background-size:contain;
        background-color: #073978;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            aspect-ratio: 1.44;
            max-height: 994px;
            bottom: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('/wp-content/themes/harnasxosp/src/images/support-bg-winners-bottom.jpg');
        }
    }

    &.-form {
        padding-bottom: 0 !important;
    }

    .winners--box {
        position: relative;
        z-index: 1;
        background-image: url('/wp-content/themes/harnasxosp/src/images/winners-box.png');
        background-size: cover;
        mix-blend-mode: luminosity;
        max-width: 82rem;
        margin: 0 auto;
        padding: 10rem 2rem;

        .header {
            color: #fff;
            font-size: 7rem;
            font-style: normal;
        }

        .paragraph {
            color: #fff;
            font-size: 3.3rem;
            font-weight: 700;
            text-transform: uppercase;
            max-width: 55rem;
            margin: 8rem auto 0;
            font-family: 'Myriad Pro', sans-serif;
            letter-spacing: .18rem;
            line-height: 1.3;
        }

        @media screen and (max-width: 767px) {
            padding: 6rem 2rem;
            .header {
                font-size: 4.5rem;
            }
            .paragraph {
                font-size: 2.5rem;
                margin: 4rem auto 0;
            }
        }
    }

    .winners--caption {
        position: relative;
        display: block;
        z-index: 1;
        color: #063165;
        background-color: #fff;
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.5;
        padding: 1rem 1rem .7rem;
        margin: 0 auto;
        max-width: 82rem;

        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }

    }

    .winners--list {
        list-style: decimal;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        >.item {
            color: #fff;
            font-size: 3rem;
            font-weight: 700;
            line-height: 1.5;
            margin-left: 3rem;
            text-transform: uppercase;

            &:first-child {
                margin-top: 3rem;
            }

            &:last-child {
                margin-bottom: 3rem;
            }
        }

        @media screen and (max-width: 767px) {
            >.item {
                font-size: 2rem;
            }
        }
    }

    .contest--box {
        position: relative;
        z-index: 2;
        text-align: center;
        color: #002d66;
        background-color: #fff;
        overflow: hidden;
        max-width: 107.7rem;
        border-radius: 4rem;
        margin-left: auto;
        margin-right: auto;
        padding: 6rem 3rem;
        // display: flex;
        // flex-direction: column;
        // align-items: center;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1.7rem;
            row-gap: 3.3rem;
            margin: 0 auto;
            max-width: 89rem;

            a {
                color: #002d66;
                max-width: none;
                min-width: auto;
                white-space: nowrap;
                padding-left: 1rem;
                padding-right: 1rem;
                font-size: 4.4rem;

                &:nth-child(1) {
                    flex: 1 0 100%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    flex: 1 1 45%;
                }
            }

            @media screen and (max-width: 1280px) {
                a {
                    font-size: 4rem;
                }
            }

            @media screen and (max-width: 990px) {
                a {
                    font-size: 3.5rem;
                }
            }

            @media screen and (max-width: 767px) {
                column-gap: 16px;
                row-gap: 16px;

                a {
                    font-size: 2.6rem;
                }
            }
        }

        &.margin {
            margin-top: 7rem;
        }


        >.header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            background: linear-gradient(to right, #dcb973 -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #dcb973 8.53%, #C99447 45.63%, #edca88 70.56%, #B37226 115.48%, #C99447 159.13%);
            font-size: 8.5rem;
            color: #fff;
            font-style: normal;
            padding: 2.2rem 0;
        }

        .paragraph {
            font-size: 4.3rem;
            font-weight: 700;
            line-height: 1.1;
            margin-bottom: 5rem;
            letter-spacing: -0.09rem;
            max-width: 93rem;
            margin-left: auto;
            margin-right: auto;

            &.lineheight15 {
                line-height: 1.3;
            }

            &.big {
                font-size: 5rem;
            }

            &.huge {
                font-size: 14rem;
            }

            &.small {
                font-weight: 500;
                font-size: 3.4rem;
                max-width: 74rem;
            }

            &.underline {
                text-decoration: underline;
            }

            span {
                display: inline-block;
                background: linear-gradient(to right, #dcb973 -59.13%, #9A611D -37.3%, #F9D68C -13.29%, #dcb973 8.53%, #C99447 45.63%, #edca88 70.56%, #B37226 115.48%, #C99447 159.13%);
                padding: 0 1.6rem;
                margin: .6rem 0;
                line-height: 1.5;
            }
        }

        .hr {
            width: 100%;
            max-width: 73.5rem;
            border: none;
            border-top: 2px solid rgba(0, 50, 90, 0.43);
            margin: 3rem auto 3rem;
        }

        &.-header {
            padding-top: 19rem;
        }

        @media screen and (max-width: 990px) {
            .paragraph {
                &.huge {
                    font-size: 12rem;
                }
            }
        }

        @media screen and (max-width: 767px) {
            >.header {
                font-size: 5rem;
            }

            .paragraph {
                font-size: 2.6rem;
                margin-bottom: 3rem;

                &.big {
                    font-size: 3rem;
                }

                &.huge {
                    font-size: 5.6rem;
                }

                &.small {
                    font-size: 2.4rem;
                }
            }

            &.-header {
                padding-top: 12rem;
            }

            &.margin {
                margin-top: 30px;
            }
        }

    }

    .contest--paragraph {
        color: #fff;
        font-size: 3.4rem;
        line-height: 1;
        margin: 12rem 0 8rem;
        letter-spacing: -0.07rem;

        @media screen and (max-width: 767px) {
            font-size: 2rem;
            margin: 3rem 0 4rem;
        }
    }

    .contest--spacer {
        height: 5rem;
    }

    .support--slogan {
        text-align: center;

        >.svg {
            max-width: 90rem;
        }
    }


    .vote-steps {
        color: #fff;
        text-align: center;
        display: flex;
        column-gap: 3rem;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        max-width: 108rem;

        .step {
            flex: 1 1 auto;
            max-width: 30%;

            .number {
                font-size: 9rem;
                font-weight: 700;
                font-style: normal;
            }

            .description {
                font-size: 2.4rem;
                line-height: 1.2;
            }
        }

        @media screen and (max-width: 990px) {
            column-gap: 2rem;

            .step {
                max-width: 25%;

                .number {
                    font-size: 6rem;
                }

                .description {
                    font-size: 1.8rem;
                }
            }

        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            row-gap: 3rem;

            .step {
                max-width: 100%;
            }
        }
    }


    .sub-hero-vote {
        margin-bottom: 4rem;
    }

    .rank--wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 99.4rem;
        padding: 6rem 0 10rem;
    }

    .rank--list {
        max-width: 60rem;
        padding: 7rem 1rem;
        margin: 0 auto;

        >.item {
            position: relative;
            padding-left: 7rem;
            color: #002d66;
            line-height: 1.2;
            margin-bottom: 3rem;

            .num {
                position: absolute;
                top: 0;
                left: 0;
            }

            .num,
            .osp {
                font-size: 3rem;
                font-weight: 700;
            }

            .adr {
                font-size: 2.2rem;
            }
        }

        @media screen and (max-width: 767px) {
            >.item {
                padding-left: 50px;
                margin-bottom: 25px;

                .num,
                .osp {
                    font-size: 20px;
                }

                .adr {
                    font-size: 16px;
                }
            }
        }
    }

    .search--input {
        position: relative;

        >.input {
            max-width: 100%;
            width: 100%;
            border: 2px solid #002d66;
            color: #002d66;
            border-radius: 1rem;
            padding: 1.5rem 6.5rem 1.5rem 1.5rem;
            font-size: 2.8rem;
            font-weight: 700;
            line-height: 1.1;
            background-color: #fff;

            &::placeholder {
                color: rgba(0, 44, 102, .3);
                text-transform: uppercase;
            }
        }

        .glass {
            @include button-reset;
            position: absolute;
            width: 34px;
            aspect-ratio: 1;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                width: 85%;
                height: 85%;
                top: 0;
                left: 0;
                border-radius: 100%;
                border: 3px solid #002d66;
            }

            &:after {
                content: '';
                position: absolute;
                width: 3px;
                background-color: #002d66;
                height: 40%;
                transform-origin: center bottom;
                transform: rotate(-45deg);
                bottom: 1.3px;
                right: -0.4px;
            }
        }
    }
}

.form--wrapper {
    background-color: #fff;
    color: #002d66;

    .paragraph {
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1.2rem;

        &.big {
            font-size: 3rem;
            line-height: 1.1;
            text-transform: none;
        }

        &.thin {
            font-weight: 500;
        }
    }

    .form--inner {
        margin-left: auto;
        margin-right: auto;
        max-width: 108rem;
        padding: 6rem 0 10rem;

        .form-spacer {
            height: 4rem;

            @media screen and (max-width: 767px) {
                height: 20px;
            }
        }

        .hint {
            font-style: italic;
            font-size: 1.8rem;
            margin-top: -1.9rem;
            line-height: 1;
        }

        .input {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1rem;
            margin-top: 0.5rem;

            label {
                font-size: 1.6rem;
                font-weight: 500;
            }

            input,
            textarea {
                max-width: 59rem;
                width: 100%;
                border: 2px solid #002d66;
                color: #002d66;
                border-radius: 1rem;
                padding: 1.5rem;
                font-size: 2.1rem;
                font-weight: 700;
                line-height: 1.1;
                background-color: #fff;
                resize: none;
            }
        }

        .checkbox--text {
            color: #002d66;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            margin-bottom: 0.8rem;
            cursor: pointer;

            a {
                color: #002d66;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: #003f92;
                }
            }
        }

        label.checkbox {
            color: #002d66;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            display: inline-block;
            display: grid;
            grid-template-columns: 2rem auto;
            gap: 1rem;
            margin-bottom: 0.8rem;
            cursor: pointer;

            a {
                color: #002d66;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: #003f92;
                }
            }

            input[type="checkbox"] {
                cursor: pointer;
                -webkit-appearance: none;
                appearance: none;
                background-color: #fff;
                margin: 0;
                font: inherit;
                color: currentColor;
                width: 16px;
                height: 16px;
                border: 1px solid currentColor;
                border-radius: 2px;
                transform: translate(3px, -2px);
                display: grid;
                place-content: center;

                &:before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    background-color: currentColor;
                }

                &:checked::before {
                    transform: scale(1);
                }
            }
        }

        .submit-group {
            text-align: center;
        }

        .message--image {
            >.img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }

    .unit--wrapper {
        display: flex;
        flex-wrap: wrap;

        >.selects {
            display: flex;
            flex-direction: column;
            flex: 1 0 55%;

            .select {
                margin-bottom: 1.2rem;
                display: block;
                max-width: 47.7rem;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    width: 2.5rem;
                    height: 1.8rem;
                    right: 7.4%;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #002d66;
                    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
                    pointer-events: none;
                }

                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 100%;
                    border: 2px solid #002d66;
                    color: #002d66;
                    border-radius: 1rem;
                    padding: 1.7rem 13%;
                    font-size: 2.3rem;
                    font-weight: 700;
                    text-align: center;
                    cursor: pointer;
                    line-height: 1.1;
                    background-color: #fff;


                    display: grid;

                    &:-ms-expand {
                        display: none;
                    }
                }
            }
        }

        .info {
            font-style: italic;
            max-width: 47.7rem;
            margin-top: 1.5rem;
            font-size: 17px;
            line-height: 1.2;

            &.-red {
                font-style: normal;
                color: #e21a2d;
                font-size: 18px;
                font-weight: 700;

                a {
                    color: #e21a2d;

                    &:hover {
                        text-decoration: underline;
                        color: #d74856;
                    }
                }
            }
        }

        .selected-unit {
            flex: 1 0 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.5rem;
            max-width: 59rem;

            >.txt {
                font-style: normal;
                text-align: center;
                font-weight: 700;
                font-size: 3rem;
                max-width: 46rem;

                span {
                    font-weight: 500;
                    font-size: 2.2rem;
                    margin-bottom: 2rem;
                    display: inline-block;

                }

                @media screen and (min-width: 767px) and (max-width: 990px) {
                    font-size: 2.2rem;

                    span {
                        font-size: 1.9rem;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            >.selects {
                flex: 1 0 80%;

                .select {
                    max-width: 59rem;
                }

                .info {
                    max-width: 59rem;
                }
            }

            .selected-unit>.txt {
                font-size: 2.6rem;

                span {
                    font-size: 2rem;
                }
            }
        }
    }
}

.support--counter {
    display: flex;
    max-width: 102rem;
    margin: 2rem auto 0;
    align-items: stretch;
    column-gap: 5.3%;

    >.group {
        display: flex;
        flex: 1 0;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 8.6%;
        row-gap: 3rem;

        >.digit {
            border-radius: 2rem;
            background: linear-gradient(to bottom, #3a497f -20%, #744381 50%, #db021d 150%);
            font-size: 12.6rem;
            line-height: .7;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0;
            aspect-ratio: 0.627;
        }

        >.caption {
            flex: 1 0 100%;
        }
    }

    @media screen and (max-width: 1366px) {
        >.group {
            >.digit {
                font-size: 10rem;
            }
        }
    }

    @media screen and (max-width: 990px) {
        >.group {
            >.digit {
                font-size: 8rem;
            }
        }
    }

    @media screen and (max-width: 599px) {
        flex-direction: column;
        max-width: 120px;
        row-gap: 2rem;

        >.group {
            row-gap: .5rem;

            >.digit {
                aspect-ratio: 0.8;
                border-radius: 1rem;
            }
        }
    }
}

.support {

    // hero
    &-hero {

        position: relative;
        padding: 70rem 0 10rem 0;
        background: url(/wp-content/themes/harnasxosp/img/support-bg.jpg) top center / cover no-repeat;

        // Media Queries
        @media screen and (max-width: 1680px) {
            padding: 60rem 0 10rem 0;
        }

        @media screen and (max-width: 1366px) {
            padding: 50rem 0 10rem 0;
        }

        @media screen and (max-width: 767px) {
            padding: 20rem 0 10rem 0;
        }

        &::before {
            position: absolute;
            content: "";
            top: -90px;
            left: 50%;

            width: 100%;
            height: 100%;

            background: url(/wp-content/themes/harnasxosp/src/images/fireman.png) top center no-repeat;
            transform: translateX(-50%);

            // Media Queries
            @media screen and (max-width: 1680px) {
                background-size: 1000px;
            }

            @media screen and (max-width: 1366px) {
                background-size: 800px;
            }

            @media screen and (max-width: 767px) {
                top: -40px;
                background-size: 450px;
            }
        }

        &.-register {
            background: none;
            padding: 9rem 0;

            &.-thanks {
                padding: 6rem 0;
            }

            &::before {
                display: none;
            }

            @media screen and (max-width: 767px) {
                &.-thanks {
                    padding: 1rem 0;
                }
            }

            @media screen and (max-width: 460px) {
                padding: 2rem 0 2rem;
            }
        }

        &.-vote {
            background: url(/wp-content/themes/harnasxosp/src/images/support-bg-vote.jpg) bottom center no-repeat;
            background-size: cover;
            padding-top: 10rem;
            padding-bottom: 8rem;
            min-height: calc(100vh - 12rem);

            &::before {
                display: none;
            }

            .hr {
                margin: 8rem 0;
                width: 100%;
                border: none;
                border-top: 3px solid #fff;
            }
        }
    }

    &-hero-container {
        position: relative;
        z-index: 5;
    }

    &-hero-title,
    &-hero-video {
        margin: 0 0 6rem 0;

        @media screen and (max-width: 767px) {
            margin: 0 0 3rem 0;
        }
    }

    &-hero-title,
    &-hero-subtitle,
    &-hero-text,
    &-hero-winner,
    &-hero-link {
        color: $support-color-text;
    }

    &-hero-title,
    &-hero-subtitle-container,
    &-hero-text,
    &-hero-winner,
    &-hero-btn-container,
    &-hero-rules {
        text-align: center;
    }

    &-hero-title {
        font-size: 9rem;
        text-align: center;
        line-height: normal;
        //color: $support-color-title;
        text-shadow: 0 0 20px $support-color-title-shadow;

        &.-sub {
            font-size: 4.5rem;
            text-transform: none;
        }

        // Media Queries
        @media screen and (max-width: 1366px) {
            font-size: 7rem;

            &.-sub {
                font-size: 4rem;
            }
        }

        @media screen and (max-width: 767px) {
            font-size: 4rem;

            &.-sub {
                font-size: 2.5rem;
            }
        }
    }

    &-hero-title-row {
        display: block;
    }

    &-hero-subtitle-container {
        margin: 0 0 4rem 0;

        @media screen and (max-width: 767px) {
            margin: 0 0 2.5rem 0;
        }
    }

    &-hero-subtitle {
        display: inline-block;
        padding: 5rem 10rem 0 10rem;
        border-top: 1px solid $support-color-border;
        font-family: $support-subtitle-font-family;
        font-size: 11.5rem;
        font-style: initial;

        // Media Queries
        @media screen and (max-width: 1366px) {
            font-size: 8rem;
        }

        @media screen and (max-width: 767px) {
            display: block;
            padding: 3rem 1.5rem 0 1.5rem;

            font-size: 6rem;
        }
    }

    &-hero-text {
        font-size: 4rem;
        line-height: 1.2;

        // Media Queries
        @media screen and (max-width: 1366px) {
            font-size: 3rem;
        }

        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }
    }

    &-hero-row {
        margin: 0 0 4rem 0;

        @media screen and (max-width: 767px) {
            margin: 0 0 3rem 0;
        }
    }

    &-hero-prize {
        display: block;
        height: 8rem;
        margin: 0 auto 2rem auto;
    }

    &-hero-winner {
        font-size: 2rem;
        text-transform: uppercase;
    }

    &-hero-winner-important {
        font-size: 2.5rem;
        font-weight: $font-weight-bold;
    }

    &-hero-btn-container {
        margin: 0 0 5rem 0;
    }

    &-hero-btn,
    &-hero-btn-text {
        display: inline-block;
    }

    &-hero-btn {
        padding: 1.5rem 4.5rem;
        border: 0;
        background: $support-btn-color-bg;
        color: $support-btn-color-text;
        font-size: 4rem;
        outline: 0;
        text-decoration: none;
        transform: skew(-20deg);

        transition: background .35s ease;

        &.is-disabled {
            pointer-events: none;
        }

        &:hover {
            background: darken($support-btn-color-bg, 5%);
        }

        // Media Queries
        @media screen and (max-width: 1366px) {
            font-size: 3rem;
        }

        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }
    }

    &-hero-btn-text {
        transform: skew(20deg);
    }

    &-hero-rules {
        margin: 8rem 0 0 0;
    }

    &-hero-link {
        display: inline-block;
        font-size: 2.6rem;

        // Media Queries
        @media screen and (max-width: 1366px) {
            font-size: 2.4rem;
        }

        @media screen and (max-width: 767px) {
            font-size: 2.2rem;
        }
    }

    &-hero-link-important {
        font-weight: $font-weight-bold;
        text-decoration: underline;
    }

    &-hero-img {
        display: block;
        width: 13rem;
        margin: 0 auto 1.5rem auto;
    }

    // q&a
    &-qa {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1199px) {
            flex-direction: column;
        }
    }

    &-qa-img {

        // Media Queries
        @media screen and (max-width: 1680px) {
            height: 1000px;
        }

        @media screen and (max-width: 1366px) {
            height: 800px;
        }

        @media screen and (max-width: 1199px) {
            height: 600px;
            margin: 0 0 2rem 0;
        }

        @media screen and (max-width: 767px) {
            height: 450px;
        }
    }

    &-qa-content {
        max-width: 85rem;
        margin: 0 0 0 18rem;

        // Media Queries
        @media screen and (max-width: 1366px) {
            margin: 0 0 0 10rem;
        }

        @media screen and (max-width: 1199px) {
            max-width: none;
            margin: 0 0 5rem 0;
        }
    }

    &-qa-item {

        &:not(:last-of-type) {
            margin: 0 0 5rem 0;

            // Media Queries
            @media screen and (max-width: 1199px) {
                margin: 0 0 5rem 0;
            }
        }
    }

    &-qa-question,
    &-qa-answer,
    &-qa-link,
    &-qa-read-more-text {
        color: $support-color-qa-text;
    }

    &-qa-question {

        position: relative;
        margin: 0 0 1rem 3.5rem;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 1;

        // Media Queries
        @media screen and (max-width: 1199px) {
            margin: 0 0 1rem 0;
        }

        &::before {

            position: absolute;
            top: 50%;
            left: -3.5rem;
            display: block;
            width: 1rem;
            height: 2rem;
            background: url(/wp-content/themes/harnasxosp/src/images/support-qa-arrow.svg) center center / contain no-repeat;
            content: "";
            transform: translateY(-50%);

            // Media Queries
            @media screen and (max-width: 1199px) {
                display: none;
            }
        }
    }

    &-qa-answer,
    &-qa-read-more,
    &-qa-read-more-text {
        font-size: 2.2rem;
        line-height: 1.36;
    }

    &-qa-answer {
        margin: 0 0 1.5rem 3.5rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            margin: 0 0 1.5rem 0;
        }
    }

    &-qa-read-more {

        position: relative;
        padding: 0;
        border: 0;

        margin: 0 0 0 5.5rem;
        background: 0;
        color: $support-color-qa-read-more;
        cursor: pointer;

        font-family: $font-primary;
        font-weight: $font-weight-bold;

        &::before {

            position: absolute;
            top: 50%;
            left: -2rem;
            display: block;
            width: 1rem;
            height: 1rem;
            background: url(/wp-content/themes/harnasxosp/src/images/support-qa-read-more-arrow.svg) center center / contain no-repeat;
            content: "";
            transform: translateY(-50%);
        }

        &.is-active {

            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        // Media Queries
        @media screen and (max-width: 1199px) {
            margin: 0 0 0 2rem;
        }
    }

    &-qa-link {

        &:hover {
            text-decoration: underline;
        }
    }

    &-qa-read-more-text {
        margin: 1.5rem 0 0 3.5rem;

        // Media Queries
        @media screen and (max-width: 1199px) {
            margin: 1.5rem 0 0 0;
        }
    }

    &-qa-important {
        font-weight: $font-weight-bold;
    }

    // border
    &-border {
        display: block;
        height: 2rem;
        background: linear-gradient(90deg, $support-color-border-1, $support-color-border-2);
    }

    // curiosities
    &-curiosities {
        padding: 8rem 0 10rem 0;
        background: linear-gradient(0deg, $support-color-curiosities-bg-1 0%, $support-color-curiosities-bg-2 50%, $support-color-curiosities-bg-1 100%);

        // Media Queries
        @media screen and (max-width: 767px) {
            padding: 8rem 0 15rem 0;
        }
    }

    &-curiosities-title {
        position: relative;
        margin: 0 0 10rem 4rem;
        color: $support-color-curiosities-title;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 1;

        &::before {

            position: absolute;
            top: 50%;
            left: -4rem;
            display: block;
            width: 2rem;
            height: 1rem;
            background: url(/wp-content/themes/harnasxosp/src/images/support-curiosities-arrow.svg) center center / contain no-repeat;
            content: "";
            transform: translateY(-50%);
        }
    }

    &-curiosities-content {
        position: relative;
        padding: 0 10rem;

        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }


    .redBox {
        background-color: #e21a2d;
        padding: 25px 60px
    }

    .redBox h1 {
        font-size: 10rem;
        color: #fff;
        font-style: normal !important;
        font-family: jubilat, serif;
        margin: 0
    }

    .blueBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        background-color: #002d66;
        padding: 17px 22px;
        margin-bottom: 30px
    }

    .blueBox p {
        font-size: 2.5rem;
        color: #fff;
        font-family: myriad-pro, sans-serif;
        font-weight: 700
    }

    .blueBox h1 {
        font-size: 6.8rem;
        color: #fff;
        font-style: normal !important;
        font-family: jubilat, serif;
        margin: 0
    }

    .flexRow {
        display: flex;
        align-items: center;
        justify-content: center
    }

    .redBtn {
        border-radius: 8px;
        background-color: #e21a2d
    }

    .outlineBtn,
    .redBtn {
        text-align: center;
        font-family: myriad-pro, sans-serif;
        font-weight: 700;
        margin: 60px 0 16px;
        color: #fff;
        padding: 15px 33px;
        line-height: 1.2
    }

    .outlineBtn {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 8px;
    }


    .support-hero.support-hero--edit {
        padding: 10rem 0
    }

    .support-hero-register {
        color: #fff;
        font-style: normal;
        text-align: center;
        font-size: 7.7rem;
        max-width: 140rem;
        display: block;
        margin: 0px auto 890px;

        span {
            font-size: 4.8rem;
            line-height: 1.1;
            display: inline-block;
        }

        &.-thanks {
            font-size: 11.3rem;
            color: #e21a2d;
            line-height: 0.9;
            text-shadow: rgba(17, 25, 47, .8) 1.2rem 1.2rem 0;
            margin: 0 auto 630px;
            // margin: 0px auto calc(14rem + 30vw);

            span {
                font-size: 7.6rem;
                color: #fff;
            }
        }

        &.-winners {
            margin: 61rem auto 2.8rem;
            text-shadow: rgba(17, 25, 47, .8) 0 0 15px;
            line-height: 1.3;
        }

        @media screen and (max-width: 1680px) {
            margin: 0px auto 940px;

            &.-thanks {
                margin: 0 auto 710px;
            }

            &.-winners {
                margin: 77rem auto 0;
            }
        }

        @media screen and (max-width: 1199px) {
            margin: 0px auto 64rem;

            &.-winners {
                margin: 53rem auto 0;
            }
        }

        @media screen and (max-width: 767px) {
            margin: 0 auto 42.5rem;
            font-size: 4.2rem;

            span {
                font-size: 3.2rem;
            }

            &.-thanks {
                margin: 0 auto 32.5rem;
                font-size: 5.4rem;
                line-height: 1.4;
                text-shadow: rgba(17, 25, 47, .8) 4px 4px 0;

                span {
                    font-size: 3.3rem;
                    line-height: 1.1;
                }
            }

            &.-winners {
                margin: 30rem auto 0;
            }
        }

        @media screen and (max-width: 460px) {
            // margin: 0px auto calc(12rem + 60vw);
            margin: 0 auto 32.8rem;
            font-size: 3.2rem;

            span {
                font-size: 2.2rem;
            }

            &.-winners {
                margin: 26rem auto 2.8rem;
            }
        }
    }

    .support-hero.support-hero--edit:before {
        display: none
    }

    .support-hero-title {
        font-style: normal;
        line-height: 1.3;

        .harnas-solo {
            position: relative;
            max-height: 14.2rem;
            top: 2.7rem;
        }

        &.-register {
            line-height: 1;
            font-size: 9rem;
            text-shadow: rgba(17, 25, 47, .8) 10px 10px 0;
            margin-bottom: 0;

            .harnas-solo {
                top: 0;
                max-height: 17.3rem;
            }
        }

        &.-red {
            color: #e21a2d;
        }

        // &.-sub {
        //     font-size: 4.8rem;
        // }

        @media screen and (max-width: 1680px) {
            .harnas-solo {
                top: 3.4rem;
            }
        }

        @media screen and (max-width: 1366px) {
            .harnas-solo {
                max-height: 11rem;
                top: 2.4rem;
            }
        }

        @media screen and (max-width: 767px) {
            .harnas-solo {
                max-height: 5.6rem;
                top: 1.4rem;
            }

            &.-register {
                font-size: 5.4rem;
                text-shadow: rgba(17, 25, 47, .8) 5px 5px 0;

                .harnas-solo {
                    max-height: 10rem;
                }
            }
        }
    }

    .support-hero-paragraph {
        text-align: center;
        color: #fff;
        font-size: 3rem;
        line-height: 1.3;
        max-width: 122rem;
        margin: 0 auto;

        &.-vote {
            font-size: 3rem;
            margin-bottom: 3rem;

            span {
                font-size: 3.6rem;
            }
        }

        @media screen and (max-width: 1366px) {
            font-size: 2.5rem;
        }

        @media screen and (max-width: 767px) {
            font-size: 2rem;
        }
    }

    .blueBoxVoting {
        width: 100%;
        max-width: 660px;
        padding: 18px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #002d66;
        margin-bottom: 2rem
    }

    .blueBoxVoting p {
        margin: 0;
        color: #fff;
        font-size: 4rem;
        text-transform: uppercase;
        font-family: jubilat, serif;
        font-weight: 700;
        line-height: 1.25;
        text-align: center
    }

    .redBoxVoting {
        width: 100%;
        max-width: 660px;
        padding: 18px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: #e21a2d;
        margin: 4rem 0
    }

    .redBoxVoting p {
        font-family: myriad-pro, sans-serif;
        color: #fff;
        text-align: center;
        font-weight: 700;
        line-height: 1.25;
        font-size: 3rem;
        margin-bottom: 35px
    }

    .redBoxVoting p span {
        font-size: 5rem
    }

    .spacer {
        display: block;
        width: 100%;
    }

    .spacer--100 {
        height: 10reml
    }


    &-curiosities-item {
        display: flex;
        align-items: center;

        margin: 2.5rem 0;

        // Media Queries
        @media screen and (max-width: 991px) {
            flex-direction: column;
            margin: 0;
        }

        &--is-reversed {
            flex-direction: row-reverse;

            // Media Queries
            @media screen and (max-width: 991px) {
                flex-direction: column;
                margin: 5rem 0 0 0;
            }

            .support-curiosities-avatar {
                margin: 0 0 0 -13.85rem;

                // Media Queries
                @media screen and (max-width: 991px) {
                    margin: 0 0 -10rem 0;
                }
            }

            .support-curiosities-box {
                padding: 0 calc(13.85rem + 7.5rem) 0 7.5rem;

                // Media Queries
                @media screen and (max-width: 991px) {
                    padding: 15rem 4rem 4rem 4rem;
                }

                &::after {
                    right: 17rem;
                    left: auto;

                    // Media Queries
                    @media screen and (max-width: 991px) {
                        right: auto;
                        left: 50%;
                    }
                }
            }

            .support-curiosities-text {
                text-align: right;

                // Media Queries
                @media screen and (max-width: 991px) {
                    text-align: center;
                }
            }
        }
    }

    &-curiosities-avatar,
    &-curiosities-avatar-inner {
        border-radius: 50%;
    }

    &-curiosities-avatar {
        position: relative;
        z-index: 5;
        width: 27.7rem;
        height: 27.7rem;
        flex-shrink: 0;
        padding: 8px;
        margin: 0 -13.85rem 0 0;
        background: linear-gradient(0deg, $support-color-curiosities-avatar-border-1, $support-color-curiosities-avatar-border-2);

        // Media Queries
        @media screen and (max-width: 991px) {
            width: 20rem;
            height: 20rem;
            margin: 0 0 -10rem 0;
        }
    }

    &-curiosities-avatar-inner {
        overflow: hidden;
        height: 100%;
        background-color: $support-color-curiosities-avatar;
    }

    &-curiosities-img {
        display: block;
        width: 100%;
        height: auto;
    }

    &-curiosities-box,
    &-curiosities-box-inner {
        display: flex;
        align-items: center;
    }

    &-curiosities-box {

        position: relative;
        width: 90rem;
        height: 24rem;
        padding: 0 7.5rem 0 calc(13.85rem + 7.5rem);
        border-radius: 2rem;
        background: linear-gradient(0deg, $support-color-curiosities-box-bg-1 0%, $support-color-curiosities-box-bg-2 50%);

        // Media Queries
        @media screen and (max-width: 991px) {
            width: 100%;
            height: auto;
            padding: 15rem 4rem 4rem 4rem;
        }

        &::after {

            position: absolute;
            top: 50%;
            left: 17rem;

            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background-color: $support-color-curiosities-dot;
            content: "";
            transform: translateY(-50%);

            // Media Queries
            @media screen and (max-width: 991px) {
                top: 12rem;
                left: 50%;
                margin: 0 0 0 -0.75rem;
                transform: none;
            }
        }
    }

    &-curiosities-box-inner {

        // Media Queries
        @media screen and (max-width: 991px) {
            height: 15rem;
            overflow-y: auto;
        }
    }

    &-curiosities-text {
        color: $support-color-curiosities-text;
        font-size: 2.2rem;
        font-style: italic;
        line-height: 1.36;

        // Media Queries
        @media screen and (max-width: 991px) {
            margin: auto;
            text-align: center;
        }
    }

    &-curiosities-slider-btn {

        position: absolute;
        top: 50%;

        display: block;
        width: 6rem;
        height: 6rem;
        padding: 0;
        border: 0;
        margin: -3rem 0 0 0;
        background: 0;
        color: transparent;
        cursor: pointer;
        font-size: 0;

        &::after {

            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;

            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: "";

            opacity: .4;
            transition: opacity .25s ease;
        }

        &:hover {

            &::after {
                opacity: 1;
            }
        }

        // Media Queries
        @media screen and (max-width: 767px) {
            top: auto;
            bottom: -10rem;
            margin: 0;
            transform: translateX(-50%);
        }
    }

    &-curiosities-slider-btn-next {
        right: -1.8rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            right: auto;
            left: 50%;
            margin: 0 0 0 50px;
        }

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-next.svg");
        }
    }

    &-curiosities-slider-btn-prev {
        left: -1.8rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            left: 50%;
            margin: 0 0 0 -50px;
        }

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-prev.svg");
        }
    }

    &-curiosities-source {
        display: block;
        margin: 2rem 0 0 0;
        color: $support-color-curiosities-source;

        font-size: 1.6rem;
    }

    // fire-department
    &-fire-department {
        padding: 15rem 0 15rem 0;
        background: linear-gradient(0deg, rgba(12, 40, 75, 1) 0%, rgba(7, 31, 63, 1) 50%);

        @media screen and (max-width: 767px) {
            padding: 10rem 0 10rem 0;
        }
    }

    &-fire-department-content {
        display: flex;
        align-items: center;
        //margin: 0 0 13.5rem 0;

        // Media Queries
        @media screen and (max-width: 1199px) {
            flex-direction: column-reverse;
            align-items: unset;
            //margin: 0 0 12rem 0;
        }

        /*
        @media screen and (max-width: 767px) {
            margin: 0 0 10rem 0;
        }
        */
    }

    &-fire-department-frame {
        width: calc(100% - 60rem);
        padding: 3px;

        aspect-ratio: 16/9;
        background: linear-gradient(0deg, $support-color-curiosities-avatar-border-1, $support-color-curiosities-avatar-border-2);

        //position: relative;

        // Media Queries
        @media screen and (max-width: 1366px) {
            width: calc(100% - 50rem);
        }

        @media screen and (max-width: 1199px) {
            width: auto;
            margin: 0 0 2rem 0;
        }
    }

    &-fire-department-frame-inner {
        overflow: hidden;
        background-color: $support-color-fire-department-frame-bg;

        //position: absolute;
        //left: 3px;
        //top: 3px;
        //right: 3px;
        //bottom: 3px;
    }

    &-fire-department-images {

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &-fire-department-slider {
        width: 60rem;

        // Media Queries
        @media screen and (max-width: 1366px) {
            width: 50rem;
        }

        @media screen and (max-width: 1199px) {
            width: auto;
        }
    }

    &-fire-department-slider-header {
        //display: flex;
        //align-items: center;
        padding: 0 2rem 2rem 0;
        border-bottom: 2px solid $support-color-fire-department-slider-border;
    }

    &-fire-department-slider-crests {

        img {
            display: block;
        }
    }

    &-fire-department-slider-caption,
    &-fire-department-slider-name {
        display: block;
        line-height: 1;
        text-transform: uppercase;
    }

    &-fire-department-slider-caption {
        margin: 0 0 2rem 0;
        color: $support-color-fire-department-slider-caption;
        font-size: 1.8rem;
    }

    &-fire-department-slider-name {
        color: $support-color-fire-department-slider-name;
        font-size: 3rem;
    }

    &-fire-department-slider-navigation {
        display: flex;
    }

    &-fire-department-slider-btn {

        position: relative;

        display: block;
        width: 10rem;
        height: 8rem;
        padding: 0;
        border: 0;
        background: 0;
        color: transparent;
        cursor: pointer;
        font-size: 0;

        &::after {

            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: 4rem;
            height: 4rem;
            margin: -2rem 0 0 -2rem;

            background-position: 50% 50%;
            background-repeat: no-repeat;
            content: "";

            opacity: .4;
            transition: opacity .25s ease;
        }

        &:hover {

            &::after {
                opacity: 1;
            }
        }
    }

    &-fire-department-slider-btn-next {
        border-left: 1px solid $support-color-fire-department-slider-border;

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-next.svg");
        }
    }

    &-fire-department-slider-btn-prev {
        border-right: 1px solid $support-color-fire-department-slider-border;

        &::after {
            background-image: url("/wp-content/themes/harnasxosp/src/images/support-btn-prev.svg");
        }
    }

    &-fire-department-slider-scrollable {
        height: 20rem;
        margin: 2rem 2rem 0 0;
        overflow-y: scroll;

        // Media Queries
        @media screen and (max-width: 1199px) {
            margin: 2rem 0 0 0;
        }
    }

    &-fire-department-slider-texts {
        padding: 0 2rem 0 0;
    }

    &-fire-department-slider-text,
    &-fire-department-slider-title {
        color: $support-color-fire-department-slider-text;
        font-size: 2.2rem;
        line-height: 1.36;
    }

    &-fire-department-slider-title {
        font-weight: $font-weight-bold;
    }

    &-fire-department-slider-text {

        &:not(:last-of-type) {
            margin: 0 0 2rem 0;
        }
    }

    &-fire-department-btn {
        text-align: center;
    }

    // overlay
    &-overlay {
        //height: 85rem;
        height: 60vh;

        // Media Queries
        @media screen and (max-width: 767px) {
            height: 300px;
            //background-attachment: unset;
        }
    }
}


// contest
.contest-container {
    color: white;
}

.contest-container a {
    color: white;
}

.contest-container .mobile {
    display: block;
}

.contest-container .desktop {
    display: none;
}

.contest-container .contest-logo {
    margin-bottom: 60px;
    text-align: center;
}

.contest-container .contest-logo img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.contest-container .contest-header {}

.contest-container .contest-intro {
    padding-bottom: 48px;
    margin-bottom: 40px;
    background: url("/wp-content/themes/harnasxosp/src/images/contest-arrow.svg") center bottom no-repeat transparent;
    background-size: 20px 10px;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
}

.contest-container .contest-intro strong {
    color: #dba952;
}

.contest-container .contest-more {
    margin-bottom: 100px;
    font-size: 22px;
    text-align: center;
}

.contest-container .contest-more strong {
    font-weight: 700;
    line-height: 1.2;
}

.contest-container .contest-columns {}

.contest-container .contest-column-1 {
    padding: 40px 0;
    text-align: center;
}

.contest-container .contest-column-1 h5 {
    font-size: 5rem;
}

.contest-container .contest-column-1 div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contest-container .contest-column-1 div span {
    width: 70px;
    border-radius: 50%;
    margin-right: 7px;
    background-color: white;
    color: #082548;
    font-size: 30px;
    font-weight: 700;
    line-height: 70px;
}

.contest-container .contest-column-1 div strong {
    color: #dba952;
    font-size: 80px;
    font-style: italic;
    font-weight: 700;
    line-height: 1;
}

.contest-container .contest-column-1 p {
    font-size: 2rem;
}

.contest-container .contest-column-2 {
    padding: 40px 0 80px;
    text-align: center;
}

.contest-container .contest-column-2 img {
    margin-bottom: 23px;
}

.contest-container .contest-column-2 p {
    font-size: 3rem;
}

.contest-container .contest-column-2 p a {
    font-weight: 700;
    text-decoration: underline;
}

.contest-container .contest-column-2>a {}

.contest-container .contest-column-2 .contest-box {
    position: relative;
    width: 100%;
    max-width: 864px;
    height: 0;
    max-height: 175px;
    padding-bottom: 20.25%;
    margin: 64px auto 0;
    background: url("/wp-content/themes/harnasxosp/src/images/contest-box.png") center center no-repeat transparent;
    background-size: contain;
}

.contest-container .contest-column-2 .contest-box p {
    position: absolute;
    top: 50%;
    right: 39%;
    left: 16.3%;
    display: block;
    font-size: 1rem;
    font-style: italic;
    font-weight: 700;
    text-align: left;
    transform: translateY(-50%);
    transform-origin: center;
}

.contest-container .contest-column-2 .contest-box p a {
    text-decoration: underline;
}

.contest-container .contest-column-2 .contest-box>a {
    position: absolute;
    top: 50%;
    left: 62%;
    display: block;
    padding: 1.5rem;
    background-color: white;
    color: #895520;
    transform: translateY(-50%);

    transform-origin: center;
}

@media (min-width: 460px) {

    .contest-container .contest-column-2 .contest-box p {
        font-size: 1.5rem;
    }

    .contest-container .contest-column-2 .contest-box>a {
        padding: 1.5rem 3rem;
    }
}

@media (min-width: 600px) {

    .contest-container .contest-column-2 .contest-box p {
        font-size: 1.75rem;
    }

    .contest-container .contest-column-2 .contest-box>a {
        padding: 2rem 4rem;
    }
}

@media (min-width: 900px) {

    .contest-container .contest-column-2 .contest-box p {
        font-size: 2.5rem;
    }

    .contest-container .contest-column-2 .contest-box>a {
        padding: 2.5rem 6rem;
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {

    .contest-container .mobile {
        display: none;
    }

    .contest-container .desktop {
        display: block;
    }

    .contest-container .contest-columns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 160px;
    }

    .contest-container .contest-column-1 {
        padding-right: 90px;
    }

    .contest-container .contest-column-2 {
        flex-grow: 1;
        padding-left: 90px;
        border-left: 3px solid #dba952;
    }

    .contest-container .contest-column-2 .contest-box p {
        font-size: 1.75rem;
    }

    .contest-container .contest-column-2 .contest-box>a {
        padding: 1.5rem 3rem;
        font-size: 3rem;
    }
}

@media (min-width: 1300px) {

    .contest-container .contest-column-2 .contest-box p {
        font-size: 2rem;
    }
}

@media (min-width: 1300px) {

    .contest-container .contest-column-2 .contest-box>a {
        padding: 2.5rem 6rem;
    }
}
