
@import "./variables";

// margin-auto

@mixin margin-auto {
    margin: {
        right: auto;
        left: auto;
    }
}

// clearfix

@mixin clearfix {

    &::after {
        display: table;
        clear: both;
        content: "";
    }
}

// list reset

@mixin list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

// absolute centering

@mixin center($position) {
    position: absolute;

    @if $position == "vertical" {
        top: 50%;
        transform: translateY(-50%);
    } @else if $position == "horizontal" {
        left: 50%;
        transform: translate(-50%);
    } @else if $position == "both" {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// gradient

@mixin background-gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == "vertical" {
        background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == "horizontal" {
        background: linear-gradient(to right, $start-color, $end-color);
    } @else {
        background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}

// external fonts

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {

    @font-face {
        font-family: quote($font-name);
        font-style: $style;
        font-weight: $weight;
        src:
            url($file-name + '.woff') format("woff"),
            url($file-name + '.woff2') format("woff2");
    }
}

// font size

@mixin font-size($size) {
    font-size: $size; // fallback for old browsers
    font-size: ($size / $font-size) * 1rem;
}

// breakpoints

$breakpoints: (
    "small"      : 576px,
    "medium"     : 768px,
    "large"      : 992px,
    "extra-large": 1200px
);

@mixin screen($width, $type: min) {

    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type == max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
