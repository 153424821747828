// gate.scss

@import "../utilities/variables";

.gate {
    padding: 40rem 0 0 0;
    background: url(/wp-content/themes/harnasxosp/src/images/gate-visual.jpg) top center / 800px no-repeat $gate-color-bg;

    // Media Queries
    @media screen and (min-width: 768px) {
        padding: 60rem 0 0 0;
        background-size: 1200px;
    }

    @media screen and (min-width: 992px) {
        background-size: cover;
    }

    @media screen and (min-width: 1024px) and (min-height: 680px) {
        height: 100vh;
        padding: 0;
    }

    &-visual {
        height: 60rem;
        background-image: url("/wp-content/themes/harnasxosp/src/images/gate-visual.jpg");
        background-position: 50% 0;
        background-size: cover;

        // Media Queries
        @media screen and (max-width: 767px) {
            height: 30rem;
        }

        @media screen and (max-width: 360px) {
            height: 25rem;
        }
    }

    &-content {
        background-color: $gate-color-content;

        // Media Queries
        @media screen and (min-width: 1024px) and (min-height: 680px) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    &-container {
        position: relative;
        padding: 12rem 10rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            padding: 8rem 15px 4rem 15px;
        }
    }

    &-row {
        display: flex;
        justify-content: space-between;

        margin: 0 -2rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            flex-direction: column;
            margin: 0;
        }
    }

    &-col {
        max-width: 50%;
        padding: 0 2rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            max-width: 100%;
            padding: 0;
        }
    }

    &-col-1 {
        // Media Queries
        @media screen and (max-width: 991px) {
            margin: 0 0 3rem 0;
        }
    }

    &-question,
    &-answer {
        font-style: italic;
        font-weight: $font-weight-bold;
        line-height: 1;
        text-transform: uppercase;
    }

    &-question {
        margin: 0 0 4rem 0;
        font-size: 3rem;

        // Media Queries
        @media screen and (max-width: 320px) {
            font-size: 2.4rem;
        }
    }

    &-answer {

        display: block;
        padding: 0 1rem 0 0;
        border: 0;
        // reset
        background: 0;
        color: $gate-color-answer;
        cursor: pointer;

        font-size: 9rem;
        outline: 0;

        // Media Queries
        @media screen and (max-width: 320px) {
            font-size: 7rem;
        }

        &:not(:last-of-type) {
            position: relative;
            margin: 0 4rem 0 0;

            &::after {

                position: absolute;
                top: 50%;
                right: -2.2rem;

                display: block;
                width: .2rem;
                height: 7rem;
                margin: -3.5rem 0 0 0;
                background-color: $gate-color-answer-border;
                content: "";

                transform: rotate(12deg);

                @media screen and (max-width: 320px) {
                    right: -2rem;
                    height: 5rem;
                    margin: -2.5rem 0 0 0;
                }
            }
        }
    }

    &-answer-container {
        display: flex;
        margin: 0 0 0 -1rem;
    }

    &-title {
        margin: 0 0 3rem 0;
        font-size: 2.4rem;
        line-height: 1.66667;

        @media screen and (max-width: 320px) {
            font-size: 2rem;
        }
    }

    &-description {

        padding: 0 0 0 4rem;
        border-left: 2px solid $gate-color-description-border;
        font-size: 1.8rem;
        line-height: 1.66667;
    }

    .logo {

        position: absolute;
        top: -5.5rem;
        left: 10rem;
        width: 31.5rem;
        height: 12.2rem;

        // Media Queries
        @media screen and (max-width: 767px) {
            top: -3.65rem;
            left: 15px;
            width: 19rem;
            height: 7.3rem;
        }
    }
}

body {
    background-color: $gate-color-bg;
}
