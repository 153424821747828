
// contact.scss

@import "../utilities/variables";

.contact {
    padding: 10rem 0;
    background: linear-gradient(90deg, $contact-color-bg-1 0%, $contact-color-bg-2 50%);

    &-item {

        &:not(:last-of-type) {
            margin: 0 0 2.5rem 0;
        }
    }

    &-text,
    &-link {
        color: $contact-color-text;
    }

    &-text {
        font-size: 2.6rem;
        line-height: 1.15;
        text-align: center;

        &--important {
            font-weight: $font-weight-bold;
        }
    }

    &-row {
        display: block;
    }
}
