@import "../utilities/variables";

// support.scss

.vote--section {
    padding: 3.5rem 0 6rem 0;
    background-image: url(/wp-content/themes/harnasxosp/img/support-bg.jpg);
    background-size: cover;
    background-position: center;

    .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $white;

        > .heading {
            text-align: center;
            font-size: 3.6rem;
            font-style: normal;
            padding: 1rem;
            background-color: rgba($blue, 0.8);
            display: inline-block;
            margin: 0 auto 2rem auto;
            color: $white;
            text-transform: uppercase;
            line-height: 1;

            @media screen and (min-width: 768px) {
                font-size: 8.4rem;
                padding: 2.3rem;
                margin: 0 auto 2.5rem auto;
            }
        }

        > .info {
            text-align: center;
            margin: 0 auto 4.6rem auto;
            font-size: 2.2rem;
            width: 100%;
            max-width: 80rem;

            > .text {
                margin: 0;
                font-size: 1.8rem;

                &.-bg {
                    width: fit-content;
                    margin: 4rem auto 0 auto;
                    padding: 0.5rem 2rem;
                    background: $white;
                    text-transform: uppercase;
                    color: $blue;
                    font-weight: $font-weight-bold;
                }
            }
        }

        > .steps {
            display: grid;
            //grid-template-columns: 1fr 1fr;
            grid-template-columns: repeat(3, 1fr);
            gap: 5rem;
            width: 100%;
            max-width: 100%;
            margin: 0 auto 3rem auto;

            > .step {
                display: flex;
                flex-direction: column;
                align-items: center;

                @media screen and (min-width: 992px) {
                    &:first-of-type {
                        > .number {
                            position: relative;

                            &::after {
                                position: absolute;
                                left: 100%;
                                top: 50%;
                                width: 120%;
                                content: '';
                                background: $white;
                                height: 1px;
                            }
                        }
                    }

                    &:last-of-type {
                        > .number {
                            position: relative;

                            &::after {
                                position: absolute;
                                right: 100%;
                                top: 50%;
                                width: 120%;
                                content: '';
                                background: $white;
                                height: 1px;
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        > .number {
                            position: relative;

                            &::after {
                                position: absolute;
                                left: 100%;
                                top: 50%;
                                width: 120%;
                                content: '';
                                background: $white;
                                height: 1px;
                            }

                            &::before {
                                position: absolute;
                                right: 100%;
                                top: 50%;
                                width: 120%;
                                content: '';
                                background: $white;
                                height: 1px;
                            }
                        }
                    }
                }

                //@media screen and (min-width: 1300px) {
                //    &:first-of-type, &:last-of-type, &:nth-of-type(2) {
                //        > .number {
                //            &:after {
                //                width:140%;
                //            }
                //            &:before{
                //                width:140%;
                //            }
                //        }
                //    }
                //}

                > .number {
                    text-transform: uppercase;
                    padding: 0.5rem 1rem;
                    border: 1px solid $white;
                    font-weight: $font-weight-bold;
                    font-size: 3rem;
                    margin-bottom: 2rem;
                }

                > .text {
                    font-weight: $font-weight-bold;
                    text-align: center;
                    font-size: 2.2rem;
                    margin: 0;

                    > .small {
                        display: block;
                        font-size: 1.3rem;
                    }
                }
            }

            @media screen and (min-width: 1300px) {
                gap: 0rem;

                > .step {
                    &:first-of-type, &:last-of-type, &:nth-of-type(2) {
                        > .number {
                            &::after, &::before{
                                width:140%;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 992px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .checkbox--list--wrapper {
        margin-top: 2rem;

        .checkbox--wrapper, a {
            color: white;
            font-size: 12px;
        }

        a {
            font-weight: bold;
        }
    }
}

.select--unit {
    position: relative;
    color: $white;
    z-index: 1;

    &:before {
        position: absolute;
        left: 50%;
        top: 0;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        content: '';
        background: $blue;
        z-index: 0;
    }

    > .grid {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 6rem;

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;

            > .select {
                width: 100%;
            }
        }

        > .select {
            > .paragraph {
                margin: 0 0 2.5rem 0;
                text-transform: uppercase;
                font-size: 2.2rem;
                font-weight: $font-weight-bold;
            }

            > select {
                font-size: 2.5rem;
                display: block;
                width: 100%;
                height: 6.5rem;
                text-align: center;
                color: $blue;
                font-weight: $font-weight-bold;
                border: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                text-overflow: '';
                background-image: url(/wp-content/themes/harnasxosp/img/triangle.svg);
                background-repeat: no-repeat;
                background-position: calc(100% - 1.5rem) center;
                padding-right: 5rem;

                &:not(:last-of-type) {
                    margin-bottom: 1rem;
                }
            }
        }

        > .selected {
            position: relative;

            > .description {
                display: grid;
                grid-template-columns: 7.5rem 1fr;
                background-color: rgba($blue, 0.8);
                gap: 2rem;
                position: absolute;
                width: 100%;
                height: auto;
                bottom: 0;
                left: 0;
                padding: 1.5rem 2rem;
                box-sizing: border-box;

                > img {
                    width: 100%;
                    height: auto;
                    background: #fff;
                }

                @media screen and (min-width: 768px) {
                    padding: 2rem 2.5rem;
                }

                > .details {

                    > .name {
                        margin: 0 0 .8rem 0;
                        font-weight: $font-weight-bold;
                        font-size: 2rem;

                        @media screen and (min-width: 768px) {
                            font-size: 2.3rem;
                        }
                    }

                    > .address {
                        margin: 0;
                        font-size: 1.3rem;
                    }
                }
            }

            > .picture {
                display: flex;

                > img {
                    width: 100%;
                    height: auto;
                    max-height: 400px;
                }
            }
        }
    }
}

.vote--button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8rem 0 4rem 0;

    > .paragraph {
        color: $white;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }

    > button {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: 4rem;
        padding: 2.5rem 5rem;
        border-radius: 0.8rem;
        background-color: $red-5;
        border: none;
        margin: 0 0 3rem 0;

        &:hover {
            cursor: pointer;
        }

        @media screen and (min-width: 768px) {
            padding: 2.5rem 10rem;
            font-size: 6.2rem;
        }
    }
}


@media screen and (max-width: 767px) {
    #applicationRegisterOspFrom {
        display: block;
    }

    .block-application-form {
        .h-container {
            padding: 0 0rem;
        }

        .block-application-form__grid {
            display: block;
        }
    }

}
