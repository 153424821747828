@import "../utilities/variables";

.rank--section {
    min-height:100vh;
    padding: 3.5rem 0 6rem 0;
    background-image: url(/wp-content/themes/harnasxosp/img/support-bg.jpg);
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #052645;

    @media screen and (max-width: 630px) {
        background-size: 200% auto;
    }

    @media screen and (min-width: 768px) {
        padding: 3.5rem 0 14rem 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $white;

        > .heading {
            text-align: center;
            font-size: 3.6rem;
            font-style: normal;
            padding: 1rem;
            background-color: rgba($blue, 0.8);
            display: inline-block;
            margin: 0 auto 2rem auto;
            color: $white;
            text-transform: uppercase;
            line-height: 1;

            @media screen and (min-width: 768px) {
                font-size: 8.4rem;
                padding: 2.3rem;
                margin: 0 auto 2.5rem auto;
            }
        }

        > .subheading {
            text-transform: uppercase;
            font-size: 2.4rem;
            margin: 0 0 1.5rem 0;
            text-align: center;

            @media screen and (min-width: 768px) {
                font-size: 3rem;
            }
        }

        > .paragraph {
            font-size: 1.8rem;
            margin: 0 auto;
            text-align: center;
            max-width: 85rem;
            width: 100%;

            @media screen and (min-width: 768px) {
                font-size: 2.2rem;
            }

            &:last-of-type {
                margin: 0 auto 3rem auto;
            }
        }

        > .label {
            font-size: 2.2rem;
            display: inline-block;
            margin: 0 0 2rem 0;
            text-align: center;

            @media screen and (min-width: 768px) {
                font-size: 3rem;
            }
        }

        > .input {
            border: none;
            background-color: $white;
            display: inline-block;
            margin: 0 auto 2.5rem auto;
            line-height: 4rem;
            padding: 0 4.7rem 0 1.5rem;
            max-width: 63rem;
            width: 100%;
            font-size: 1.8rem;
            box-sizing: border-box;
            background-image: url(/wp-content/themes/harnasxosp/img/search.svg);
            background-size: 3.2rem auto;
            background-position: calc(100% - 15px) center;
            background-repeat: no-repeat;

            @media screen and (min-width: 768px) {
                font-size: 2.2rem;
                line-height: 5rem;
            }
        }

        > .winner {
            display: flex;
            margin-bottom: 2.5rem;

            > img {
                width: 100%;
                height: auto;
            }
        }

        > .list {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;

            @media screen and (min-width: 768px) {
                gap: 3.5rem;
            }


            > .block {
                display: grid;
                grid-template-columns: 1fr;

                @media screen and (min-width: 768px) {
                    grid-template-columns: 6rem 1fr;
                    grid-template-rows: auto auto;
                }

                > .position {
                    font-weight: $font-weight-bold;
                    font-size: 2.4rem;
                    text-align: center;

                    @media screen and (min-width: 768px) {
                        grid-column: 1/2;
                        grid-row: 1/3;
                        text-align: left;
                        font-size: 3rem;
                    }
                }

                > .name {
                    font-weight: $font-weight-bold;
                    font-size: 2.4rem;
                    margin: 0;
                    text-align: center;

                    @media screen and (min-width: 768px) {
                        text-align: left;
                        font-size: 3rem;
                    }
                }

                > .address {
                    font-size: 1.8rem;
                    margin: 0;
                    text-align: center;

                    @media screen and (min-width: 768px) {
                        text-align: left;
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}
