
/*
 * Colors
 */

$white     : #fff;
$black     : #000;
$dark      : #464646;
$dark-2    : #111;
$blue      : #113456;
$blue-2    : #08111d;
$blue-3    : #061c3d;
$blue-4    : #0a2c50;
$blue-5    : #03142c;
$blue-6    : #002d66;
$gold      : #c29755;
$gold-2    : #ab7e38;
$gold-3    : #824d1c;
$gold-4    : #b2873d;
$gold-5    : #a1772d;
$gold-6    : #ab7c2b;
$gray      : #898989;
$gray-2    : #ccc;
$gray-3    : #fafafa;
$gray-4    : #e1e4e6;
$gray-5    : #f4f5f5;
$gray-6    : #595959;
$gray-7    : #c3c9cf;
$red       : #c40f28;
$red-2     : #dc1229;
$red-3     : #7f171c;
$red-4     : #9f242a;
$red-5     : #E21A2D;

/*
 * Fonts
 */

// font directory
$font-directory: "/wp-content/themes/harnasxosp/src/fonts";

// font family
$font-stack: arial, sans-serif;
$font-primary: "Source Sans 3", $font-stack;
$font-secondary: "Artegra Slab", $font-stack;

// font weight
$font-weight-thin:       100;
$font-weight-extralight: 200;
$font-weight-light:      300;
$font-weight-regular:    400;
$font-weight-medium:     500;
$font-weight-semibold:   600;
$font-weight-bold:       700;
$font-weight-extrabold:  800;
$font-weight-black:      900;

/*
 * General
 */

$color-text: $dark;
$color-bg: $white;

/*
 * Components
 */

// navbar
$navbar-bg: $gray-3;

// navigation
$navigation-link: $blue;
$navigation-bg: $white;

// hamburger
$hamburger-bg: $black;

// slider
$slider-caption: $gray;
$slider-product-name: $dark;
$slider-border: $gray;

// border
$color-border: $gray-2;

// btn
$btn-color-text: $white;
$btn-color-bg: $red;
$btn-color-bg-hover: $red-2;

// footer
$footer-bg: $red;
$footer-color: $white;
$footer-nav-dot: $white;

// darkmode
$darkmode-color-1: $dark-2;
$darkmode-color-2: $gray-6;
$darkmode-color-3: $white;

// promo
$promo-video-top-mask: $white;
$promo-video-bottom-mask: rgba($dark-2, .85);
$promo-visual-2-title: $white;
$promo-visual-2-text: rgba($white, .8);
$promo-visual-3-title: $dark;
$promo-visual-3-text: $dark;
$promo-visual-3-content-bottom: $white;
$promo-rules-list-title: $red;
$promo-rules-btn-color: $red;
$promo-rules-link-color: $gray-7;

$promo-rules-title-color: $blue;

$promo-headline-title-color: $red-2;
$promo-headline-text-color: $white;

$promo-hero-text-color: $white;
$promo-note-bg-color: $black;
$promo-note-text-color: $white;

$promo-visual-text-color: $white;
$promo-visual-bullet-color: $white;

$promo-old-rules-color: $red;
$promo-old-rules-bg-color: $white;

// products
$products-name: $gold;
$products-title: $gold;
$products-detail: $gold;
$products-description: $gray;

// movie
$movie-color-border: rgba($white, .3);
$movie-color-title: $white;
$movie-color-description: rgba($white, .8);

// gate
$gate-color-bg: $white;
$gate-color-content: $white;
$gate-color-question: $white;
$gate-color-answer: $gold-2;
$gate-color-answer-border: $gold-2;
$gate-color-title: $white;
$gate-color-description: $white;
$gate-color-description-border: $gold-2;

// Q&A
$qa-color-bg: $gray-5;
$qa-color-title: $blue;
$qa-color-subtitle: $red;
$qa-color-text: $blue;
$qa-color-link: $blue;
$qa-color-panel: rgba($white, .85);
$qa-color-item-active: rgba($gray-4, .5);
$qa-color-question-active: $red;

// contact
$contact-color-bg-1: $blue-3;
$contact-color-bg-2: $blue-4;
$contact-color-text: $white;

// support
$support-color-title: $white;
$support-color-title-shadow: $black;

$support-color-qa-text: $white;
$support-color-qa-read-more: $red;

$support-color-gradient: $blue-5;

$support-color-border-1: $gold-3;
$support-color-border-2: $gold-4;

$support-color-curiosities-title: $white;
$support-color-curiosities-bg-1: $red-3;
$support-color-curiosities-bg-2: $red-4;
$support-color-curiosities-avatar: $blue-3;
$support-color-curiosities-avatar-border-1: $gold-3;
$support-color-curiosities-avatar-border-2: $gold-4;
$support-color-curiosities-box-bg-1: $blue-3;
$support-color-curiosities-box-bg-2: $blue-4;
$support-color-curiosities-text: $white;
$support-color-curiosities-dot: $white;
$support-color-curiosities-source: $red;

$support-color-fire-department-frame-bg: $black;
$support-color-fire-department-slider-caption: $gray;
$support-color-fire-department-slider-name: $white;
$support-color-fire-department-slider-border: rgba($white, .4);
$support-color-fire-department-slider-text: $white;

$support-subtitle-font-family: $font-secondary;
$support-color-text: $white;
$support-color-border: $gold-5;
$support-btn-color-bg: $gold-6;
$support-btn-color-text: $white;

