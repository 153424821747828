/*
 * General
 */

@import "../utilities/variables";

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body {
    overflow-x: hidden;
}

body {
    background-color: $color-bg;
    image-rendering: -webkit-optimize-contrast;
}

.text-center {
    text-align: center;
}

/*
 * Global classes
 */

.is-visually-hidden {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
    clip-path: polygon(0 0, 0 0, 0 0);
    white-space: nowrap;
}

.is-hidden {
    display: none;
}
