// lodoherbata.scss

@import "../utilities/variables";

.energetyk {
    padding-top: 85px;
    padding-bottom: 200px;
    background-color: #00a0e4;

    .container {
        position: relative;
    }

    &__hero-content {

        * {
            color: transparent;
        }
        position: relative;
        z-index: 9;
        min-height: 570px;
        background: url(/wp-content/themes/harnasxosp/src/images/energetyk_hasla.png) 0 0 / contain no-repeat;

        @media all and (max-width: 1280px) {
            min-height: 370px;
        }

        @media all and (max-width: 800px) {
            background-position: center;
        }
    }

    &__packshot {
        position: absolute;
        top: 0;
        right: 12%;

        @media all and (max-width: 800px) {
            position: relative;
            right: initial;
            display: block;
            width: 100%;
            margin: 0 auto;
            margin-top: 80px;
        }

        .positioning {
            position: relative;

            @media all and (max-width: 800px) {
                display: flex;
                justify-content: center;
            }
        }

        img.main {
            position: relative;
            z-index: 9;
            height: 640px;

            @media all and (max-width: 1280px) {
                height: 440px;

            }

            @media all and (max-width: 425px) {
                height: 320px;

            }
        }

        .highlight {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            height: 1000px;
            transform: translate(-50%, -50%);
        }

        .large-lighting-gold {
            position: absolute;
            z-index: 2;
            top: 60%;
            left: 50%;
            height: 1200px;
            animation: blinker-stable 5s linear infinite;
            animation-delay: 1s;
            transform: translate(-50%, -50%);

            @media all and (max-width: 1250px) {
                height: 100vw;
            }

            @media all and (max-width: 800px) {
                height: 100vw;
            }

        }

        .large-lighting {
            position: absolute;
            z-index: 1;
            top: 85%;
            left: -70%;
            width: 110vw;
            animation: blinker 5s linear infinite;
            animation-delay: 2s;
            transform: translate(-50%, -50%);

            @media all and (max-width: 1480px) {
                left: -44%;
            }

            @media all and (max-width: 1250px) {
                top: 65%;
                left: -24%;
            }

            @media all and (max-width: 800px) {
                left: 0;
                width: 260vw;
            }
        }

        .small-lighting {
            position: absolute;
            z-index: 1;
            top: 85%;
            left: -70%;
            width: 80vw;
            animation: blinker 5s linear infinite;
            animation-delay: 2s;
            transform: translate(-50%, -50%);
        }
    }

    &__bottom {
        position: relative;
        z-index: 2;
        width: 100vw;
        background-color: #00a0e4;

        &::after {
            position: absolute;
            top: -300px;
            left: 0;
            display: block;
            width: 100vw;
            height: calc(100% + 300px);
            background: url(/wp-content/themes/harnasxosp/src/images/energetyk_tlo.png) 0 0 / cover no-repeat;
            content: "";

            @media all and (max-width: 990px) {
                top: -450px;
                height: calc(100% + 450px);
            }

            @media all and (max-width: 630px) {
                top: -50vw;
                height: calc(100% + 260vw);
                background: url(/wp-content/themes/harnasxosp/src/images/energetyk_tlo_mob.png) 0 0 / cover no-repeat;
                background-position: top right;
            }
        }
    }

    &__bottom {
        padding-bottom: 100px;

        .container {
            position: relative;
            z-index: 12;
            display: grid;
            align-items: center;
            gap: 30px;
            grid-template-columns: 1fr 1fr;

            @media all and (max-width: 990px) {
                grid-template-columns: 1fr;
            }
        }

    }

    &__bottom-content {
        position: relative;
        z-index: 12;
        padding-right: 40%;


        @media all and (max-width: 990px) {
            order: -1;
        }

        @media all and (max-width: 1280px) {
            padding-right: 0;
        }

        h2 {
            min-height: 120px;
            margin-bottom: 70px;
            background: url(/wp-content/themes/harnasxosp/src/images/POCZUJ_ENERGIE_HARNASIA.png) 0 0 / contain no-repeat;
            color: transparent;
        }

        p {
            color: white;
            font-size: 22px;
            line-height: 1.5;
        }

        ul {
            margin-top: 60px;

            li {
                padding-right: 12px;
                padding-left: 12px;
                margin-bottom: 20px;
                color: white;
                font-size: 24px;
                font-weight: 700;
                line-height: 1.5;


                span {
                    display: inline;
                    background-color: #db021d;
                    box-decoration-break: clone;
                    box-shadow: 1em 0 0 #db021d, -1em 0 0 #db021d;
                }

                &:nth-child(even) {

                    span {
                        display: inline;
                        background-color: #00a2e5;
                        box-decoration-break: clone;
                        box-shadow: 1em 0 0 #00a2e5, -1em 0 0 #00a2e5;
                    }


                }
            }
        }
    }

    &__bottom-packshot {
        position: relative;
        z-index: 6;
        display: grid;
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;

        @media all and (max-width: 500px) {
            gap: 60px;
            grid-template-columns: 1fr;
        }

        img {
            height: 330px;
            margin: 0 auto;
        }

        .button-like {
            display: inline-block;
            width: max-content;
            height: max-content;
            justify-content: center;
            padding: 20px 14px;
            border-radius: 39px;
            margin: 0 auto;
            margin-top: 80px;
            background-color: #e9cc8b;
            color: #002d66;
            font-size: 26px;
            font-weight: 700;
            grid-column: 1 / span 2;
            line-height: 1.2;

            @media all and (max-width: 500px) {
                max-width: 90vw;
                margin-top: 0;
                grid-column: 1;
                text-align: center;
                font-size: 24px;

            }
        }
    }
}


@keyframes blinker-stable {

    20% {
        opacity: .4;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    90% {
        opacity: 0;
    }
}

@keyframes blinker {

    20% {
        opacity: .4;
    }

    40% {
        opacity: .25;
    }

    60% {
        opacity: 1;
    }

    90% {
        opacity: 0;
    }
}

.lodoherbata {
    position: relative;
    z-index: 1;
    background: linear-gradient(0deg, rgba(240, 146, 69, 1) 0%, rgba(237, 103, 45, 1) 100%);

    &-hero {
        position: relative;
        margin: 0 0 -10rem 0;

        &::before {
            position: absolute;
            top: 0;
            left: 50%;
            width: 90rem;
            height: 86rem;
            margin-left: -90rem;
            background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-tin.png) 0 0 / contain no-repeat;
            content: "";
        }

        @media screen and (max-width: 1024px) {
            margin: 0;

            &::before {
                display: none;
            }
        }
    }

    &-hero-content,
    &-hero-copy {
        display: flex;
    }

    &-hero-content {
        justify-content: flex-end;
        padding: 7.5rem 20rem 0 0;

        @media screen and (max-width: 1680px) {
            padding: 7.5rem 15rem 0 0;
        }

        @media screen and (max-width: 1366px) {
            padding: 7.5rem 7.5rem 0 0;
        }

        @media screen and (max-width: 1024px) {
            justify-content: center;
            padding: 6rem 0 0 0;
        }
    }

    &-hero-copy {
        width: 62rem;
        flex-direction: column;
        align-items: center;
    }

    &-hero-label,
    &-hero-title,
    &-hero-flavor {
        margin: 0 0 3.5rem 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: transparent;
        font-size: 0;
    }

    &-hero-label {
        width: 39.3rem;
        height: 9.5rem;
        background-image: url(/wp-content/themes/harnasxosp/src/images/nowosc.svg);

        @media screen and (max-width: 991px) {
            width: 30rem;
            height: 7.5rem;
        }
    }

    &-hero-title {
        width: 53.2rem;
        height: 7rem;
        background-image: url(/wp-content/themes/harnasxosp/src/images/lodoherbata.svg);

        @media screen and (max-width: 991px) {
            width: 37rem;
            height: 5.5rem;
        }
    }

    &-hero-flavor {
        width: 39.2rem;
        height: 19.8rem;
        background-image: url(/wp-content/themes/harnasxosp/src/images/brzoskwinia.png);

        @media screen and (max-width: 991px) {
            width: 30rem;
            height: 16rem;
        }
    }

    &-hero-subtitle,
    &-hero-text,
    &-hero-alcohol {
        color: #133b6d;
    }

    &-hero-subtitle {
        margin: 0 0 2rem 0;
        font-size: 3rem;
    }

    &-hero-text {
        margin: 0 0 3rem 0;
        font-size: 2.2rem;
        line-height: 1.36;
    }

    &-hero-alcohol {
        font-size: 2rem;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: uppercase;

        span {
            display: block;
            margin: -2rem 0 -2rem 0;
            font-size: 4rem;
        }
    }

    &-video-top {
        padding: 20rem 0 0 0;
        background: linear-gradient(0deg, rgba(231, 109, 54, 1) 50%, rgba(231, 109, 54, 0) 80%), url(/wp-content/themes/harnasxosp/src/images/lodoherbata-bg.png) center top / cover no-repeat;
    }

    &-video-bottom {
        background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-harnas.png) calc(50% + 55rem) bottom / 62.5rem 112.5rem no-repeat, linear-gradient(180deg, rgba(231, 109, 54, 1) 0%, rgba(23, 44, 94, 1) 15%);

        @media screen and (max-width: 991px) {
            background: none, linear-gradient(180deg, rgba(231, 109, 54, 1) 0%, rgba(23, 44, 94, 1) 15%);
        }
    }

    &-caption {
        max-width: 95rem;
        padding: 6rem 6rem 0 6rem;

        @media screen and (max-width: 991px) {
            max-width: none;
            padding: 6rem 3.5rem 0 0;
        }
    }

    &-caption-inner {
        padding: 0 0 0 3.5rem;
    }

    &-caption-title,
    &-caption-text {
        color: #fff;
    }

    &-caption-title {
        position: relative;
        margin: 0 0 2rem 0;
        font-size: 3rem;

        &::before {

            position: absolute;
            top: .2rem;
            left: -3.5rem;

            display: block;
            width: 2rem;
            height: 2rem;
            background-image: url("/wp-content/themes/harnasxosp/src/images/caption-arrow-top-2.svg");

            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
        }
    }

    &-caption-text {
        font-size: 2.2rem;
        line-height: 1.36;

        &:not(:last-of-type) {
            margin: 0 0 2rem 0;
        }
    }

    &-caption-text-important {
        font-weight: $font-weight-bold;
    }

    &-products {
        padding: 10rem 0 20rem 0;

        @media screen and (max-width: 991px) {
            padding: 5rem 0 12rem 0;
        }
    }

    &-products-list {
        display: flex;

        @media screen and (max-width: 991px) {
            display: block;
        }
    }

    &-products-img {
        display: block;
        width: 100%;
        max-width: 55rem;
        height: auto;

        @media screen and (max-width: 1680px) {
            max-width: 50rem;
        }

        @media screen and (max-width: 991px) {
            margin: 0 auto;
        }
    }

    &-products-only-in {
        display: block;
        width: 43.7rem;
        height: 13.2rem;
        margin: 5rem 0 0 33.5rem;
        background: url(/wp-content/themes/harnasxosp/src/images/lodoherbata-only-in.svg) center center / contain no-repeat;
        color: transparent;
        font-size: 0;

        @media screen and (max-width: 1680px) {
            width: 36rem;
            height: 12rem;
            margin: 5rem 0 0 30rem;
        }

        @media screen and (max-width: 991px) {
            margin: 8rem auto 0 auto;
        }
    }
}
