// footer.scss

@import "../utilities/variables";

.footer {
    background-color: $footer-bg;

    // Media Queries
    @media screen and (max-width: 991px) {
        height: auto;
    }

    &-container {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10rem;
        padding-bottom: 4rem;

        // Media Queries
        @media screen and (max-width: 991px) {
            flex-direction: column;

            padding: 8rem 15px 5rem 15px;
        }
    }

    &-content {
        position: relative;
        z-index: 5;
        margin: -7rem 0 0 0;

        // Media Queries
        @media screen and (max-width: 991px) {
            margin: -3.5rem 0 0 0;
        }
    }

    &-links {
        margin: 4rem 0 0 0;

        // Media Queries
        @media screen and (max-width: 991px) {
            margin: 4rem 0 4rem 0;
        }
    }

    &-navigation {

        &:not(:last-of-type) {
            margin: 0 0 1rem 0;
        }

        &--large {

            .footer-navigation-link {
                font-size: 2.5rem;
            }

            .footer-navigation-item {

                &:not(:last-of-type) {
                    margin: 0 4rem 0 0;

                    // Media Queries
                    @media screen and (max-width: 991px) {
                        margin: 0;
                    }

                    &::after {
                        right: -2.25rem;
                    }
                }
            }
        }
    }

    &-navigation-list {
        display: flex;

        // Media Queries
        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    &-navigation-item {

        &:not(:last-of-type) {
            position: relative;

            margin: 0 2rem 0 0;

            // Media Queries
            @media screen and (max-width: 991px) {
                margin: 0;
            }

            &::after {

                position: absolute;
                top: 50%;
                right: -1.25rem;

                display: inline-block;
                width: .5rem;
                height: .5rem;
                border-radius: 50%;
                margin: -.25rem 0 0 0;
                background-color: $footer-nav-dot;
                content: "";

                // Media Queries
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }
        }

        // Media Queries
        @media screen and (max-width: 991px) {
            text-align: center;
        }
    }

    &-navigation-link {
        color: $footer-color;
    }

    &-caution {
        width: 35rem;

        // Media Queries
        @media screen and (max-width: 575px) {
            width: 30rem;
        }
    }

    .logo {
        // Media Queries
        @media screen and (max-width: 991px) {
            margin: 0 auto;
        }
    }
}
